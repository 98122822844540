import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ConfigProvider, DatePicker, Form, FormInstance, Input, InputNumber, Modal, Pagination, Popconfirm, Select, Table, TablePaginationConfig, Tooltip, Typography, message } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import { AxDataform, ColumnItem, FormItemObj } from '../../utils/interfaces';
import { axMaterialstock, getCompanyList, getMaterialList } from '../../utils/api';
import { uniAxList, uniFormProcess } from '../../utils/uniFucs';
import TopSearch from '../../components/TopSearch';
import SuperTable from '../../components/SuperTable';

export default function MaterialStock() {
/* constant */
const pathname = "/indexpage/materialstock"
const modalRef = createRef<FormInstance>()
const searchRef = createRef<FormInstance>()
const initSearchParams = {page:1}
const initForminitial = {}
/* hooks */
let location = useLocation()
const navigate = useNavigate()
const [companyList, setCompanyList] = useState([])
const [logList, setLogList] = useState([])
const [rowList, setRowList] = useState([])
const [currentRow, setCurrentRow] = useState({} as any)
let [searchParams, setSearchParams] = useState({page:1,orderBy:"create_date", orderDir: "desc"} as any)
const [tempSearchParams, setTempSearchParams] = useState({page:1,orderBy:"create_date", orderDir: "desc"} as any)
const [staffList, setStaffList] = useState([])
const [feeTypeList, setFeeTypeList] = useState([])
const [tableLoading, setTableLoading] = useState(false)
const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
const [materialList, setMaterialList] = useState([])
const [loadFlag, setLoadFlag] = useState(false)
const [modalLoading, setModalLoading] = useState(false)
const [exportLoading, setExportLoading] = useState(false)
const [staticLoading,setStaticLoading] = useState(false)
const [modalFlag, setModalFlag] = useState('new')
const [showModal, setShowModal] = useState(false)
let [formInitials, setFormInitials] = useState({...initForminitial})
const [levelList1, setLevelList1] = useState([])
const [levelList2, setLevelList2] = useState([])
const [plusModalKeys, setPlusModalKeys] = useState({} as any)

/* elements */
//搜索行
const searchList:FormItemObj[] = [
{label: '公司', prop:'companyId',type:'select',dataList:companyList,change:(value:any)=>{
  if (value) uniAxList(dataMaterial(value))
  else setMaterialList([])
}},
{label: '物料', prop:'materielId',type:'select',dataList:materialList},
]
//列项
const columns:ColumnItem[] = [
{title: '公司',key: 'companyName'},
{title: '物料名',key: 'materielName'},
// {title: '单位',key: 'unit'},
{title: '采购数',key: 'buyNum'},
{title: '申领数',key: 'applyNum'},
{title: '剩余数',key: 'buyPrice',custval:({row,value}:any)=>row.buyNum-row.applyNum},
]

/* funcs */
const searchConfirm = async (formValus:any) => {
  pagination.current = 1
  setPagination({...pagination,current:1});
  searchParams = await uniFormProcess({...initSearchParams,...formValus},searchList)
  // setTableParams({...searchParams})
  setSearchParams(searchParams)
  uniAxList(dataRow())
}
//路由刷新请求
const routerFlash = () => {
  uniAxList(dataRow())
}

/* 接口参数 */
const dataRow = ():AxDataform => ({
  params: {...searchParams, current:pagination.current, size:pagination.pageSize},
  axApi: axMaterialstock,
  setList: setRowList,
  setTableLoading,
  setPagination
})
//物料列表
const dataMaterial = (companyId:any):AxDataform=>({
  params: {filter_EQS_company_id: companyId},
  axApi: getMaterialList,
  setList: setMaterialList,
})
const dataCompany:AxDataform = {
  axApi: getCompanyList,
  setList: setCompanyList,
}

/* useEffect */
//监听路由变化
React.useEffect(() => {
  if (loadFlag && location.pathname===pathname) routerFlash()
}, [location])
/* mounted */
React.useEffect(() => {
  uniAxList(dataRow())
  uniAxList(dataCompany)
  setLoadFlag(()=>true)
}, [])

return (<>
<TopSearch
  ref={searchRef}
  initialValues={initSearchParams}
  searchList={searchList}
  searchConfirm={searchConfirm}
/>
<SuperTable
  columns={columns}
  rowList={rowList}
  tableLoading={tableLoading}
  pagination={pagination}
  dataRow={dataRow}
/>
</>)
}