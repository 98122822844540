import React, { createRef, forwardRef, useState } from "react"
import { Button, ConfigProvider, DatePicker, Form, FormInstance, Input, InputNumber, Select, TreeSelect } from "antd"
import locale from 'antd/es/locale/zh_CN';
import { csDateRages } from "../utils/constant";
import moment from "moment";

type topProp = {searchConfirm:any, searchList:object[], initialValues?:object, searchData?:any, topActions?:any, hasButton?:boolean}
function TopSearch(props: topProp, ref: any) {
/* constant */
const {searchConfirm, searchList, initialValues, searchData, topActions, hasButton=true} = props
let compoundList = searchList.filter((item:any)=>item.isCompound)
let normalList = searchList.filter((item:any)=>!item.isCompound)
/* hookd */
const [compoundItem, setCompoundItem] = useState(compoundList[0] as any);

// /* useEffect */
// //监听路由变化
// React.useEffect(() => {
//   if (location.pathname===pathname) {
//     let mystate:any = state
//     if (state) {
//       searchRef.current?.setFieldsValue({'filter_LIKES_c.id':mystate.billid})
//       routerFlash(mystate.billid)
//     }
//     else routerFlash()
//   }
// }, [location]);

return (
  <ConfigProvider locale={locale}>
  <Form preserve={false} ref={ref} name="searchform" initialValues={initialValues} onFinish={(formValus) => searchConfirm(formValus)} autoComplete="off">
    <div style={{display:"flex",flexWrap:'wrap',alignItems:'center'}}>
      {compoundList.length>0?
        <div style={{display:"flex",justifyContent:'center',alignItems:'center'}}>
          <Select
            value={compoundItem.prop}
            style={{width:100}}
            size="small"
            allowClear={false}
            onChange={(value:any,operation:any)=>compoundChange(value,operation,compoundList,setCompoundItem)}
          > 
            {compoundList.map((item:any) => <Select.Option key={item['label']} value={item['prop']}>{item['label']}</Select.Option>)}
          </Select>
          <Form.Item style={{height:32}} key={compoundItem.prop} label={''} name={compoundItem.prop} rules={[{ required: compoundItem.required, message: compoundItem.requiredMsg || compoundItem.label }]}>{
            getElement(compoundItem,compoundItem.type)
          }</Form.Item>
        </div>:null
      }
      {normalList.map((item:any,index:any) => <Form.Item style={{height:34,marginLeft:(compoundList.length<=0 && index===0)?0:(item.marginLeft||10)}} key={item.prop} label={item.label} name={item.prop} rules={[{ required: item.required, message: item.requiredMsg || item.label }]}>{
        getElement(item,item.type)
      }</Form.Item>)}
      {hasButton?<Form.Item style={{height:34, marginRight:10}}><Button type="primary" size="small" htmlType="submit">搜索</Button></Form.Item>:null}
      {topActions && topActions.length>0
        ? <>{topActions.map((item:any)=> (item.show===false?false:true)?
        <Form.Item key={item.label} style={{height:34}}>{item.render
          ?item.render
          :<Button disabled={item.disabled} style={{marginRight:10}} type={item.type || 'primary'} danger={item.isDanger} loading={item.loading} size='small' key={item.label} onClick={item.action}>{item.label}</Button>
        }</Form.Item>:null)}</>
        :null
      }
    </div>
  </Form>
  </ConfigProvider>
)}

const compoundChange = (value:any,operation:any,compoundlist:any,setCompoundItem:any) => {
  let finditem = compoundlist.find((item:any)=>item.prop===value)
  setCompoundItem(finditem)
}

const getElement = (item:any,type='input') => {
  let tempel
  if (type==='input') tempel = <Input
    style={{width:item.width || 150}}
    allowClear={item.clearable===false?false:true}
    disabled={item.disabled}
    size='small'
    placeholder={item.placeholder || '请输入'}
  />
  else if (type==='select') tempel = (<Select
    style={{width:item.width || 150}}
    disabled={item.disabled}
    mode={item.selectProps?item.selectProps.mode:null}
    size="small"
    maxTagCount='responsive'
    optionLabelProp={(item.selectProps && item.selectProps.mode==='multiple')?'label':''}
    allowClear={item.clearable===false?false:true}
    placeholder={item.placeholder || '请选择'}
    onChange={(value,operation)=>item.change?item.change(value,operation):null}
    showSearch
    filterOption={(inputkey:any,option:any)=>(option?.children ?? '').includes(inputkey)}
  > 
    {item.dataList.map((listitem:any) => <Select.Option label={listitem[item.selectProps?.propName || 'name']?listitem[item.selectProps?.propName || 'name'].slice(0,1):''} key={listitem[(item.selectProps && item.selectProps.propId)?item.selectProps.propId:'id']} value={listitem[(item.selectProps && item.selectProps.propId)?item.selectProps.propId:'id']}>
      {item.selectProps&&item.selectProps.childrenKeys
        ?item.selectProps.childrenKeys.map((keyitem:string)=>listitem[keyitem]).join(' ')
        :listitem[(item.selectProps&&item.selectProps.propName)?item.selectProps.propName:'name']
      }
    </Select.Option>)}
  </Select>)
  else if (type==='tree') tempel = (<TreeSelect
    style={{ width: item.width || 150 }}
    disabled={item.disabled}
    size='small'
    showSearch
    treeNodeFilterProp={item.treeProps?.propName || 'name'}
    fieldNames={{value: item.treeProps?.propId || 'id', label: item.treeProps?.propName || 'name'} as any}
    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
    placeholder={item.placeholder || '请选择'}
    allowClear={item.clearable===false ? false : true}
    treeDefaultExpandAll={item.treeProps?item.treeProps.expandAll:null}
    treeData={item.dataList}
    treeCheckable={item.treeProps?item.treeProps.checkable:null}
    treeCheckStrictly={item.treeProps?item.treeProps.strictMode:null}
    onChange={(keyarr,labelarr,event)=>item.change?item.change(keyarr,labelarr,event.triggerNode.props):null}
  />)
  else if (type==='number' || type==='price') tempel = (<InputNumber
    disabled={item.disabled}
    style={{ width: item.width || 150 }}
    size='small'
    precision={item.numProps && item.numProps.precision ? item.numProps.precision : (type==='price'?2:0)}
    min={item.numProps && item.numProps.min ? item.numProps.min : 0}
    placeholder={item.placeholder || '请输入'}
    onChange={(value)=>item.change?item.change(value):null}
  />)
  else if (type==='datePicker') tempel = (
  <DatePicker
    style={{ width: item.width || 150 }}
    size='small'
    disabled={item.disabled}
    allowClear={item.clearable===false?false:true}
    picker={(item.datepickerProps && item.datepickerProps.type)?item.datepickerProps.type:'date'}
    disabledDate={(current)=>(item.datepickerProps?.disabledDate)?(item.datepickerProps.disabledDate(current)):(current > moment().endOf('day'))}
    onChange={(value)=>item.change?item.change(value):null}
  />
  )
  else if (type==='dateRange') tempel = (<DatePicker.RangePicker
    style={{ width: item.width || 230 }}
    allowClear={item.clearable===false?false:true}
    size="small"
    picker={(item.daterangeProps && item.daterangeProps.type)?item.daterangeProps.type:'date'}
    placeholder={['开始日期', '结束日期']}
    ranges={item.daterangeProps?.shortCuts || csDateRages}
    disabledDate={(current)=>(item.daterangeProps?.disabledDate)?(item.daterangeProps.disabledDate(current)):(current > moment().endOf('day'))}
  />)
  return tempel
}
// TopSearch.defaultProps = {rowKey: 'id', pagination:false}
export default forwardRef(TopSearch)