import React, { createRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Input, FormInstance, TreeSelect, Popconfirm, message, Button, Table } from 'antd';

import { axAllowmonth, axFirmInitial, axFirmStartym, axInitDep, axUninitDep, createDep, deleteDep, getDepTree, modifyDep, searchDepList } from '../../../utils/api'
import { DpItem } from '../../../utils/beans';
import FormModal from '../../../components/FormModal';
import { uniSelectRows, uniModifyDpList, uniAxList, uniAxSearchList, UniDeleteAll, uniQuitModal, uniChgRowSubmit, uniModifyAxList, uniAddRowSubmit, uniModifyRow, uniDeleteRow, uniValueToName, uniFormProcess, uniInitFormdata, uniOpAlarm } from '../../../utils/uniFucs'
import UniOperationColumn from '../../../components/UniOperationColumn';
import { useLocation } from 'react-router-dom';
import tablecss from '../../../css/custable.module.less'
import TopSearch from '../../../components/TopSearch';
import { AxDataform, ColumnAction, ColumnItem, FormItemObj } from '../../../utils/interfaces';
import SuperTable from '../../../components/SuperTable';
import { csActiveStatus, csOrgTypes, csYesNo } from '../../../utils/constant';
import FormDialog from '../../../components/FormDialog';

export default function BranchSetting() {
/* 不需要刷新页面的参数constructor */
const pathname = "/indexpage/branchsetting"
const searchRef = createRef<FormInstance>()
const initDpItem:DpItem = {id: '', label: '', key: ''}
const initSearchParams = {}
const initInitial = [{id:1,name:'初始化'},{id:0,name:'未初始化'}]
const initActive = [{id:1,name:'启用'},{id:0,name:'未启用'}]
/* hooks */
let location = useLocation()
const modalRef = createRef<FormInstance>()
const {userStore: {userInfor}} = useSelector((state:any )=> state)
const [rowList, setRowList] = useState([])
const [backList, setBackList] = useState([])
const [chgModal, setChgModal] = useState(false)
const [addModal, setAddModal] = useState(false)
const [currentRow, setCurrentRow] = useState({} as any)
const [selectedRowKeys, setSelectedRowKeys] = useState([])
let [searchParams, setSearchParams] = useState({orderBy:"sort", orderDir: "asc"} as any)
const [tempSearchParams, setTempSearchParams] = useState({orderBy:"sort", orderDir: "asc"} as any)
const [modalLoading, setModalLoading] = useState(false)
const [loadFlag, setLoadFlag] = useState(false)
const [tableLoading, setTableLoading] = useState(false)
const [modalFlag, setModalFlag] = useState('new')
const [showModal, setShowModal] = useState(false)
let [formInitials, setFormInitials] = useState({})
let [custKeys, setCustKeys] = useState({})
//路由刷新请求
const routerFlash = () => {
  uniAxList({...dataRow(), setBackList: setBackList})
}
//modal点击确定
const modalConfirm = async (formValues:any) => {
  let tempid = currentRow.id
  let tempitems = modalFlag==='init'?initformItems:modalFlag==='active'?activeformItems:formItems
  let datafuc = modalFlag==='init'?dataFirmInit:modalFlag==='active'?dataFirmStart:modalFlag === 'new'? dataNewrow : dataUpdaterow
  let tempparms = await uniFormProcess(formValues,tempitems)
  if (currentRow.id) tempparms = {...tempparms,id:currentRow.id}
  await uniAxList(datafuc({...tempparms}))
  if (modalFlag === 'update') uniAxList(dataAllowMonth({id:tempid,allowMonth:formValues.allowMonth}))
}

/* elements */
//搜索行
const searchList:FormItemObj[] = [
{label: '部门名称', prop:'filter_LIKES_name'}
]
//列首行
const columns:ColumnItem[] = [
{title: '名称',key: 'name',custval:({row,value}:any) => row.isHead===1?(value+'(总公司)'):value},
{title: '类型',key: 'type',custval:({row,value}:any) => uniValueToName({list:csOrgTypes, value})},
{title: '初始化',key: 'initialize',type:'tag',custval:({row,value}:any)=>row.type===10?uniValueToName({list:csYesNo,value}):''},
// {title: '结转时间',key: 'carryYm',custval:({row,value}:any)=>row.type===10?value:''},
// {title: '状态',key: 'initialize',type:'tag',tagProps:{values:[0,1]},custval:({row,value}:any) =>row.type===10?uniValueToName({list:initActive, value}):''},
{title: '启用时间',key: 'startYm',custval:({row,value}:any)=>row.type===10?value:''},
{title: '结账月',key: 'carryYm',custval:({row,value}:any)=>row.type===10?value:''},
{title: '录入允许月数',key: 'allowMonth'},
{title: '排序',key: 'sortVal'},
{title: '操作',key:'operation',type: 'operation',width:100,opProps:{opList:[
  {name:'修改',action:({row}:any) => {
    setModalFlag('update')
    setCurrentRow(row)
    setFormInitials(uniInitFormdata(row,formItems))
    setShowModal(true)
  }},
  {name:'删除',action:({row}:any) =>uniOpAlarm(()=>uniAxList(dataDelrow(row.id)))},
  {name:'启用',disabled:({row}:any)=>row.initialize===1,show:({row}:any)=>row.type===10 && !row.isHead,action:({row}:any) => {
    setModalFlag('active')
    setCurrentRow(row)
    setFormInitials(uniInitFormdata(row,activeformItems))
    setShowModal(true)
  }},
  {name:'初始化',show:({row}:any)=>row.type===10 && !row.isHead && row.initialize===0,disabled:({row}:any)=>!row.startYm || (row.initialize && row.startYm !== row.carryYm),action:({row}:any) =>uniOpAlarm(()=>uniAxList(dataInitrow(row.id)))},
  {name:'反初始',show:({row}:any)=>row.type===10 && !row.isHead && row.initialize===1,action:({row}:any) =>uniOpAlarm(()=>uniAxList(dataUninitrow(row.id)))}
  // {name:'初始化',disabled:({row}:any)=>!row.startYm || (row.initialize && row.startYm !== row.carryYm),show:({row}:any)=>row.type===10 && !row.isHead,action:({row}:any) => {
  //   setModalFlag('init')
  //   setCurrentRow(row)
  //   setFormInitials(uniInitFormdata(row,initformItems))
  //   setShowModal(true)
  // }}
]}}
]
//底部操作
const bottomActions:ColumnAction[] = [
{label: '新增部门', type:'primary', action: () => {setModalFlag('new');setShowModal(true)}},
]
//添加和修改中的modal项
const formItems:FormItemObj[] = [
{label: '上级部门',prop: 'pid',type:'tree',clearable:false,dataList:[{id:0,name:'一级公司',pid:-1},...rowList],show:modalFlag==='new',change:(value:any,node:any)=>{
  console.log('node----------',node)
  let maxnum = (node.children && node.children.length>0) ? node.children[node.children.length-1].sortVal : 0
  modalRef.current?.setFieldsValue({sortVal:maxnum+1})
  setCustKeys({...custKeys,type: node.pid===-1?10:30})
}},
{label: '名称',prop: 'name'},
{label: '排序',prop: 'sortVal',type:'number',required:false},
{label: '凭证未来月数',prop: 'allowMonth',type:'number'}
]
//初始化formitem
const activeformItems:FormItemObj[] = [
{label: '启用时间',prop: 'startYm',type:'datePicker',datepickerProps:{type:'month',returnType:'string'}}
]
//初始化formitem
const initformItems:FormItemObj[] = [
{label: '状态',prop: 'initialize',type:'select',dataList:initInitial}
]

/* funcs */
const searchConfirm = async (formValus:any) => {
searchParams = {...initSearchParams,...formValus}
uniAxList(dataRow())
}
/* 接口参数 */
//获取树状列表参数--返回没有parentId
const dataRow = ():AxDataform => ({
  params: searchParams,
  axApi: getDepTree,
  // modifyFunc: uniModifyDpList,
  itemModify: {modifyFunc:({item}:any)=>{
    if (item.children.length<=0) delete item.children
  }},
  setTableLoading,
  setList: setRowList,
})
//账套启用
const dataFirmStart = (params:any):AxDataform => ({
  params,
  axApi: axFirmStartym,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//账套初始化
const dataFirmInit = (params:any):AxDataform => ({
  params,
  axApi: axFirmInitial,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//新建部门
const dataNewrow = (params:any):AxDataform => ({
  params,
  axApi: createDep,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//修改部门
const dataUpdaterow = (params:any):AxDataform => ({
  params: {...params, id:currentRow.id},
  axApi: modifyDep,
  setModalLoading,
  setShowModal
})
//删除部门
const dataDelrow = (rowid:any):AxDataform => ({
  params: {id:rowid},
  axApi: deleteDep,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//初始化公司
const dataInitrow = (rowid:any):AxDataform => ({
  params: {id:rowid},
  axApi: axInitDep,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//反初始化公司
const dataUninitrow = (rowid:any):AxDataform => ({
  params: {id:rowid},
  axApi: axUninitDep,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//修改未来期数
const dataAllowMonth = (params:any):AxDataform => ({
  params,
  axApi: axAllowmonth,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})

/* useEffect */
//监听路由变化
React.useEffect(() => {
  if (loadFlag && location.pathname===pathname) routerFlash()
}, [location])

/* mounted */
React.useEffect(() => {
  uniAxList(dataRow())
  setLoadFlag(()=>true)
}, [])

return (
  <>
    <SuperTable
      columns={columns}
      rowList={rowList}
      tableLoading={tableLoading}
    />
    <FormDialog
      ref={modalRef}
      title={modalFlag==='new'?'新增部门':modalFlag==='update'?'修改部门':modalFlag==='init'?'初始化状态':modalFlag==='active'?'账套启用':''}
      show={showModal}
      formItems={modalFlag==='init'?initformItems:modalFlag==='active'?activeformItems:formItems}
      modalLoading={modalLoading}
      formInitials={formInitials}
      cancel={()=>{setFormInitials({});setShowModal(()=>false);setCurrentRow({});setCustKeys({})}}
      confirm={modalConfirm}
    ></FormDialog>
  </>
)
}
