import React, { createRef, useState } from 'react'
import { Button, ConfigProvider, DatePicker, Form, FormInstance, Input, InputNumber, message, Modal, Pagination, Popconfirm, Select, Table, TablePaginationConfig, Tooltip, TreeSelect, Typography } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { axAccountBalance, axAccountbook, axSubjectTrans, balanceProofSubjects, deleteProof, deleteProofbatch, axSubjectList, getBusinessList, getCompanyList, getProofList, getProofNoList, reviewProof, unReviewProof, axProofcarry, axProofuncarry } from '../../utils/api'
import { uniAxList, uniAxSearchList, uniBatchConfirm, uniChgRowSubmit, uniConfirm, uniCurrentTime, uniDatePikcerChange, uniDeepArray, UniDeleteAll, uniDeleteRow, uniExportExcel, uniFilterItem, uniFormProcess, uniModifyAxList, uniOpAlarm, uniSelectRows, uniToProof, uniValueToName } from '../../utils/uniFucs'
import UniOperationColumn from '../../components/UniOperationColumn'
import { cancelStatus, cancelStatusNums, csDateRages, materialBuyReviewList, materialReviewStatus, proofTypeList, reviewStatusList } from '../../utils/constant'
import { useLocation, useNavigate } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import Checkbox from 'antd/es/checkbox/Checkbox';
import tablecss from '../../css/custable.module.less'
import { AxDataform, ColumnAction, ColumnItem, FormItemObj } from '../../utils/interfaces';
import TopSearch from '../../components/TopSearch';
import SuperTable from '../../components/SuperTable';
import FormDialog from '../../components/FormDialog';

export default function AccountBook() {
const pathname = "/indexpage/accountbook"
const searchRef = createRef<FormInstance>()
const modalRef = createRef<FormInstance>()
const initSearchParams = {page:1, order:'asc,p.bill_ymd---asc,p.proof_no','filter_EQL_p.cancel_status':0}
const initFormdata = {}
/* hooks */
let location = useLocation()
const {state} = useLocation()
const navigate = useNavigate()
const formModalRef = createRef<FormInstance>()
const [unReviewModal, setUnReviewModal] = useState(false)
const [companyList, setCompanyList] = useState([])
const [logList, setLogList] = useState([])
const [rowList, setRowList] = useState([{detailList:[]}])
const [backRowList, setBackRowList] = useState([])
const [modalItemVisible, setModalItemVisible] = useState({businessId:true,costTypeId:true,collectionPrice:true,payPrice:false,bothPrice:false,servicePrice:false,collectionAccountId:true,payAccountId:false,serviceAccountId:false,collectionTime:true,payTime:false,bothTime:false})
const [showCashAccount, setShowCashAccount] = useState(true)
const [selectedRowKeys, setSelectedRowKeys] = useState([] as any)
const [selectedRows, setSelectedRows] = useState([] as any)
const [currentRow, setCurrentRow] = useState({} as any)
// const [searchParams, setSearchParams] = useState({page:1, order:'desc,proof_no---asc,borrow_lend',filter_EQL_cancel_status:0} as any)
// const [tempSearchParams, setTempSearchParams] = useState({page:1, order:'desc,proof_no---asc,borrow_lend',filter_EQL_cancel_status:0} as any)
let [searchParams, setSearchParams] = useState({...initSearchParams} as any)
const [tempSearchParams, setTempSearchParams] = useState({...initSearchParams} as any)
const [staffList, setStaffList] = useState([])
const [feeTypeList, setFeeTypeList] = useState([])
const [tableLoading, setTableLoading] = useState(false)
const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 10})
const [accountantSubjectList, setAccountantSubjectList] = useState([])
const [businessList, setBusinessList] = useState([])
const [reviewMark, setReviewMark] = useState({value:''})
const [unReviewRows, setUnReviewRows] = useState([])
const [loadFlag, setLoadFlag] = useState(false)
const [reviewProofNoList,setReviewProofNoList] = useState({value:[]})
const [unReviewProofNoList, setUnReviewProofNoList] = useState({value:[]})
const [modalLoading, setModalLoading] = useState(false)
const [exportLoading, setExportLoading] = useState(false)
const [routerValue, setRouterValue] = useState({companyId:null,subjectId:null,ymd:[]} as any)
const [proofNoList,setProofNoList] = useState([])
const [checkedList,setCheckedList] = useState([] as any)
const [checkedYmd,setcheckedYmd] = useState([] as any)
const [hoverproofno,setHoverproofno] = useState('')
const [balanceModal,setBalanceModal] = useState(false)
const [reviewCount,setReviewCount] = useState(0)
const [modalFlag, setModalFlag] = useState('review')
const [showModal, setShowModal] = useState(false)
let [formInitials, setFormInitials] = useState({...initFormdata})
const [searchYmRange,setSearchYmRange] = useState([] as any)
const [companyItem,setCompanyItem] = useState({} as any)

/* elements */
//顶部操作
const topActions:ColumnAction[] = [
{label: '导出', type:'primary',loading:exportLoading,action: async () => {
  setExportLoading(true)
  let resdata = await uniAxList({params: {...searchParams,size:99999},axApi: axAccountbook})
  let tempkeys = ['billYmd','proofNo','businessName','companyName','cancelStatus','approverName','approveStatus','approverRemark','proofType','createBy','createDate']
  let temparr:any = []
  resdata.forEach((item:any)=>{
    if (item.detailList && item.detailList.length > 0) {
      let keys = ['']
      item.detailList.forEach((subitem:any,index:number)=>{
        let tempobj = {...subitem}
        tempkeys.forEach((key)=>tempobj[key]=item[key])
        if (index===0) {
          tempobj.mergeFlag = true
          tempobj.span = item.detailList.length
        }
        temparr.push(tempobj)
      })
    }
  })
  uniExportExcel({columns:printcolumns,mergColindexs:[0,1,6,7,8,9,10,11,12,13,14],rowList:temparr,title:'会计序时账', fileName: '会计序时账'})
  setExportLoading(false)
}},
]
//搜索行
const searchList:FormItemObj[] = [
{label: '公司', prop:'filter_EQL_p.company_id',type:'select',dataList:companyList,clearable:false,required:true,change:(value:any)=>{
  searchRef.current?.setFieldsValue({'filter_BETWEENS_p.bill_ymd':null})
  let finditem:any = companyList.find((item:any)=>item.id===value)
  setCompanyItem(finditem)
  if (finditem.initialize===1) setSearchYmRange([finditem.startYm,finditem.endYm])
  else setSearchYmRange([])
}},
{label: '作废状态', prop:'filter_EQL_p.cancel_status',type:'select',dataList:cancelStatusNums},
{label: '凭证号', prop:'filter_EQS_p.proof_no'},
{label: '摘要', prop:'filter_LIKES_p.summary'},
{label: '审核状态', prop:'filter_EQL_p.approve_status',type:'select',dataList:materialReviewStatus},
{label: '会计科目', prop:'filter_EQL_pd.subject_id',width:180,type:'tree',treeProps:{propName:'title'},dataList:accountantSubjectList},
{label: '凭证生成方式', prop:'filter_EQL_p.proof_type',type:'select',dataList:proofTypeList},
{label: '所属业务', prop:'filter_EQL_p.business_id',type:'select',dataList:businessList},
{label: '凭证日期', prop:'filter_BETWEENS_p.bill_ymd',type:'dateRange',daterangeProps:{returnType:'string',nosuffix:true,disabledDate:(current)=>{
  if (searchYmRange.length<2) return true
  else return current<moment(searchYmRange[0]).startOf('month') || current>moment(searchYmRange[1]).endOf('month')
}}},
{label: '金额', prop:'filter_EQL_p.price'},
]
//列项
const columns:ColumnItem[] = [
{title: '凭证日期',key: 'billYmd',width:120,
render: (value:any, row:any) =><div style={{height:40*row.detailList.length,display:'flex',flexDirection:'column',boxSizing:'border-box'}}>
  {row.detailList.map((item:any,index:any)=><div style={{flex:1,lineHeight:'40px'}}><div style={{margin:'0 16px'}}>{index===0 ? value: ''}</div></div>)}
</div>},
{title: '凭证号',key: 'proofNo',width:120,
render: (value:any, row:any) =><div style={{height:40*row.detailList.length,display:'flex',flexDirection:'column',boxSizing:'border-box'}}>
  {row.detailList.map((item:any,index:any)=><div style={{flex:1,lineHeight:'40px'}}>{index===0?<div style={{textDecoration:'underline',cursor:'pointer',minWidth:60,maxWidth:200,color:'#1890ff'}} onClick={()=>uniToProof(row,row.id, navigate)}>{Number(value)}</div>:<div></div>}</div>)}
</div>},
{title: '摘要',key: 'summary',width: 300,onCell: (record:any, index:number) => ({style:{padding:0}}),
render: (value:any, row:any) =><div style={{height:40*row.detailList.length,display:'flex',flexDirection:'column',boxSizing:'border-box'}}>
  {row.detailList.map((item:any)=><div style={{flex:1,lineHeight:'40px',borderBottom:'1px solid #333'}}><div style={{margin:'0 16px'}}><Tooltip placement="topLeft" title={item.summary}>{item.summary}</Tooltip></div></div>)}
</div>},
{title: '会计科目',key: 'subjectNo',width: 300,onCell: (record:any, index:number) => ({style:{padding:0}}),
render: (value:any, row:any) =><div style={{height:40*row.detailList.length,display:'flex',flexDirection:'column',boxSizing:'border-box'}}>
  {row.detailList.map((item:any)=><div key={item.id} style={{flex:1,lineHeight:'40px',borderBottom:'1px solid #333'}}><div style={{margin:'0 16px'}}><Tooltip placement="topLeft" title={item.subjectNo + ' ' + item.subjectName}>{item.subjectNo + ' ' + item.subjectName}</Tooltip></div></div>)}
</div>},
{title: '借方金额',key: 'borrowPrice',width: 120,onCell: (record:any, index:number) => ({style:{padding:0}}),
render: (value:any, row:any) =><div style={{height:40*row.detailList.length,display:'flex',flexDirection:'column',boxSizing:'border-box'}}>
  {row.detailList.map((item:any)=><div key={item.id} style={{flex:1,lineHeight:'40px',borderBottom:'1px solid #333'}}><div style={{margin:'0 16px',color:item.borrowLend === 1?'':'transparent'}}>{item.borrowLend === 1 ? (item.price / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-'}</div></div>)}
</div>},
{title: '贷方金额',key: 'lendPrice',width: 120,onCell: (record:any, index:number) => ({style:{padding:0}}),
render: (value:any, row:any) =><div style={{height:40*row.detailList.length,display:'flex',flexDirection:'column',boxSizing:'border-box'}}>
  {row.detailList.map((item:any)=><div key={item.id} style={{flex:1,lineHeight:'40px',borderBottom:'1px solid #333'}}><div style={{margin:'0 16px',color:item.borrowLend === 2?'':'transparent'}}>{item.borrowLend === 2 ? (item.price / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-'}</div></div>)}
</div>},
{title: '所属业务',key: 'businessName'},
{title: '所属公司',key: 'companyName'},
{title: '作废状态',key: 'cancelStatus',width: 120,custval:({row,value}:any)=>uniValueToName({list:cancelStatusNums,value})},
{title: '审核人',key: 'approverName'},
{title: '审核状态',key: 'approveStatus',type:'tag',tagProps:{values:[3,2,1,0]},width: 120,custval:({row,value}:any)=>uniValueToName({list:materialReviewStatus,value})},
{title: '审核备注',key: 'approverRemark',width:120},
{title: '凭证方式',key: 'proofType',custval:({row,value}:any)=>uniValueToName({list:proofTypeList,value})},
{title: '制单人',key: 'createBy'},
{title: '创建时间',key: 'createDate'},
{title: '操作',key:'operation',type: 'operation',width:80,opProps:{opList:[
  {name:'删除',action:({row}:any) =>uniOpAlarm(()=>uniAxList(dataDelrow(row.id)))}
]}}
]
//列项
const printcolumns:ColumnItem[] = [
{title: '凭证日期',key: 'billYmd',width:120},
{title: '凭证号',key: 'proofNo',width:120},
{title: '摘要',key: 'summary',width: 300},
{title: '会计科目',key: 'subjectNo',width: 300,custval:({row,value}:any)=>row.subjectNo + ' ' + row.subjectName},
{title: '借方金额',key: 'borrowPrice',custval:({row,value}:any)=>row.borrowLend===1?Number((row.price/100).toFixed(2)):0},
{title: '贷方金额',key: 'lendPrice',custval:({row,value}:any)=>row.borrowLend===2?Number((row.price/100).toFixed(2)):0},
{title: '所属业务',key: 'businessName'},
{title: '所属公司',key: 'companyName'},
{title: '作废状态',key: 'cancelStatus',width: 120,custval:({row,value}:any)=>uniValueToName({list:cancelStatusNums,value})},
{title: '审核人',key: 'approverName'},
{title: '审核状态',key: 'approveStatus',width: 120,custval:({row,value}:any)=>uniValueToName({list:materialReviewStatus,value})},
{title: '审核备注',key: 'approverRemark',width:120},
{title: '凭证方式',key: 'proofType',custval:({row,value}:any)=>uniValueToName({list:proofTypeList,value})},
{title: '制单人',key: 'createBy'},
{title: '创建时间',key: 'createDate'}
]
//底部操作
const bottomActions:ColumnAction[] = [
{label: '批量审核', type:'primary', disabled:!selectedRowKeys.length,action: () => onReview()},
{label: '批量反审核', type:'primary',isDanger:true,disabled:!selectedRowKeys.length,action: () => onUnReview()},
{label: '批量删除', type:'primary',isDanger:true,disabled:!selectedRowKeys.length,action: () => uniOpAlarm(()=>uniAxList(dataDelrowbatch(selectedRowKeys)))},
{label: '记账凭证平衡', type:'primary',action: () => {setModalFlag('balance');setShowModal(true);}},
{label: '会计结转凭证生成', type:'primary',action: () => {setFormInitials({subjectId:131});setModalFlag('trans');setShowModal(true);}},
{label: '会计结账', type:'primary',action: () => {
  if (!companyItem.id) return message.error('请先选择公司')
  uniOpAlarm(()=>uniAxList(dataProofcarry()),`${companyItem.name} 结转月${companyItem.carryYm?moment(companyItem.carryYm).add(1,'month').format('YYYY-MM'):companyItem.beginYm}, 是否确认该操作`)
}},
{label: '会计反结账', type:'primary',action: () => {
  if (!companyItem.id) return message.error('请先选择公司')
  if (!companyItem.carryYm) return message.error('该公司未结转')
  uniOpAlarm(()=>uniAxList(dataProofuncarry()),`${companyItem.name} 反结转月${companyItem.carryYm}, 是否确认该操作`)
}}
]
//审批modal中的item
const reviewItems:FormItemObj[] = [
{label: '审核结果',prop: 'reviewStatus',type:'select',dataList:materialBuyReviewList},
{label: '备注',prop: 'remark',required:false}
]
//审批modal中的item
const balanceItems:FormItemObj[] = [
{label: '开始月份',prop: 'ym',type:'datePicker',datepickerProps:{type:'month',returnType:'string'}},
]
//结转modal中的item
const transItems:FormItemObj[] = [
{label: '公司', prop:'companyId',type:'select',dataList:companyList,selectProps:{requireName:'companyName'}},
{label: '本年利润科目', prop:'subjectId',width:180,type:'tree',treeProps:{propName:'title'},dataList:accountantSubjectList},
{label: '月份',prop: 'ym',type:'datePicker',datepickerProps:{type:'month',returnType:'string'}},
]
/* funcs */
const searchConfirm = async (formValus:any) => {
  pagination.current = 1
  setPagination({...pagination,current:1});
  let tempprice = formValus['filter_EQL_p.price'] ? Number(formValus['filter_EQL_p.price'] * 100) : null
  searchParams = {...initSearchParams,... await uniFormProcess(formValus,searchList),'filter_EQL_p.price':tempprice}
  setSearchParams(searchParams)
  uniAxList(dataRow())
}
//点击批量审核
const onReview = () => {
  for (let i=0;i<selectedRows.length;i++) {
    if(![1,3].includes(selectedRows[i].approveStatus)) return message.error('所选凭证状态必须[待审核]')
  }
  setModalFlag('review')
  setShowModal(true)
}
//点击反审核
const onUnReview = () => {
  for (let i=0;i<selectedRows.length;i++) {
    if(selectedRows[i].approveStatus !== 2) return message.error('所选凭证状态必须[通过审核]')
  }
  uniOpAlarm(()=>uniAxList(dataUnBatchConfirm()))
}
//点击批量删除
const onBatchDelete = () => {
let deleteKeys:any = []
selectedRows.forEach((rowItem:any)=>{
  rowItem.proofs.forEach((proofItem:any)=>deleteKeys.push(proofItem.id))
})
if (deleteKeys.length <= 0) message.info('没有可以删除的记录')
else UniDeleteAll({deleteKeyList:deleteKeys, deleteApi:deleteProof, setSelectedRowKeys:setSelectedRowKeys, axParams:dataRow()})
}
//路由刷新请求
const routerFlash = () => {
// uniAxList(dataRow())
uniAxList(uniAsData)
uniAxList(uniCompanyData)
uniAxList(uniBusinessData)
}
//modal点击确定
const modalConfirm = async (formValues:any) => {
  //confirm={ async (formValues:any)=>uniAxList(dataBatchConfirm(await uniFormProcess(formValues,reviewItems)))}
  let tempitems = modalFlag === 'review'?reviewItems:modalFlag === 'trans'?transItems:balanceItems
  let datafuc = modalFlag === 'review'? dataBatchConfirm :modalFlag === 'trans'? dataSubjectTrans :dataBalance
  let tempparms = await uniFormProcess(formValues,tempitems)
  uniAxList(datafuc(tempparms))
  // modalFlag==='new'?uniAxList(dataNewrow(await uniFormProcess(formValues,formItems))):uniAxList(dataUpdaterow(await uniFormProcess(formValues,formItems)))
}

/* 接口参数 */
const dataRow = ():AxDataform => ({
params: {...searchParams,current: pagination.current,size:pagination.pageSize},
axApi: axAccountbook,
// modifyFunc: uniModifyAxList,
// modifyData: {custFunc:(record:any)=>record.key=record.billYmd+record.proofNo},
setList: setRowList,
setTableLoading,
setSelectedRowKeys,
setSelectedRows,
setPagination,
})
//删除凭证
const dataDelrow = (rowid:any):AxDataform => ({
  params: {id:rowid},
  axApi: deleteProof,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//批量删除凭证
const dataDelrowbatch = (rowids:any):AxDataform => ({
  params: rowids,
  axApi: deleteProofbatch,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//会计结转
const dataProofcarry = ():AxDataform => ({
  params: {companyId:companyItem.id,ym:companyItem.carryYm ?moment(companyItem.carryYm).add(1,'month').format('YYYY-MM'):companyItem.beginYm},
  axApi: axProofcarry,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//会计反结转
const dataProofuncarry = ():AxDataform => ({
  params: {companyId:companyItem.id,ym:companyItem.carryYm},
  axApi: axProofuncarry,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
const uniProofListData:any = {
axApi: getProofList,
setList: setRowList,
setTableLoading,
modifyFunc: (axList:any)=>{
  let rowSpan = 1
  let startIndex = 0
  let proofindex = 1
  axList.forEach((axItem:any,index:number)=>{
    if (index===0) axItem.proofindex = 1
    else if ((axItem.proofNo !== axList[index-1].proofNo) || (axItem.billYmd !== axList[index-1].billYmd)) proofindex += 1
    axItem.proofindex = proofindex
    if (index===0) {
      if ((axItem.proofNo!==axList[1].proofNo) || (axItem.billYmd !== axList[1].billYmd)) axItem.rowSpan=1
      else {
        startIndex = index
        // axItem.rowSpan=0
      }
    } else if (index===axList.length-1) {
      if ((axItem.proofNo !== axList[index-1].proofNo) || (axItem.billYmd !== axList[index-1].billYmd)) axItem.rowSpan=1
      else {
        axItem.rowSpan=0
        axList[startIndex].rowSpan = rowSpan+1
        rowSpan = 1
      }
    } else {
      if ((axItem.proofNo!==axList[index-1].proofNo || axItem.billYmd !== axList[index-1].billYmd) && (axItem.proofNo!==axList[index+1].proofNo || axItem.billYmd !== axList[index+1].billYmd)) axItem.rowSpan = 1
      else if ((axItem.proofNo!==axList[index-1].proofNo || axItem.billYmd !== axList[index-1].billYmd) && (axItem.proofNo===axList[index+1].proofNo && axItem.billYmd === axList[index+1].billYmd)) startIndex = index
      else if (axItem.proofNo===axList[index-1].proofNo && axItem.billYmd === axList[index-1].billYmd && axItem.proofNo===axList[index+1].proofNo && axItem.billYmd === axList[index+1].billYmd) {
        axItem.rowSpan = 0
        rowSpan += 1
      } else if (axItem.proofNo===axList[index-1].proofNo && axItem.billYmd === axList[index-1].billYmd && (axItem.proofNo!==axList[index+1].proofNo || axItem.billYmd !== axList[index+1].billYmd)) {
        axItem.rowSpan=0
        axList[startIndex].rowSpan = rowSpan+1
        rowSpan = 1
      }
    }
  })
}
}
const uniAsData = {
modifyFunc: uniModifyAxList,
modifyData: {titleList: ['title','subjectNo','name']},
params: {page:1, size:9999},
axApi: axSubjectList,
setList: setAccountantSubjectList
}
const uniCompanyData:any = {
axApi: getCompanyList,
setList: setCompanyList,
}
const uniBusinessData:any = {
axApi: getBusinessList,
setList: setBusinessList,
}
const DataBalanceProof:any = {
axApi: balanceProofSubjects,
setModalLoading,
setModal: setBalanceModal,
awaitFunc:()=>uniAxList(dataRow())
}
//批量审核
const dataBatchConfirm = (formvalus:any):AxDataform => ({
  params: {...formvalus,ids: selectedRowKeys},
  axApi: reviewProof,
  setModalLoading,
  setShowModal,
  awaitFunc:()=>uniAxList(dataRow())
})
//批量反审核
const dataUnBatchConfirm = ():AxDataform => ({
  params: {ids: selectedRowKeys},
  axApi: unReviewProof,
  awaitFunc:()=>uniAxList(dataRow())
})
//记账凭证平衡
const dataBalance = (formvalus:any):AxDataform => ({
  params: {...formvalus},
  axApi: axAccountBalance,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//会计科目结转
const dataSubjectTrans = (formvalus:any):AxDataform => ({
  params: {...formvalus},
  axApi: axSubjectTrans,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
/* useEffect */
//监听路由变化
React.useEffect(() => {
if(loadFlag && location.pathname===pathname) routerFlash()
}, [location])

/* mounted */
React.useEffect(() => {
// if(!state) uniAxList(dataRow())
uniAxList(uniAsData)
uniAxList(uniCompanyData)
uniAxList(uniBusinessData)
setLoadFlag(()=>true)
}, [])

React.useEffect(() => {
if (state && location.pathname===pathname) {
  routerValue.ymd=[]
  routerValue.companyId = null
  let mySate:any = state
  routerValue.subjectId = mySate.subjectId
  tempSearchParams.filter_EQL_company_id = mySate.companyId
  tempSearchParams.filter_EQL_subject_id = mySate.subjectId
  searchParams.filter_EQL_company_id = mySate.companyId
  searchParams.filter_EQL_subject_id = mySate.subjectId
  if(mySate.companyId) routerValue.companyId = mySate.companyId
  let tempYmd
  if (mySate.year) {
    let start = moment(mySate.year).startOf('year').format("YYYY-MM-DD")
    let end = moment(mySate.year).endOf('year').format("YYYY-MM-DD")
    tempYmd = start+','+end
    routerValue.ymd = [moment(start,"YYYY-MM-DD"),moment(end,"YYYY-MM-DD")]
  } else {
    let start = moment(mySate.ymd).startOf('month').format("YYYY-MM-DD")
    let end = moment(mySate.ymd).endOf('month').format("YYYY-MM-DD")
    tempYmd = start+','+end
    routerValue.ymd = [moment(start,"YYYY-MM-DD"),moment(end,"YYYY-MM-DD")]
  }
  tempSearchParams.filter_BETWEENS_bill_ymd = tempYmd
  searchParams.filter_BETWEENS_bill_ymd = tempYmd
  console.log('')
  uniAxList({...dataRow(), params: {...searchParams, filter_BETWEENS_bill_ymd:tempYmd, filter_EQL_company_id:mySate.companyId}})
}
}, [state])

return (
<>
  <TopSearch
    ref={searchRef}
    topActions={topActions}
    searchList={searchList}
    initialValues={{'filter_EQL_p.cancel_status':0}}
    searchConfirm={searchConfirm}
  />
  <SuperTable
    tableClass='accountbookTable'
    bordered={true}
    columns={columns}
    rowList={rowList}
    bottomActions={bottomActions}
    tableLoading={tableLoading}
    pagination={pagination}
    dataRow={dataRow}
    rowSelection={{setSelectedRowKeys, setSelectedRows, selectedRowKeys}}
  />
  <FormDialog
    ref={modalRef}
    title={modalFlag==='review'?'批量审核':modalFlag==='trans'?'会计科目结转':'记账凭证整理'}
    show={showModal}
    formItems={modalFlag==='review'?reviewItems:modalFlag==='trans'?transItems:balanceItems}
    modalLoading={modalLoading}
    formInitials={formInitials}
    cancel={()=>{
      setShowModal(()=>false)
      setCurrentRow({})
      setFormInitials({...initFormdata})
    }}
    confirm={modalConfirm}
  />
</>
)
}
