import { HomeOutlined, BookOutlined, MenuFoldOutlined, LoginOutlined, SolutionOutlined, UserSwitchOutlined, DeploymentUnitOutlined, RedEnvelopeOutlined,
        IdcardOutlined, TransactionOutlined, AuditOutlined, StrikethroughOutlined, PieChartOutlined, FundOutlined, AppstoreOutlined, CloudServerOutlined,
        CreditCardOutlined, BankOutlined, FileDoneOutlined, ShareAltOutlined, PropertySafetyOutlined, NodeIndexOutlined, ShoppingCartOutlined, CalendarOutlined,
        AccountBookOutlined, CheckCircleOutlined, SnippetsOutlined, BarChartOutlined, EditOutlined, FormOutlined, AreaChartOutlined, StockOutlined, DiffOutlined,
        LogoutOutlined, SwapOutlined, MoneyCollectOutlined, DeliveredProcedureOutlined, IssuesCloseOutlined, ControlOutlined, SelectOutlined, VerifiedOutlined,
        CloseSquareOutlined, AppstoreAddOutlined, TeamOutlined, SwitcherOutlined, ScheduleOutlined, ReconciliationOutlined, ImportOutlined, InteractionOutlined,
        UnorderedListOutlined, LineChartOutlined, LaptopOutlined, TableOutlined, ProfileOutlined, OneToOneOutlined, ReadOutlined, ClockCircleOutlined, CalculatorOutlined,
        ProjectOutlined, RiseOutlined, BlockOutlined, HighlightOutlined, FileSyncOutlined, FundProjectionScreenOutlined, FileSearchOutlined, FundViewOutlined,
        DollarOutlined, DashboardOutlined, SettingOutlined, UserOutlined, BgColorsOutlined, RadarChartOutlined, SlackOutlined, PrinterOutlined, SlackSquareOutlined,
        QrcodeOutlined, QuestionCircleOutlined, OrderedListOutlined, FolderViewOutlined, SortAscendingOutlined,WalletOutlined,TranslationOutlined,LineHeightOutlined,
        DotChartOutlined, LinkedinOutlined, PicLeftOutlined, ChromeOutlined, DropboxOutlined, PoundCircleOutlined, PaperClipOutlined,RetweetOutlined,FullscreenOutlined,
        EuroOutlined,UnlockOutlined,FlagOutlined,DatabaseOutlined,BorderOuterOutlined,InsertRowBelowOutlined
       } from '@ant-design/icons'
import moment from 'moment'

import { AccountSubjectItem, CompanyAccountItem, UserLocal } from './beans'
import { StaffItem, NameValue } from './beans'
// import { useReducer } from 'react';
// import { urlState, urlReducer } from './reducer'

//baseUrl
export const getBaseURL = () => {
  // const [baseurl, urlDispatch] = useReducer(urlReducer, urlState)
  if (process.env.NODE_ENV === 'production') {
    // return 'http://120.79.170.191:8080/'
    return 'https://api.yc.hemikj.com/'
  }
  else return 'http://localhost:3000/'
  // else return 'https://api.yc.hemikj.com/'
}

/**
 * 此文件存储公共信息，一般是些常量111
 */

export const commonMenuItems:any = [
  {code:'PC_GUIDE',name:'新手指引'}
]

//左侧列表menu菜单
export const menuObj:any = {
  'PC_GUIDE':{
    label: '新手指引',
    icon: UnlockOutlined,
    route: 'guide',
  },
  'PC_HOME':{
    label: '首页',
    icon: HomeOutlined,
    route: 'main',
  },
  'SUPER_HOME':{
    label: '首页',
    icon: HomeOutlined,
    route: 'main',
  },
  'PC_BASICS_SETTING':{
    label: '基础设置',
    icon: MenuFoldOutlined,
    route:null
  },
  'PC_GP_COMPANY':{
    label: '共配账套设置',
    icon: LoginOutlined,
    route: 'gpratesetting'
  },
  'PC_PERSONNEL_SETTING':{
    label: '人事管理设置',
    icon: SolutionOutlined,
    route: null
  },
  'PC_USER_SETTING':{
    label: '人员管理',
    icon: UserSwitchOutlined,
    route: 'staffmanagement'
  },
  'PC_DEPT_SETTING':{
    label: '部门设置',
    icon: DeploymentUnitOutlined,
    route: 'branchsetting'
  },
  'PC_POSITION_SETTING':{
    label: '职位设置',
    icon: IdcardOutlined,
    route: 'titlesetting'
  },
  'PC_WD_FIN':{
    label: '网点财务设置',
    icon: PropertySafetyOutlined,
    route: null
  },
  'PC_COMPANY_ACCOUNT_SETTING':{
    label: '公司账户设置',
    icon: BankOutlined,
    route: 'firmaccountsetting'
  },
  'PC_ACCOUNT_SUBJECT_SETTING':{
    label: '会计科目',
    icon: StrikethroughOutlined,
    route: 'expressaccountingsubject'
  },
  'PC_COSTTYPE_SETTING':{
    label: '费用类型设置',
    icon: PieChartOutlined,
    route: 'feetypesetting'
  },
  'PC_WANGDAIN_SETTING':{
    label: '网点期初建账',
    icon: FundOutlined,
    route: 'initialaccounts'
  },
  'PC_CUSTOMER_EDIT':{
    label: '客户资料设置',
    icon: AppstoreOutlined,
    route: 'customerarchives'
  },
  'PC_MATERIAL_EDIT':{
    label: '物料库存设置',
    icon: CloudServerOutlined,
    route: 'stockin'
  },
  'PC_MATERIEL_CATEGORY':{
    label: '物料类目设置',
    icon: BorderOuterOutlined,
    route: 'mcategory'
  },
  'PC_APPROVAL_PERMISSION_EDIT':{
    label: '审批权限设置',
    icon: AuditOutlined,
    route: 'approvallimits'
  },
  'PC_APPROVAL_PROCESS_EDIT':{
    label: '审批流程设置',
    icon: FileDoneOutlined,
    route: 'approvalprocess'
  },
  '关联项目设置':{
    label: '关联项目设置',
    icon: ShareAltOutlined,
    route: 'relevantunits'
  },
  'PC_IN_OUT_BILL':{
    label: '收支账单',
    icon: TransactionOutlined,
    route:null
  },
  '平台余额维护':{
    label: '平台余额维护',
    icon: FolderViewOutlined,
    route:'plantbalance'
  },
  'PC_COMPANY_IN_OUT_BILL':{
    label: '公司收支登记',
    icon: EditOutlined,
    route: 'compayment'
  },
  'PC_USER_BILL':{
    label: '员工账单登记',
    icon: FormOutlined,
    route: null,
  },
  'PC_BILL_SUMMARY':{
    label: '账单汇总',
    icon: AreaChartOutlined,
    route: 'billsummary'
  },
  'PC_BILL_TOTAL':{
    label: '账单统计',
    icon: StockOutlined,
    route: 'billstatistics'
  },
  'PC_BILL_BXMX':{
    label: '报销明细',
    icon: DiffOutlined,
    route: 'reimbursement'
  },
  'PC_BILL_PJDSDF':{
    label: '派件代收到付',
    icon: LogoutOutlined,
    route: 'deliverycollectpay'
  },
  'PC_BILL_FJDS':{
    label: '发件代收到付',
    icon: SwapOutlined,
    route: 'sendcollect'
  },
  'PC_BILL_CZTX':{
    label: '充值提现',
    icon: RedEnvelopeOutlined,
    route: 'recharge'
  },
  'PC_BILL_ZHYE':{
    label: '账户余额',
    icon: MoneyCollectOutlined,
    route: 'userbalance'
  },
  'PC_BILL_YELS':{
    label: '余额流水明细',
    icon: PrinterOutlined,
    route: 'userbalancedetail'
  },
  'PC_BILL_XFFH':{
    label: '现付月付发货费',
    icon: DeliveredProcedureOutlined,
    route: 'transferfee'
  },
  'PC_BILL_FKMX':{
    label: '罚款明细',
    icon: IssuesCloseOutlined,
    route: 'finebill'
  },
  'PC_BILL_PFMX':{
    label: '派费明细',
    icon: ControlOutlined,
    route: 'dispatchfee'
  },
  'PC_BILL_QTFY':{
    label: '其他费用',
    icon: SelectOutlined,
    route: 'otherfee'
  },
  'PC_BILL_YJJK':{
    label: '押金借款',
    icon: VerifiedOutlined,
    route: 'pledgeloan'
  },
  'PC_BILL_ZFMX':{
    label: '作废明细',
    icon: CloseSquareOutlined,
    route: 'invaliddetail'
  },
  'PC_TOATL_DATA':{
    label: '总部数据登记',
    icon: AppstoreAddOutlined,
    route: null
  },
  'PC_HEADBILL_IMPORT':{
    label: '总部数据导入',
    icon: BgColorsOutlined,
    route: 'hqdata'
  },
  'PC_HEADBILL_IMPORT_ORIGINAL':{
    label: '业务数据原数据',
    icon: DotChartOutlined,
    route: 'hqorigin'
  },
  'PC_HEADBILL_TAX_TOTAL':{
    label: '集配税率统计',
    icon: InsertRowBelowOutlined,
    route: 'hqtaxtotal'
  },
  'PC_HEADBILL_TOTAL':{
    label: '总部数据统计表',
    icon: RadarChartOutlined,
    route: 'hqstatistics'
  },
  'PC_MATERIAL_SETTING':{
    label: '物料管理',
    icon: NodeIndexOutlined,
    route: null
  },
  'PC_MATERIAL_BUY':{
    label: '物料采购明细',
    icon: ShoppingCartOutlined,
    route: 'materialpurch'
  },
  'PC_MATERIAL_APPLY':{
    label: '物料申领明细',
    icon: CalendarOutlined,
    route: 'materialclaim'
  },
  'PC_MATERIAL_APPLY_BILL':{
    label: '物料申领流水明细',
    icon: LineHeightOutlined,
    route: 'materialclaimlog'
  },
  'PC_MATERIAL_PRICE_EDIT':{
    label: '物料价格设置',
    icon: AccountBookOutlined,
    route: 'materialprice'
  },
  'PC_MATERIAL_RECONCILIATION':{
    label: '每日物料对账表',
    icon: CheckCircleOutlined,
    route: 'materialdailycheck'
  },
  'PC_MATERIAL_STOCK':{
    label: '物料库存明细',
    icon: LoginOutlined,
    route: 'materialstock'
  },
  'PC_MATERIAL_COST':{
    label: '物料成本核算表',
    icon: SnippetsOutlined,
    route: 'materialcost'
  },
  'PC_MATERIAL_MONTH_STATISTICS':{
    label: '月度物料统计表',
    icon: BarChartOutlined,
    route: 'materialmonthstat'
  },
  'PC_FREIGHT_SETTLEMENT':{
    label: '运费结算',
    icon: PicLeftOutlined,
    route: null
  },
  'PC_BILLING_RULE_MAINTENANCE':{
    label: '计费规则维护',
    icon: ChromeOutlined,
    route: 'chargerule'
  },
  'PC_BILLING_OBJECT_MAINTENANCE':{
    label: '计费对象关系维护',
    icon: DropboxOutlined,
    route: 'chargerelations'
  },
  'PC_BILLING_IMPORT':{
    label: '总部数据导入1',
    icon: PaperClipOutlined,
    route: 'chargedataimport'
  },
  'PC_BILLING':{
    label: '财务报表1',
    icon: PoundCircleOutlined,
    route: 'chargefinance'
  },
  'PC_FIXEDASSETS_SETTING':{
    label: '资产管理',
    icon: TranslationOutlined,
    route: null
  },
  'PC_FIXEDASSETS_MANAGE_SETTING':{
    label: '资产管理设置',
    icon: CreditCardOutlined,
    route: 'financeassets'
  },

  'PC_FIXEDASSETS_INOUT':{
    label: '资产借出归还',
    icon: LinkedinOutlined,
    route: 'financeinout'
  },
  'PC_CUSTOMER_SETTING':{
    label: '客户管理',
    icon: TeamOutlined,
    route: null
  },
  'PC_CUSTOMER_XF':{
    label: '客户现付账单',
    icon: SwitcherOutlined,
    route: 'custcashbill'
  },
  'PC_CUSTOMER_YJ':{
    label: '客户月结账单',
    icon: ScheduleOutlined,
    route: 'custmonthclear'
  },
  'PC_CUSTOMER_BALACNE':{
    label: '客户账户余额',
    icon: ReconciliationOutlined,
    route: 'custbalance'
  },
  'PC_CUSTOMER_PREPAY':{
    label: '预付款充值',
    icon: ImportOutlined,
    route: 'custprepay'
  },
  '客户账单审批':{
    label: '客户账单审批',
    icon: InteractionOutlined,
    route: 'custbillapproval'
  },
  'PC_CUSTOMER_BILL':{
    label: '客户账单流水明细',
    icon: UnorderedListOutlined,
    route: 'custbillflow'
  },
  'PC_CUSTOMER_BILL_ANALYSIS':{
    label: '客户回款分析表',
    icon: LineChartOutlined,
    route: 'custpayrepo'
  },
  'PC_INTELLIGENCE_FINANCE':{
    label: '智能财务',
    icon: LaptopOutlined,
    route: null
  },
  'PC_ACCOUNTING_VOUCHER':{
    label: '记账凭证',
    icon: TableOutlined,
    route: 'recordvoucher'
  },
  'PC_ACCOUNTBOOK':{
    label: '会计序时账',
    icon: ProfileOutlined,
    route: 'accountbook'
  },
  'PC_REMAINDER':{
    label: '科目余额表',
    icon: OneToOneOutlined,
    route: 'accountbalance'
  },
  'PC_PROOF_TOTAL':{
    label: '凭证汇总表',
    icon: RetweetOutlined,
    route: 'proofcollection'
  },
  'PC_COMPANY_JOURNAL':{
    label: '银行日记账',
    icon: FlagOutlined,
    route: 'bankdaily'
  },
  'PC_CASH_JOURNAL':{
    label: '现金日记账',
    icon: EuroOutlined,
    route: 'cashdaily'
  },
  'PC_ACCOUNTING_SETTING':{
    label: '辅助核算管理',
    icon: DatabaseOutlined,
    route: 'affixmanage'
  },
  'PC_ACCOUNTING_TOTAL':{
    label: '辅助核算明细账',
    icon: FullscreenOutlined,
    route: 'affixdetail'
  },
  'PC_ACCOUNT_BILL':{
    label: '明细账',
    icon: ReadOutlined,
    route: 'detailbook'
  },
  'PC_REPORT':{
    label: '每日报表',
    icon: ClockCircleOutlined,
    route: null
  },
  'PC_BILL_RECONCILIATION':{
    label: '每日流水对账表',
    icon: CalculatorOutlined,
    route: 'dailyflow'
  },
  'PC_ACCOUNT_RECONCILIATION':{
    label: '公司账户对账表',
    icon: ProjectOutlined,
    route: 'firmaccountcheck'
  },
  'PC_FINANCE_REPORT':{
    label: '财务报表',
    icon: RiseOutlined,
    route: null
  },
  'PC_MONTH_RECONCILIATION':{
    label: '月末结账对账',
    icon: BlockOutlined,
    route: 'monthclear'
  },
  'PC_SHOUPAI_DATA':{
    label: '收派数据录入',
    icon: HighlightOutlined,
    route: 'iodatarecord'
  },
  'PC_ONE_REPORTPROFIT':{
    label: '单品牌月利润表',
    icon: FileSyncOutlined,
    route: 'productmonthprofit'
  },
  'PC_MONTH_REPORTPROFIT':{
    label: '共配月度利润表',
    icon: FundProjectionScreenOutlined,
    route: 'gpmonthprofit'
  },
  'PC_ONE_ANALYSE':{
    label: '单品牌单票分析表',
    icon: FileSearchOutlined,
    route: 'productsingleanal'
  },
  'PC_ONE_YEAR_ANALYSE':{
    label: '单品牌年度分析表',
    icon: FundViewOutlined,
    route: 'productyearanal'
  },
  'PC_GP_BALANCESHEET':{
    label: '共配资产负债表',
    icon: DollarOutlined,
    route: 'gpbalancesheet'
  },
  'PC_GP_MONEY':{
    label: '共配现金流量表',
    icon: DashboardOutlined,
    route: 'gpcashflow'
  },
  'PC_SYSTEM_SETTING':{
    label: '系统设置',
    icon: SettingOutlined,
    route: null
  },
  'PC_SITE_ROLE_SETTING':{
    label: '角色权限',
    icon: UserOutlined,
    route: 'userprivilege'
  },
  'PC_SITE_MENU_SETTING': {
    label: '菜单设置',
    icon: SlackOutlined,
    route: 'menusetting'
  },
  'PC_SITE_SETTING': {
    label: '站点设置',
    icon: SlackSquareOutlined,
    route: 'sitesetting'
  },
  'SUPER_SETTING':{
    label: '运营管理',
    icon: QrcodeOutlined,
    route: null
  },
  'SUPER_SITE_APPIY':{
    label: '开户申请',
    icon: QuestionCircleOutlined,
    route: 'siteapply'
  },
  'SUPER_SITE_SETTING':{
    label: '站点管理',
    icon: OrderedListOutlined,
    route: 'sitemanagement'
  },
  'SUPER_SUBJECT_SETTING':{
    label: '会计科目管理',
    icon: SortAscendingOutlined,
    route: 'subjectmanage'
  },
  'SUPER_COSTTYPE_SETTING':{
    label: '费用类型管理',
    icon: WalletOutlined,
    route: 'feetypemanage'
  },
}
//数字中文大写
export const chNum:any = {'':'', '0':'零', '1':'壹', '2':'贰', '3':'叁', '4':'肆', '5':'伍', '6':'陆', '7':'柒', '8':'捌', '9':'玖'}
//字母表
export const csAlphabets = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']

//本地存储的userLocal的初始状态
export const initUserLocal:UserLocal = {mobile: '', password: '', remember: false, token: ''}

//人员item的初始值
export const initStaffItem:StaffItem = {
  address: '',
  avatar: '',
  balance: 0,
  contractFile: '',
  createBy: '',
  createDate: '',
  departmentName: '',
  id: '',
  identityCard: '',
  isDeleted: false,
  jobNum: '',
  joinTime: '',
  mobile: '',
  name: '',
  password: '',
  password2: '',
  positionName: '',
  remark: '',
  roleKey: '',
  siteId: 0,
  status: '',
  updateBy: '',
  updateDate: '',
  userCharacterName: '',
  company: [],
  role: [{id: ''}],
  token: '',
  autoLogin: false,
  remember: false
}
//时间range
export const csDateRages:any = {
  '近12月': [moment().subtract(12,'month').startOf('month'), moment().subtract(1,'month').endOf('month')],
  '近6月': [moment().subtract(6,'month').startOf('month'), moment().subtract(1,'month').endOf('month')],
  '近3月': [moment().subtract(3,'month').startOf('month'), moment().subtract(1,'month').endOf('month')],
  '上月': [moment().subtract(1,'month').startOf('month'), moment().subtract(1,'month').endOf('month')],
  '今天': [moment(), moment()],
  '本周': [moment().startOf('week'), moment().endOf('week')],
  '本月': [moment().startOf('month'), moment().endOf('month')],
  '本季度': [moment().startOf('quarter'), moment().endOf('quarter')],
  '本年': [moment().startOf('year'), moment().endOf('year')]
}
//month时间range
export const monthRages:any = {
  '去年': [moment().subtract(1,'year').startOf('year'), moment().subtract(1,'year').endOf('year')],
  '上个月': [moment().subtract(1,'month').startOf('month'), moment().subtract(1,'month').endOf('month')],
  '本月': [moment().startOf('month'), moment().endOf('month')],
  '本季度': [moment().startOf('quarter'), moment().endOf('quarter')],
  '本年': [moment().startOf('year'), moment().endOf('year')]
}

//开户类型bankType
export const bankTypeList:any = [
  {name: '现金账户', id: '现金账户'},
  {name: '银行卡', id: '银行卡'},
  {name: '支付宝', id: '支付宝'},
  {name: '微信', id: '微信'},
  {name: '其他', id: '其他'}
]
//资产类型
export const assetType:any = [
  {name: '资产', id: 1},
  {name: '负债', id: 2},
  {name: '权益', id: 3},
  {name: '成本', id: 4},
  {name: '损益', id: 5}
]
//借贷方向
export const leadBorrow:any = [
  {name: '借', id: 1},
  {name: '贷', id: 2},
]
//资产借出归还
export const csAssetInouts:NameValue[] = [
  {name: '借出', value: 1},
  {name: '领用', value: 3},
  {name: '归还', value: 2}
]
//是否禁用
export const forbidenStatus:any = [
  {name: '启用', id: 1},
  {name: '禁用', id: 0},
]
//在职或离职
export const postStatus:NameValue[] = [
  {name: '在职', value: '1'},
  {name: '离职', value: '2'},
]
//账户类型
export const accountType:any = [
  {name: '公司账户', id: 1},
  {name: '个人账户', id: 2},
  // {name: '总部余额', id: 3},
  {name: '虚拟账户', id: 4}
]
//启用禁用
export const banOrNot:any = [
  {name: '启用', id: true},
  {name: '禁用', id: false}
]
//是否启用
export const onOrOff:NameValue[] = [
  {name: '启用', value: '0'},
  {name: '禁用', value: '1'}
]
//结算方式
export const settleType:NameValue[] = [
  {name: '现付', value: 1},
  {name: '月结', value: 2}
]
//所属品牌
export const brandList:NameValue[] = [
  {name: '申通', value: '申通'},
  {name: '圆通', value: '圆通'},
  {name: '中通', value: '中通'},
  {name: '韵达', value: '韵达'},
  {name: '极兔', value: '极兔'},
  {name: '丰网', value: '丰网'},
  {name: '其他', value: '其他'}
]
//报销/收/付款方式
export const payWay:NameValue[] = [
  {name: '线上', value: '1'},
  {name: '线下', value: '2'}
]
//付款状态
export const payStatus:any = [
  {name: '未付款', id: 0},
  {name: '已付款', id: 1}
]
//收款状态
export const collectionStatus:NameValue[] = [
  {name: '未收款', value: 0},
  {name: '已收款', value: 1}
]
//代收到付运单类型
export const pjdsType:NameValue[] = [
  {name: '代收', value: 1},
  {name: '到付', value: 2}
]
//代收到付运单类型
export const pjdsBusinessId:NameValue[] = [
  {name: '代收', value: 71},
  {name: '到付', value: 72}
]
//员工账单中business类型
export const businessTypes:any = [
  {name: '报销明细', id: 68},
  {name: '充值', id: 69},
  {name: '提现', id: 70},
  {name: '代收', id: 71},
  {name: '到付', id: 72},
  {name: '发件代收', id: 73},
  {name: '发货费', id: 74},
  {name: '现付款', id: 75},
  {name: '派费', id: 76},
  {name: '员工罚款', id: 77},
  {name: '其他费用', id: 78},
  {name: '押金借款', id: 95}
]
//充值提现
export const payTypeList:NameValue[] = [
  {name: '充值', value: 1},
  {name: '提现', value: 2}
]
//已充值和未充值
export const chargeStatus:NameValue[] = [
  {name: '已充值', value: 1},
  {name: '未充值', value: 0}
]
//已提现和未提现
export const withdrawStatus:NameValue[] = [
  {name: '已提现', value: 1},
  {name: '未提现', value: 0}
]
//充值提现的businessId
export const chargeOrWithdraw:NameValue[] = [
  {name: '充值', value: 69},
  {name: '提现', value: 70}
]
//金额正负
export const balanceStatus:NameValue[] = [
  {name: '大于0', value: 1},
  {name: '等于0', value: 0},
  {name: '小于0', value: -1}
]
//现付和发货费运单类型
export const currentOrSendFee:NameValue[] = [
  {name: '现付', value: 1},
  {name: '月付', value: 2}
]
//线上账户
export const onlineAccounts:NameValue[] = [
  {name: '共配线上账户', value: 1}
]
//应收或应付
export const shouldColOrPay:NameValue[] = [
  {name: '应收', value: 1},
  {name: '应付', value: 2}
]
//收付类型incomeType
export const incomeTypes:any = [
  {name: '应收', id: 1},
  {name: '应付', id: 2},
  {name: '实收', id: 3},
  {name: '实付', id: 4}
]

//借还类型
export const csLendBorrow:any = [
  {name: '还款', id: 1},
  {name: '借款', id: 2},
]
//所有的状态
export const allCashStatus:NameValue[] = [
  {name: '未收', value: 1},
  {name: '未付', value: 2},
  {name: '已收', value: 3},
  {name: '已付', value: 4},
]
//收付款状态
export const cashStatus:NameValue[] = [
  {name: '未收/付款', value: 0},
  {name: '已收/付款', value: 1}
]
//收付款状态及作废
export const cashStatusCancel:any = [
  {name: '未收/付款', id: 0},
  {name: '已收/付款', id: 1},
]
//作废状态
export const cancelStatus:any = [
  {name: '正常', id: false},
  {name: '作废', id: true}
]
//作废状态nums
export const cancelStatusNums:any = [
  {name: '正常', id: 0},
  {name: '作废', id: 1}
]
//报废状态
export const csScrapNums:any = [
  {name: '正常', id: 0},
  {name: '报废', id: 1}
]
//现付类型
export const xfTypes:NameValue[] = [
  {name: '已收款(出账单+收款)', value: 1},
  {name: '未收款(只出账单未全部收款)', value: 2},
  {name: '还款(只收款)', value: 3},
]
//月结账单类型
export const monthBillTypes:NameValue[] = [
  {name: '新增应收款', value: 1},
  {name: '已有应收款', value: 2}
]
//月结还是现付类型
export const custType:NameValue[] = [
  {name: '现付', value: 1},
  {name: '月结', value: 2}
]
//近三年
export const late3years:NameValue[] = [
  {name: (new Date).getFullYear().toString(), value: (new Date).getFullYear().toString()},
  {name: ((new Date).getFullYear() - 1).toString(), value: ((new Date).getFullYear() - 1).toString()},
  {name: ((new Date).getFullYear() - 2).toString(), value: ((new Date).getFullYear() - 2).toString()}
]
//公司登记收付类型
export const companyBillType:any = [
  {name: '公司收到款', id: 1},
  {name: '公司支出款', id: 2},
  {name: '内部转账', id: 3}
]
//手续费扣款账户
export const serviceAccount:any = [
  {name: '入款账户', id: 1},
  {name: '出款账户', id: 2},
]
//凭证状态
export const proofStatusList:any = [
  {name: '未生成凭证', id: 1},
  {name: '已生成凭证', id: 2},
  {name: '不生成凭证', id: 3},
  {name: '已作废', id: 4}
]
//借方贷方
export const borrowOrLend:any = [
{name: '借方', id: 1},
{name: '贷方', id: 2},
]
//凭证生成方式
export const proofTypeList:any = [
{name: '系统生成', id: 1},
{name: '手动录入', id: 2},
{name: '无需凭证', id: 3}
]
//物料类型
export const materialTypes:NameValue[] = [
  {name: '面单', value: 1},
  {name: '非面单/物料', value: 2},
]
//物料类型详情
export const materialTypesDetail:NameValue[] = [
  {name: '生产类易耗品', value: 1},
  {name: '招待物品', value: 2},
  {name: '办公用品', value: 3}
]
//入库状态
export const stockStatusList:any = [
  {name: '未入库', id: 0},
  {name: '已入库', id: 1}
]
//人员性质
export const userTypeList:NameValue[] = [
  {name: '员工', value: 1},
  {name: '客户', value: 2},
  {name: '承包区', value: 3}
]
//资产来源
export const csPropTypes:any = [
  {name: '调入', id: 1},
  {name: '新增', id: 2}
]
//对账状态
export const isCheckList:any = [
  {name: '未对账', id: 0},
  {name: '已对账', id: 1}
]
//审核状态
export const reviewStatusList:NameValue[] = [
  {name: '无需审核', value: 0},
  {name: '待审核', value: 1},
  {name: '审核中', value: 2},
  {name: '通过审核', value: 3},
  {name: '审核不通过', value: 4}
]
//物料审核状态
export const materialReviewStatus:any = [
{name: '无需审核', id: 0},
{name: '待审核', id: 1},
{name: '通过审核', id: 2},
{name: '审核不通过', id: 3}
]
//公司收支类型
export const collectionPayTypeList:NameValue[] = [
  {name: '公司收入', value: 1},
  {name: '公司支出', value: 2},
  {name: '内部转账', value: 3},
]
//对账状态
export const reconciliationStatusList:any = [
  {name: '未对账', id: 0},
  {name: '部分对账', id: 1},
  {name: '已对账', id: 2},
]
//公司收支类型
export const actionTypeList:NameValue[] = [
  {name: '公司收支', value: 1},
  {name: '员工账单', value: 2},
]
//物料采购审批结果
export const materialBuyReviewList:any = [
  {name: '审核不通过', id: 0},
  {name: '审核通过', id: 1},
]
//客户现付类型的项目类型
export const cashPayTypeList:NameValue[] = [
  {name: '已收款(出账单+收款)', value: 1},
  {name: '未收款(只出账单未全部收款)', value: 2},
  {name: '还款(只还款)', value: 3}
]
//客户月结类型的项目类型
export const monthPayTypeList:NameValue[] = [
  {name: '新增应收款', value: 1},
  {name: '已有应收款', value: 2},
  {name: '预付款', value: 3}
]
//所有类型--自定义用于搜素
export const allPayTypeList:NameValue[] = [
  {name: '已收款(出账单+收款)', value: 1},
  {name: '未收款(只出账单未全部收款)', value: 2},
  {name: '还款(只收款)', value: 3},
  {name: '新增应收款', value: 4},
  {name: '已有应收款', value: 5},
  {name: '预付款', value: 6}
]
//是否做利润表
export const csYesNo:any = [
  {name: '是', id: 1},
  {name: '否', id: 0},
]
//登录权限列表
export const loginScopeList:NameValue[] = [
  {name: '后台登录', value: 1},
  {name: 'APP登录', value: 2},
  {name: '全部', value: 3},
]
//预付款中的费用类型
export const prepayCostType:any = [
  {
    value: '1081',
    label: '预收账款',
    children: [{value: '1084',label:'客户预付款(非面单预付款)'}]
  }
]
//员工余额明细收支类型
export const staffCashType:any = [
  {name: '收入', id: 1},
  {name: '支出', id: 2},
]
//用户类型-站点或者用户
export const userTypes:NameValue[] = [
  {name: '用户', value: 1},
  {name: '站点', value: 2},
]
//加减类型
export const calculateTypes:NameValue[] = [
  {name: '+', value: 1},
  {name: '-', value: 2},
]
//取数规则
export const calculateTypeTypes:NameValue[] = [
  {name: '损益发生额', value: 1},
  {name: '借方发生额', value: 2},
  {name: '贷方发生额', value: 3}
]
//资产负债类型
export const subjectTypeList:NameValue[] = [
  {name: '资产', value: '1'},
  {name: '负债', value: '2'},
  {name: '权益', value: '3'}
]
//公私类型
export const privateTypes:NameValue[] = [
  {name: '对公', value: 1},
  {name: '对私', value: 2},
]
//是否商业类型
export const businessTypeList:NameValue[] = [
  {name: '非商业', value: 0},
  {name: '商业', value: 1},
]
//适用公司类型
export const adaptTypes:NameValue[] = [
  {name: '通用', value: 1},
  {name: '快递', value: 2},
  {name: '商业', value: 3}
]
//能或者不能
export const canOrCant:NameValue[] = [
  {name: '能', value: 1},
  {name: '不能', value: 0},
]
//是否完结
export const carryStatusList:any = [
  {name: '已完结', id: true},
  {name: '未完结', id: false},
]
//预收实收
export const preTypeList:NameValue[] = [
  {name: '预收', value: 1},
  {name: '实收', value: 2},
  {name: '面单转预付', value: 3},
]
//客户月结收款方式
export const monthClearTypes:NameValue[] = [
  {name: '账户', value: 1},
  {name: '预付款', value: 2},
]
//是否计算收支
export const inOutStatusList:NameValue[] = [
  {name: '不计算', value: 0},
  {name: '计算', value: 1},
]
//客户充值类型
export const custChargeTypes:NameValue[] = [
  // {name: '面单充值', value: 2},
  {name: '预付款充值', value: 1}
]
//日期类型
export const dateTypes:NameValue[] = [
  {name: '账单日期', value: 1},
  {name: '结算日期', value: 2}
]
//资产类型
export const csAssetTypes:NameValue[] = [
  {name: '固定资产', value: 1},
  {name: '长期待摊费用', value: 3},
  {name: '无形资产', value: 2}
]
//资产性质
export const csAssetProperties:any = [
  {name: '已有资产', id: 1},
  {name: '新购资产', id: 2}
]
//资产的businessId
export const csAssetBusinessIds:NameValue[] = [
  {name: '固定资产', value: 96},
  {name: '长期待摊费用', value: 97},
  {name: '无形资产', value: 98}
]
//是否公摊
export const csIsShareList:any = [
  {name: '不公摊', id: 0},
  {name: '公摊 (第一行是比例%，第二行是金额)', id: 1}
]
//报价组类型
export const csCuTypes:NameValue[] = [
  {name: '员工', value: 1},
  {name: '客户', value: 2}
]
//称重方式
export const csCostWeights:NameValue[] = [
  {name: '按单位进位', value: 1}
]
//计重方式
export const csWeightCost:NameValue[] = [
  {name: '实重', value: 1},
  {name: '续重', value: 2}
]
//是否计算
export const csWeightCals:NameValue[] = [
  {name: '未计算', value: 0},
  {name: '计算成功', value: 1},
  {name: '计算失败', value: 2}
]
//日记账类型
export const csRegitType:NameValue[] = [
  {name: '银行日记账', value: 1},
  {name: '现金日记账', value: 2}
]
//月报季报类型
export const csFlowTypes:NameValue[] = [
  {name: '月报', value: 1},
  {name: '季报', value: 2}
]
//公司
export const companyNameList = ["总公司","中通","韵达","圆通","申通","极兔","丰网"]
export const companyNameId = [{name:"中通", id:2},{name:"韵达", id:3},{name:"圆通", id:4},{name:"申通", id:5},{name:"极兔", id:6},{name:"丰网", id:7}]

//公司账户companyAccount的item初始值
export const initComapnyAccountItem:CompanyAccountItem = {
  accountType: 0,
  balance: 0,
  bankType: "",
  beginBalance: 0,
  beginTime: "",
  cardNo: "",
  companyId: 0,
  createBy: "",
  createDate: "",
  id: "",
  name: "",
  realName: "",
  siteId: "",
  status: true,
  updateBy: "",
  updateDate: '',
  subjectName: '',
  subjectNo: ""
}

//accountSubject快递会计科目item初始值
export const initAccountSubjectItem:AccountSubjectItem = {
  balance: 0,
  borrowLend: 0,
  createBy: "",
  createDate: "",
  id: 0,
  level: 0,
  name: "",
  parentId: 0,
  siteId: "",
  status: 1,
  subjectNo: "",
  subjectType: "",
  sysLock: 0,
  updateBy: "",
  updateDate: ""
}

//自定义记账凭证初始item
export const initProofItem:any = {
  borrowLend: "",
  companyName: "",
  fen: "",
  fenL: "",
  hundred: "",
  hundredL: "",
  hundredMillion: "",
  hundredMillionL: "",
  hundredThousand: "",
  hundredThousandL: "",
  jiao: "",
  jiaoL: "",
  million: "",
  millionL: "",
  subjectName: "",
  summary: "",
  ten: "",
  tenL: "",
  tenMillion: "",
  tenMillionL: "",
  tenThousand: "",
  tenThousandL: "",
  thousand: "",
  thousandL: "",
  yuan: "",
  yuanL: ""
}

//单个列boolean统计
export const staticSingleColumns:any = [
  {
    title: '',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: '未收款',
    dataIndex: 'uncash',
    key: 'uncash',
  },
  {
    title: '已收款',
    dataIndex: 'hascash',
    key: 'hascash',
  },
  {
    title: '小计',
    dataIndex: 'total',
    key: 'total'
  },
  {
    title: '条数',
    dataIndex: 'counts',
    key: 'counts',
  },
]
//单个列boolean统计
export const staticSgPayColumns:any = [
  {
    title: '',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: '未付款',
    dataIndex: 'uncash',
    key: 'uncash',
  },
  {
    title: '已付款',
    dataIndex: 'hascash',
    key: 'hascash',
  },
  {
    title: '小计',
    dataIndex: 'total',
    key: 'total'
  },
  {
    title: '条数',
    dataIndex: 'counts',
    key: 'counts',
  },
]
//所有应收类型统计表格列
export const staticAllColumns:any = [
  {
    title: '收付类型',
    dataIndex: 'incomeType',
    key: 'incomeType',
  },
  {
    title: '费用类型',
    dataIndex: 'costTypeName',
    key: 'icostTypeName',
  },
  {
    title: '记录条数',
    dataIndex: 'counts',
    key: 'doneTotal',
  },
  {
    title: '小计',
    dataIndex: 'total',
    key: 'total'
  },
]
//----------------------优化----------------
//辅助核算类型
export const csAffixTypes:any = [
{name: '业务资料', id: 1},
{name: '基础资料', id: 2},
{name: '辅助资料', id: 3}
]
//单据类型
export const csBillTypes:any = [
{name: '已对账待开票', id: 1},
{name: '已开票待结款', id: 2},
{name: '部分结款', id: 3},
{name: '已结款', id: 4},
{name: '暂估款', id: 5}
]
//收支类型
export const csIncomeTypes:any = [
{name: '应收', id: 1},
{name: '应付', id: 2}
]
//组织类型
export const csOrgTypes:any = [
{name: '公司', id: 10},
{name: '部门', id: 30}
]
//启用未启用状态
export const csActiveStatus:any = [
{name: '启用', id: 1},
{name: '未启用', id: 0}
]
//在职状态状态
export const csStaffActives:any = [
{name: '在职', id: '1'},
{name: '离职', id: '2'}
]
//发放状态
export const applyStatusList = [
{name: '未发放', id: 0},
{name: '已发放', id: 1}
]
//物料流水类型
export const csMaterialflowTypes = [
{name: '采购', id: 1},
{name: '申领', id: 2}
]
