import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ConfigProvider, DatePicker, Form, FormInstance, Input, InputNumber, Modal, Pagination, Popconfirm, Select, Table, TablePaginationConfig, Tooltip, Typography, message } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import { PlusCircleOutlined } from '@ant-design/icons'

import { axAffixSubjectDetail, axAllpurchStat, axSinglepurchStat, companyBillList, confirmMaterialBuy, confirmMaterialStock, createMaterial, createMaterialBuy, deleteMaterial, deleteMaterialBuy, getCompanyAccoutList, getCompanyList, getCostTypeTreeV2, getMaterialByList, getMaterialLevel1, getMaterialLevel2, getMaterialList, getStaffList, reviewMaterialBuy, singleSubjectRemainder, updateMaterialBuy, updateMaterialBuyCompbill } from '../../utils/api'
import { uniAddRowSubmit, uniAxList, uniAxSearchList, uniChgRowSubmit, uniConfirm, uniCurrentTime, uniDatePikcerChange, uniDeepArray, UniDeleteAll, uniDeleteRow, uniExportExcel, uniFilterItem, uniFormProcess, uniInitFormdata, uniModifyAxList, uniModifyRow, uniOpAlarm, uniQuitModal, uniSelectRows, uniTableChange, uniToProof, uniValueToName } from '../../utils/uniFucs'
import FormModal from '../../components/FormModal'
import { MaterielItem } from '../../utils/beans'
import TextArea from 'antd/lib/input/TextArea';
import { payStatus, proofStatusList, materialReviewStatus, stockStatusList, materialBuyReviewList, csDateRages } from '../../utils/constant';
import tablecss from '../../css/custable.module.less'
import TopSearch from '../../components/TopSearch';
import { AxDataform, ColumnAction, ColumnItem, FormItemObj } from '../../utils/interfaces';
import SuperTable from '../../components/SuperTable';
import FormDialog from '../../components/FormDialog';
import MainEcharts from '../../components/MainEcharts';

export default function MaterialPurch() {
const pathname = "/indexpage/materialpurch"
const modalRef = createRef<FormInstance>()
const submodalRef = createRef<FormInstance>()
const searchRef = createRef<FormInstance>()
const initSearchParams = {page:1,orderBy:"buy_time", orderDir: "desc"}
const initForminitial = {}
const initSubforminitial = {}
const initsumProps:any = []
/* hooks */
let location = useLocation()
const navigate = useNavigate()
const formModalRef = createRef<FormInstance>()
const [confirmModal, setConfirmModal] = useState(false)
const [stockModal, setStockModal] = useState(false)
const [companyList, setCompanyList] = useState([])
const [logList, setLogList] = useState([])
const [rowList, setRowList] = useState([])
const [currentRow, setCurrentRow] = useState({} as any)
let [searchParams, setSearchParams] = useState({...initSearchParams} as any)
let [tableParams, setTableParams] = useState({...initSearchParams} as any)
const [tableLoading, setTableLoading] = useState(false)
const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
const [accountList, setAccountList] = useState([])
const [customAddKeys, setCustomAddKeys] = useState({} as any)
const [unCashRows, setUnCashRows] = useState([] as any)
const [unStockRows, setUnStockRows] = useState([] as any)
const [materialList, setMaterialList] = useState([])
const [searchMatlist, setSearchMatlist] = useState([])
const [loadFlag, setLoadFlag] = useState(false)
const [modalStock, setModalStock] = useState({value: 0})
const [modalLoading, setModalLoading] = useState(false)
const [submodalLoading, setSubmodalLoading] = useState(false)
const [exportLoading, setExportLoading] = useState(false)
const [detailList, setDetailList] = useState([])
const [modalFlag, setModalFlag] = useState('')
let [formInitials, setFormInitials] = useState({...initForminitial})
let [subformInitials, setSubformInitials] = useState({...initSubforminitial})
const [showModal, setShowModal] = useState(false)
const [showSubmodal, setShowSubmodal] = useState(false)
const [staffList, setStaffList] = useState([])
const [levelList1, setLevelList1] = useState([])
const [levelList2, setLevelList2] = useState([])
const [compBillList,setCompBillList] = useState([])
const [modalbillMount, setModalbillMount] = useState(0)
let [modalEndym, setModalEndym] = useState(moment().format('YYYY-MM'))
const [searchYmRange,setSearchYmRange] = useState([] as any)
const [modalcompanyItem,setModalcompanyItem] = useState({} as any)
const [srhMatitem,setSrhMatitem] = useState({} as any)
const [srhYmdrange,setSrhYmdrange] = useState('' as any)
const [statList, setStatList] = useState([])

 /*  funcs */
//路由刷新请求
const routerFlash = () => {
  // uniAxList(dataRow())
  // uniAxList(uniMaterialListData)
  // uniAxList(uniCaData)
  uniAxList(uniCompanyData)
}
const searchConfirm = async (formValus:any) => {
  pagination.current = 1
  setPagination({...pagination,current:1});
  // searchParams = {...initSearchParams,...formValus}
  // setSelectedRows([])
  // setSelectedRowKeys([])
  searchParams = await uniFormProcess({...initSearchParams,...formValus},searchList)
  setTableParams({...searchParams})
  setSearchParams(searchParams)
  uniAxList(dataRow())
}
//modal点击确定
const modalConfirm = async (formValues:any) => {
  let tempdatarow
  let tempitems
  if (modalFlag === 'new') {
    if (formValues.companyBillId && Number(formValues.totalPrice) !== modalbillMount) return message.error('采购金额和公司收支账单金额不相等')
    tempdatarow = dataNewrow
    tempitems = formItems
  }
  else if (modalFlag === 'update') {
    if (!formValues.companyBillId && currentRow.companyBillId) return message.error('不能删除关联公司收支登记')
    if (formValues.companyBillId && Number(formValues.totalPrice) !== modalbillMount) return message.error('采购金额和公司收支账单金额不相等')
    tempdatarow = dataUpdaterow
    tempitems = formItems
  }
  else if (modalFlag === 'instock') {tempdatarow = dataInstockrow;tempitems = instockItems}
  else if (modalFlag === 'detail') {
    if (formValues.companyBillId && Number(formValues.totalPrice) !== modalbillMount) return message.error('采购金额和公司收支账单金额不相等')
    tempdatarow = dataUpdaterowCompbill
    tempitems = detailformItems
  }
  let tempparms = await uniFormProcess(formValues,tempitems)
  if (tempdatarow) uniAxList(tempdatarow(tempparms))
}
//submodal点击确定
const submodalConfirm = async (formValues:any) => {
  let tempparms = await uniFormProcess(formValues,materialformItems)
  uniAxList(dataMaterialCreate(tempparms))
}
//点击新建物料图标
const oncreateMaterial = () => {
  let companyId = modalRef.current?.getFieldValue('companyId')
  if (!companyId) return message.error('请先选择公司')
  setShowSubmodal(true)
  setSubformInitials({companyId,initialStock:0,stock:0,startDate:moment()})
}
//图例的option
const statOption = () => ({
  // color: ['#00d462'],
  dataset: {dimensions: modalFlag==='allcharts'?['materielName','buyNum']:['ym','buyNum'],source: statList},
  xAxis: [{type: 'category',axisLabel: {interval: 0,rotate: -60}}],
  yAxis: [{type: 'value'}],
  series:[
    {name: '采购额',type: 'bar'},
  ],
  tooltip: {trigger: 'axis',axisPointer: {type: 'cross'},},
  // legend: {data: ['采购额']},
  // toolbox: {
  //   feature: {saveAsImage: {}}
  // },
  grid: {top:10,left: '3%',bottom: '2%',containLabel: true},
})

/* 列表 */
const searchList:FormItemObj[] = [
{label: '公司', prop:'filter_EQL_company_id',type:'select',dataList:companyList,required:true,clearable:false,change:(value:any)=>{
  searchRef.current?.setFieldsValue({'filter_BETWEENS_buy_time':null})
  let finditem:any = companyList.find((item:any)=>item.id===value)
  if (finditem.initialize===1) setSearchYmRange([finditem.startYm,finditem.endYm])
  else setSearchYmRange([])
  uniAxList(dataMaterialList(value))
}},
{label: '采购日期', prop:'filter_BETWEENS_buy_time',type:'dateRange',daterangeProps:{returnType: 'string',disabledDate:(current)=>{
  if (searchYmRange.length<2) return true
  else return current<moment(searchYmRange[0]).startOf('month') || current>moment(searchYmRange[1]).endOf('month')
}}},
// {label: '物料名', prop:'filter_LIKES_materiel_name'},
{label: '物料', prop:'filter_EQS_materiel_id',type:'select',dataList:searchMatlist,change:(value:any)=>{
  let finditem:any = searchMatlist.find((item:any)=>item.id===value)
  setSrhMatitem(finditem)
}},
{label: '入库状态', prop:'filter_EQL_stock_status',type:'select',dataList:stockStatusList},
{label: '凭证状态', prop:'filter_EQL_proof_status',type:'select',dataList:proofStatusList},
]
const columns:ColumnItem[] = [
{title: '物料名称',key: 'materielName',type:'underline',unlineProps:{action: async ({row,value}:any)=>{
  setCurrentRow(row)
  setFormInitials(uniInitFormdata(row,detailformItems))
  setModalFlag('detail')
  setShowModal(true)
  let resdata = await uniAxList(dataCompBillList(row.companyId))
  if (row.companyBillId) {
    let finditem:any = resdata.find((item:any)=>item.id===row.companyBillId)
    if (finditem) {
      let temp = (finditem.price /100).toFixed(2).split('.')[1] === '00'?Number((finditem.price /100).toFixed(0)):Number((finditem.price /100).toFixed(2))
      setModalbillMount(temp)
    }
  }
}}},
{title: '物料单位',key: 'unit'},
{title: '公司收支ID',key: 'companyBillId',type:'underline',unlineProps:{action:({row,value}:any)=>navigate('/indexpage/compayment', {replace: true, state: {billid:value}})}},
{title: '公司',key: 'companyName'},
{title: '采购人',key: 'buyUserName'},
{title: '采购单价',key: 'price',custval:({row,value}:any)=>value?(value / 100).toFixed(2):0},
{title: '采购数量',key: 'num'},
{title: '采购金额',key: 'totalPrice',custval:({row,value}:any)=>value?(value / 100).toFixed(2):0},
{title: '采购日期',key: 'buyTime', custval:({row,value}:any) => value ? value.split(' ')[0] : ''},
{title: '采购备注',key: 'remark'},
{title: '入库状态',key: 'stockStatus',type:'tag',custval:({row,value}:any)=>uniValueToName({list:stockStatusList,value})},
{title: '入库数量',key: 'stockNum'},
{title: '入库人',key: 'stockUserName'},
{title: '入库日期',key: 'stockTime',custval:({row,value}:any) => value ? value.split(' ')[0] : ''},
{title: '入库备注',key: 'stockRemark'},
{title: '操作',key:'operation',type: 'operation',width:160,opProps:{opList:[
  {name:'入库',disabled:({row}:any)=>row.stockStatus===1,action:({row}:any) =>{
    setFormInitials({stockNum:row.num, stockTime:moment()});
    setModalFlag('instock');setCurrentRow(row);setShowModal(true);
  }
  },
  {name:'修改',disabled:({row}:any)=>row.isPay==1,action: async ({row}:any) => {
    let finditem:any = companyList.find((item:any)=>item.id===row.companyId)
    // setModalEndym(finditem.endYm)
    setModalcompanyItem(finditem)
    uniAxList(dataCompBillList(row.companyId,row.companyBillId))
    let resdata = await uniAxList(dataMaterialList(row.companyId))
    let findItem:any = uniFilterItem(resdata, 'id', row.materielId)
    setModalFlag('update')
    setCurrentRow(row)
    setFormInitials({...uniInitFormdata(row,formItems),restStock:findItem?.stock || 0})
    setShowModal(true)
    uniAxList(dataAccountList(row.companyId))
    uniAxList(dataStaffList(row.companyId))
    uniAxList(dataAffixdetail8(row.companyId))
  }},
  {name:'删除',disabled:({row}:any)=>row.isPay==1,action:({row}:any) =>uniOpAlarm(()=>uniAxList(dataDelrow(row.id)))}
]}}
]
const bottomActions:ColumnAction[] = [
{label: '新增', type:'primary', action: () => {setModalFlag('new');setShowModal(() => true)}},
{label: '导出',type:'primary',loading:exportLoading,disabled: rowList.length<=0,action: () =>uniExportExcel({
  exportLoading,columns:columns.slice(0,-1),title:'物料采购明细',fileName:'物料采购明细',
  showSummary:false,sumProps:initsumProps,axApi:getMaterialByList,tableParams,setExportLoading
})},
{label: '所有物料统计图', type:'primary',isDanger:true,action: () => {
  let tempcompany = searchRef.current?.getFieldValue('filter_EQL_company_id')
  if (!tempcompany) return message.error('请选择公司')
  let timerange = searchRef.current?.getFieldValue('filter_BETWEENS_buy_time')
  if (!timerange) return message.error('请选择日期范围')
  let rangestr = moment(timerange[0]).format('YYYY-MM') + ' 到 ' + moment(timerange[1]).format('YYYY-MM')
  setSrhYmdrange(rangestr)
  setModalFlag('allcharts')
  setShowModal(() => true)
  uniAxList(dataAllstat(tempcompany,moment(timerange[0]).format('YYYY-MM'),moment(timerange[1]).format('YYYY-MM')))
}},
{label: '单个物料统计图', type:'primary',isDanger:true,action: () => {
  let tempcompany = searchRef.current?.getFieldValue('filter_EQL_company_id')
  if (!tempcompany) return message.error('请选择公司')
  let timerange = searchRef.current?.getFieldValue('filter_BETWEENS_buy_time')
  if (!timerange) return message.error('请选择日期范围')
  let timpmat = searchRef.current?.getFieldValue('filter_EQS_materiel_id')
  if (!timpmat) return message.error('请选择物料')
  let rangestr = moment(timerange[0]).format('YYYY-MM') + ' 到 ' + moment(timerange[1]).format('YYYY-MM')
  setSrhYmdrange(rangestr)
  setModalFlag('singlecharts')
  setShowModal(() => true)
  uniAxList(dataSinglestat(tempcompany,moment(timerange[0]).format('YYYY-MM'),moment(timerange[1]).format('YYYY-MM'),timpmat))
}},
]

/* modal */
//详情
const detailformItems:FormItemObj[] = columns.slice(0,-1).map((colitem:any)=>{
  return {label:colitem.title,prop:colitem.key,required:false,readonly:true,clearable:false,custval:colitem.custval}
})

//新增和修改
const formItems:FormItemObj[] = [
{label: '选择公司', prop: 'companyId', clearable:false, type:'select', selectProps:{requireName:'companyName'}, dataList: companyList,change:(value:any)=>{
  let finditem:any = companyList.find((item:any)=>item.id===value)
  if (finditem.initialize===0) {
    modalRef.current?.setFieldsValue({companyId:null})
    return message.error('该公司未初始化, 不能新建采购')
  }
  // setModalEndym(finditem.endYm)
  setModalcompanyItem(finditem)
  modalRef.current?.setFieldsValue({buyUserId:null,materielId:null,unit:null, price:null,restStock:null, totalPrice:null,payAccountId:null,companyBillId:null})
  uniAxList(dataMaterialList(value))
  uniAxList(dataAccountList(value))
  uniAxList(dataStaffList(value))
  uniAxList(dataCompBillList(value))
  uniAxList(dataAffixdetail8(value))
}},
{label: '采购人',prop: 'buyUserId',clearable:false,type:'select',selectProps:{requireName:'buyUserName'},dataList:staffList},
{label: '物料名称',prop: 'materielId',clearable:false,type:'select',selectProps:{requireName:'materielName'},dataList:materialList,change: (value:any)=>{
  let findItem:any = uniFilterItem(materialList, 'id', value)
  modalRef.current?.setFieldsValue({unit:findItem.unit, price:null,restStock:findItem.stock, totalPrice:null, num:null})
},suffix: <div style={{height:32,display:'flex',alignItems:'center'}}><PlusCircleOutlined style={{color:'#1890ff',fontSize:20,cursor:'pointer'}} onClick={oncreateMaterial} /></div>},
{label: '物料单位', prop: 'unit', disabled:true},
{label: '采购单价', prop: 'price',type:'price',change:(value:any)=>{
  let buyNum = modalRef.current?.getFieldValue('num')
  modalRef.current?.setFieldsValue({totalPrice:buyNum ? buyNum * value : 0})
}},
{label: '当前库存', prop: 'restStock', disabled:true},
{label: '采购数量', prop: 'num', type:'number', change:(value:any)=>{
  let buyPrice = modalRef.current?.getFieldValue('price')
  modalRef.current?.setFieldsValue({totalPrice:buyPrice ? buyPrice * value : 0})
}},
{label: '采购金额', prop: 'totalPrice', type:'price',change:(value:any)=>{
  let buyNum = modalRef.current?.getFieldValue('num')
  modalRef.current?.setFieldsValue({price:buyNum ? value / buyNum : 0})
}},
{label: '采购时间', prop: 'buyTime', type:'datePicker', datepickerProps:{returnType:'string',disabledDate:(current)=>current<moment(modalcompanyItem.beginYm).startOf('month') || current>moment(modalcompanyItem.endYm).endOf('month')}},
{label: '收支登记', prop: 'companyBillId',type:'select', dataList:compBillList,required:false,suffix: <span>{'金额'+modalbillMount}</span>,change: (value:any)=>{
  if (value) {
    let finditem:any = compBillList.find((item:any)=>item.id===value)
    if (finditem) {
      let temp = (finditem.price /100).toFixed(2).split('.')[1] === '00'?Number((finditem.price /100).toFixed(0)):Number((finditem.price /100).toFixed(2))
      setModalbillMount(temp)
    }
  } else setModalbillMount(0)
}},
{label: '备注',prop: 'remark', required:false},
]

//确认入库
const instockItems:FormItemObj[] = [
{label: '入库时间',prop: 'stockTime', type:'datePicker', datepickerProps:{returnType:'string'}},
{label: '入库数量',prop: 'stockNum', type:'number'},
{label: '入库备注',prop: 'stockRemark'}
]

/* submodal */
//添加和修改中的modal项
const materialformItems:FormItemObj[] = [
{label: '编码',prop: 'code',type:'slot',slotProps:<Input.Search size='small' type={'number'}
  placeholder="输入编码"
  enterButton="自动生成"
  style={{width: '100%'}}
  onSearch={async (value) => {
    let res = await getMaterialList({orderBy:"code", orderDir: "desc",page:1, size:1})
    let maxcode = 0
    if (res.data.records.length > 0) maxcode = Number(res.data.records[0].code)
    let newcode = (maxcode+1).toString().padStart(7, '0')
    submodalRef.current?.setFieldsValue({code: newcode})
  }}
  onBlur={(e) => {
    modalRef.current?.setFieldsValue({code: e.target.value.toString().padStart(7, '0')})
  }}/>
},
{label: '名称',prop: 'name'},
{label: '所属公司',prop: 'companyId',type:'select',dataList:companyList,selectProps:{requireName:'companyName'},disabled:true},
{label: '一级类目',prop: 'levelOneId',type:'select',dataList:levelList1,change:(value:any)=>{
  submodalRef.current?.setFieldsValue({levelTwoId:null})
  uniAxList(dataLevel2(value))
}},
{label: '二级类目',prop: 'levelTwoId',type:'select',dataList:levelList2,change:(value:any)=>{
  if (value) {
    let finditem:any = levelList2.find((item:any)=>item.id===value)
    if (finditem) submodalRef.current?.setFieldsValue({unit:finditem.unit})
  }
}},
{label: '单位',prop: 'unit'},
{label: '初始库存',prop: 'initialStock',type:'number', numProps: {min:0,precision:0},disabled:true},
{label: '当前库存',prop: 'stock',type:'number',numProps: {min:0,precision:0},disabled:true},
{label: '采购价格',prop: 'buyPrice',type:'price',numProps: {min:0,precision:2}},
{label: '销售价格',prop: 'salePrice',type:'price',numProps: {min:0,precision:2}},
{label: '库存启用时间',prop: 'startDate',type:'datePicker',datepickerProps:{returnType:'string',disabledDate:(current)=>false},disabled:true},
{label: '排序号',prop: 'orderNo',type:'number',required:false,numProps: {min:0,precision:0}},
{label: '备注',prop: 'remark',span:24,required:false}
]

/* 接口参数 */
const dataRow = (current?:number):AxDataform => ({
  params: {...searchParams, current: current || pagination.current, size:pagination.pageSize},
  axApi: getMaterialByList,
  setList: setRowList,
  setTableLoading,
  setPagination
})
//新建行
const dataNewrow = (params:any):AxDataform => ({
  params,
  axApi: createMaterialBuy,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//修改行
const dataUpdaterow = (params:any):AxDataform => {
  let billchange = (currentRow.companyBillId !== params.companyBillId)
  let rowid = currentRow.id
  return {
    params: {...params, id:currentRow.id},
    axApi: updateMaterialBuy,
    setModalLoading,
    setShowModal,
    awaitFunc: async (predata:any) => {
      if (billchange) await uniAxList(dataUpdaterowCompbill({companyBillId:params.companyBillId},rowid))
      else uniAxList(dataRow())
    }
  }
}
//修改关联公司收支登记账单
const dataUpdaterowCompbill = (params:any,rowid?:any):AxDataform => ({
  params: {companyBillId:params.companyBillId, id: currentRow.id || rowid},
  axApi: updateMaterialBuyCompbill,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//删除行
const dataDelrow = (rowid:any):AxDataform => ({
  params: {id:rowid},
  axApi: deleteMaterialBuy,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//确认付款
const dataPayrow = (params:any):AxDataform => ({
  params: {...params, id:currentRow.id},
  axApi: confirmMaterialBuy,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//确认入库
const dataInstockrow = (params:any):AxDataform => ({
  params: {...params, id:currentRow.id},
  axApi: confirmMaterialStock,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//新建物料
const dataMaterialCreate = (params:any):AxDataform => ({
  params,
  axApi: createMaterial,
  setModalLoading:setSubmodalLoading,
  setShowModal:setShowSubmodal,
  awaitFunc: (predata:any) => {
    uniAxList(dataMaterialList(params.companyId))
  }
})
//公司账户
const dataAccountList = (companyid:any):AxDataform => ({
  params: {companyId:companyid},
  axApi: getCompanyAccoutList,
  setList: setAccountList
})
const dataMaterialList = (companyid:any):AxDataform => ({
  params:{filter_EQS_company_id: companyid},
  axApi: getMaterialList,
  setList: modalFlag ? setMaterialList : setSearchMatlist,
})
//人员列表
const dataStaffList = (companyId:any):AxDataform => ({
  params: {size:999,groupItemId:companyId},
  axApi: getStaffList,
  setList: setStaffList,
})
//物料一级类目
const dataLevel1:AxDataform = {
  axApi: getMaterialLevel1,
  setList: setLevelList1
}
//物料二级类目
const dataLevel2 = (parentId:any):AxDataform => ({
  params: {parentId},
  axApi: getMaterialLevel2,
  setList: setLevelList2
})
//公司收支账单
const dataCompBillList = (companyId:any,billid?:any):AxDataform => ({
  params: {'filter_EQL_c.business_id':7,'filter_EQS_c.company_id':companyId,'filter_EQB_c.is_cancel': false,size:999},
  axApi: companyBillList,
  itemModify: {modifyFunc: ({item}:any)=>item.name='凭证号 '+item.accountProof.map((subitem:any)=>subitem.accountProofNo).join(',')},
  setList: setCompBillList,
  awaitFunc: (predata:any) => {
    if (billid) {
      let finditem:any = predata.find((item:any)=>item.id===billid)
      if (finditem) {
        let temp = (finditem.price /100).toFixed(2).split('.')[1] === '00'?Number((finditem.price /100).toFixed(0)):Number((finditem.price /100).toFixed(2))
        setModalbillMount(temp)
      }
    }
  }
})
const dataAffixdetail8 = (companyId:any):AxDataform => ({
  params: {accountingId: 8,companyId},
  axApi: axAffixSubjectDetail,
  setList: setDetailList,
})
const dataAllstat = (companyId:any,beginYm:any,endYm:any):AxDataform => ({
  params: {companyId,beginYm,endYm},
  axApi: axAllpurchStat,
  // setList: setStatList,
  awaitFunc: (predata:any)=>{
    setStatList(predata.slice(0,46))
  }
})
const dataSinglestat = (companyId:any,beginYm:any,endYm:any,materielId:any):AxDataform => ({
  params: {companyId,beginYm,endYm,materielId},
  axApi: axSinglepurchStat,
  setList: setStatList,
  awaitFunc: (predata:any)=>{
    // setStatList(predata.slice(-12,-1))
  }
})
const uniCompanyData:any = {
  axApi: getCompanyList,
  setList: setCompanyList,
}

/* useEffect */
//监听路由变化
React.useEffect(() => {
  if (loadFlag && location.pathname===pathname) routerFlash()
}, [location])
/* mounted */
React.useEffect(() => {
  // uniAxList(dataRow())
  // uniAxList(uniMaterialListData)
  // uniAxList(uniCaData)
  uniAxList(uniCompanyData)
  // uniAxList(uniAffixdetail8)
  uniAxList(dataLevel1)
  setLoadFlag(()=>true)
}, [])

return (
  <>
    <TopSearch
      ref={searchRef}
      searchList={searchList}
      searchConfirm={searchConfirm}
    />
    <SuperTable
      columns={columns}
      rowList={rowList}
      bottomActions={bottomActions}
      tableLoading={tableLoading}
      pagination={pagination}
      dataRow={dataRow}
    />
    <FormDialog
      ref={modalRef}
      type={['new','update','detail'].includes(modalFlag)?'drawer':'modal'}
      modalWith="80%"
      modalHeight={window.innerHeight * 0.7}
      title={modalFlag==='new'?'新增采购':modalFlag==='update'?'修改采购':modalFlag==='pay'?'确认付款':modalFlag==='detail'?'详情':modalFlag==='allcharts'?`图例 ${srhYmdrange}`:modalFlag==='singlecharts'?`图例 ${srhMatitem.name} ${srhYmdrange}`:'确认入库'}
      show={showModal}
      formItems={modalFlag==='instock'?instockItems:modalFlag==='detail'?detailformItems:['allcharts','singlecharts'].includes(modalFlag)?[]:formItems}
      modalLoading={modalLoading}
      formInitials={formInitials}
      hasConfirm={['detail','allcharts','singlecharts'].includes(modalFlag)?false:true}
      showCustom={['allcharts','singlecharts'].includes(modalFlag)?true:false}
      cancel={()=>{
        setModalFlag('')
        setShowModal(()=>false)
        setCurrentRow({})
        setCompBillList([])
        setStatList([])
        setModalbillMount(0)
        setFormInitials({...initForminitial})
      }}
      confirm={modalConfirm}
    >
      <MainEcharts option={statOption()} chartStyle={{ background: '#fff', height: window.innerHeight * 0.6, width: window.innerWidth * 0.7 }}/>
      <></>
    </FormDialog>
    <FormDialog
      ref={submodalRef}
      title="新增物料"
      show={showSubmodal}
      formItems={materialformItems}
      modalLoading={submodalLoading}
      formInitials={subformInitials}
      cancel={()=>{
        setModalcompanyItem({})
        setShowSubmodal(()=>false)
        setSubformInitials({...initSubforminitial})
      }}
      confirm={submodalConfirm}
    />
    {/* <Modal
      width={600}
      maskClosable={false}
      title={'图标'}
      open={showCharts}
      onCancel={()=>setShowCharts(false)}
      destroyOnClose
      footer={[<Button key="back" onClick={()=>setShowCharts(false)}>取消</Button>]}
    >
      <ReactEchartsCore
        style={{ background: '#fff', height: '376px', width:'400px' }}
        echarts={echarts}
        option={alloption}
      />
    </Modal> */}
    {/* <FormModal
      ref={formModalRef}
      title='确认付款'
      modalVisible={confirmModal}
      initialValues={{}}
      quitModal={uniQuitModal(setConfirmModal)}
      modalConfirm={uniChgRowSubmit({currentRows:unCashRows, axData:dataRow(),updateApi:confirmMaterialBuy,setModal:setConfirmModal,setModalLoading})}
      modelItems={payItems}
      modalLoading={modalLoading}
    />
    <FormModal
      ref={formModalRef}
      title='确认入库'
      modalVisible={stockModal}
      initialValues={{}}
      quitModal={uniQuitModal(setStockModal)}
      modalConfirm={uniChgRowSubmit({currentRows:unStockRows, axData:dataRow(),updateApi:confirmMaterialStock,setModal:setStockModal,setModalLoading})}
      modelItems={instockItems}
      modalLoading={modalLoading}
    /> */}
  </>
)
}
