import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ConfigProvider, DatePicker, FormInstance, Select, Table, TablePaginationConfig } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { companyAccountList, getCompanyAccoutList, getCompanyList } from '../../utils/api'
import { uniAxList, uniAxSearchList, uniCurrentTime, uniDeepArray, uniExportExcel, uniFilterItem, uniFormProcess, uniModifyAxList, uniSelectRows, uniValueToName } from '../../utils/uniFucs'
import SummeryRow from '../../components/SummeryRow'
import { csDateRages, isCheckList, reconciliationStatusList } from '../../utils/constant'
import { ModifyParams } from '../../utils/beans';
import tablecss from '../../css/custable.module.less'
import { AxDataform, ColumnItem, FormItemObj } from '../../utils/interfaces';
import TopSearch from '../../components/TopSearch';
import SuperTable from '../../components/SuperTable';

export default function FirmAccountCheck() {
const pathname = "/indexpage/firmaccountcheck"
const initSearchParams = {page:1,orderBy:"ymd", orderDir: "asc"}
/* hooks */
let location = useLocation()
const navigate = useNavigate()
const [companyList, setCompanyList] = useState([])
const [rowList, setRowList] = useState([])
const [selectedRowKeys, setSelectedRowKeys] = useState([])
const [selectedRows, setSelectedRows] = useState([])
const [currentRow, setCurrentRow] = useState({} as any)
let [searchParams, setSearchParams] = useState({...initSearchParams} as any)
// let [searchParams, setSearchParams] = useState({payTimeBegin:uniCurrentTime({})+' 00:00:00', payTimeEnd:uniCurrentTime({})+' 23:59:59'} as any)
const [tempSearchParams, setTempSearchParams] = useState({payTimeBegin:uniCurrentTime({})+' 00:00:00', payTimeEnd:uniCurrentTime({})+' 23:59:59'} as any)
const [staffList, setStaffList] = useState([])
const [reinburseTypeList, setReinburseTypeList] = useState([])
const [tableLoading, setTableLoading] = useState(false)
const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 10})
const [staticTableData, setStaticTableData] = useState([] as any)
const [accountList, setAccountList] = useState([])
const [loadFlag, setLoadFlag] = useState(false)
const searchRef = createRef<FormInstance>()
const [searchYmRange,setSearchYmRange] = useState([] as any)
let [pagerowList,setPagerowList] = useState([])

/* funcs */
const searchConfirm = async (formValus:any) => {
  pagination.current = 1
  setSelectedRows([])
  setSelectedRowKeys([])
  searchParams = await uniFormProcess({...initSearchParams,orderDir:searchParams.orderDir,...formValus},searchList)
  setSearchParams({...searchParams})
  uniAxList(dataRow())
}
//路由刷新请求
const routerFlash = () => {
  // uniAxList(dataRow())
  uniAxList(uniCompanyData)
  // uniAxList(uniSaData)
}
//排序
const tableChange = (pagination:any, filters:any, sorter:any) => {
  let companyid = searchRef.current?.getFieldValue('companyId')
  if (companyid && sorter.order) {
    if (sorter.order==='descend') searchParams = {...searchParams, orderDir:'desc'}
    else searchParams = {...searchParams, orderDir:'asc'}
    setSearchParams(searchParams)
    uniAxList(dataRow())
  } 
}

/* elements */
//搜索行
const searchList:FormItemObj[] = [
{label: '公司', prop:'companyId',type:'select',dataList:companyList,clearable:false,required:true,change:(value:any)=>{
  searchRef.current?.setFieldsValue({accountId:null})
  if (value) uniAxList(dataSaData(value))
  else setAccountList([])
  searchRef.current?.setFieldsValue({'myrange':null})
  let finditem:any = companyList.find((item:any)=>item.id===value)
  if (finditem.initialize===1) setSearchYmRange([finditem.startYm,finditem.endYm])
  else setSearchYmRange([])
}},
{label: '账户', prop:'accountId',width:250,type:'select',dataList:accountList,selectProps:{childrenKeys:['name','cardNo']}},
{label: '状态', prop:'reconciliationStatus',type:'select',dataList:reconciliationStatusList},
{label: '收付日期', prop:'myrange',type:'dateRange',daterangeProps:{returnType:'string',rangeKeys:['payTimeBegin','payTimeEnd'],disabledDate:(current)=>{
  if (searchYmRange.length<2) return true
  else return current<moment(searchYmRange[0]).startOf('month') || current>moment(searchYmRange[1]).endOf('month')
}}}
]
//列项
const columns:ColumnItem[] = [
  {title: '账户名称',key: 'accountName'},
  {title: '所属公司',key: 'companyName'},
  {title: '收付款日期',key: 'ymd',sorter:true,sortDirections:['descend','ascend']},
  {title: '期初余额',key: 'startMoney',type:'price'},
  {title: '收入金额',key: 'inMoney',type:'price'},
  {title: '支出金额',key: 'outMoney',type:'price'},
  {title: '账户余额',key: 'endMoney',type:'price'},
  {title: '对账状态',key: 'reconciliationStatus',custval:({row,value}:any)=>uniValueToName({list:reconciliationStatusList,value})},
]

  /* 接口参数 */
  const dataRow = ():AxDataform => ({
    params: searchParams,
    axApi: companyAccountList,
    // setList: setRowList,
    setTableLoading,
    awaitFunc:(predata:any)=>{
      if(searchParams.orderDir==='asc') setRowList(predata.reverse())
      else setRowList(predata)
      // setPagination({...pagination,total:predata.length})
      // setPagerowList(predata.slice(0,pagination.pageSize))
    }
  })
  const uniCompanyData:any = {
    axApi: getCompanyList,
    setList: setCompanyList,
  }
  //公司账户
  const dataSaData = (companyId:any):AxDataform => ({
    params:{companyId},
    axApi: getCompanyAccoutList,
    setList: setAccountList
  })
  /* useEffect */
  //监听路由变化
  React.useEffect(() => {
    if(loadFlag && location.pathname===pathname) routerFlash()
  }, [location])
  /* mounted */
  React.useEffect(() => {
    // uniAxList(dataRow())
    uniAxList(uniCompanyData)
    // uniAxList(uniSaData)
    setLoadFlag(()=>true)
  }, [])

  return (
    <>
      <TopSearch
        ref={searchRef}
        searchList={searchList}
        searchConfirm={searchConfirm}
        initialValues={initSearchParams}
      />
      <SuperTable
        columns={columns}
        rowList={rowList}
        tableLoading={tableLoading}
        inPagination={(page:any, pageSize:any)=>{
          setPagerowList(rowList.slice(pageSize*(page-1),pageSize*page))
        }}
        dataRow={dataRow}
        rowSelection={{setSelectedRowKeys, setSelectedRows, selectedRowKeys}}
        tableChange={tableChange}
      />
    </>
  )
}
