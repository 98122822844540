import React, { Fragment, useState } from 'react'
import {Button, ConfigProvider, DatePicker, Input, InputNumber, Pagination, Select, Switch, Table, Tag, TreeSelect, Typography, Upload, UploadFile, UploadProps} from 'antd'
import locale from 'antd/es/locale/zh_CN';
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { uniAxList, uniSelectRows, uniValueToName } from '../utils/uniFucs';
import supertcss from '../css/supertable.module.less'
import { csDateRages } from '../utils/constant';

type tableProp = {columns:object[], rowList:object[], expandable?:any, rowSelection?:any, onRow?:any,strip?:boolean,summary?:any, scroll?:any,inPagination?:any,pagination?:any, bordered?:boolean, tableLoading?:boolean, rowKey?:string, dataRow?:any, tableChange?:any, bottomActions?:object[],tableClass?:string}
const tagColors = ['red','green','volcano','blue','magenta','lime','orange','geekblue','purple','cyan','gold','#108ee9']
export default function SuperTable(props:tableProp) {
console.log('supertable------**********---------')

/* funcs */
//修饰原始columns
const modifCol = (itemlist:any,parent?:any) => {
  let temparr:any = itemlist.map((item:any)=>{
    let tempitem
    if (item.children) {
      tempitem = item
      modifCol(item.children,item)
    } 
    tempitem = {...item, ellipsis: item.ellipsis===false?false:{showTitle:true}, dataIndex:item.key, fixed:(item.type==='operation' && item.fixed!==false)?'right':false}
    if (!item.render) tempitem.render = (value: any, row: any, index:any) => {
      let temp = <></>
      // if (!item.type) temp = <div style={{minWidth:60,display:'flex'}}>{item.custval?item.custval({row,value,index}):value}</div>
      if (!item.type) temp = <span style={{minWidth:item.maxWidth && item.maxWidth < 60 ?item.maxWidth:60,maxWidth:item.maxWidth || 200,display:'inline-block'}}>{item.custval?item.custval({row,value,index}):value}</span>
      // else if (item.type==='price') temp = <span style={{minWidth:item.maxWidth && item.maxWidth < 60 ?item.maxWidth:60,maxWidth:item.maxWidth || 200,display:'inline-block'}}>{(value && !isNaN(value)) ? (value%100===0?(value/100).toFixed(0):(value/100).toFixed(2)) :''}</span>
      else if (item.type==='price') temp = <span style={{minWidth:item.maxWidth && item.maxWidth < 60 ?item.maxWidth:60,maxWidth:item.maxWidth || 200,display:'inline-block'}}>{(value && !isNaN(value)) ? (value/100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") :0}</span>
      else if (item.type==='toname') temp = <span style={{minWidth:item.maxWidth && item.maxWidth < 60 ?item.maxWidth:60,maxWidth:item.maxWidth || 200,display:'inline-block'}}>{uniValueToName({list:item.tonameProps.dataList,value})}</span>
      else if (item.type==='datetime') temp = <span style={{minWidth:item.maxWidth && item.maxWidth < 60 ?item.maxWidth:60,maxWidth:item.maxWidth || 200,display:'inline-block'}}>{value ? value.split(' ')[0] : ''}</span>
      else if (item.type==='underline') temp = <div style={{textDecoration:'underline',cursor:'pointer',minWidth:item.maxWidth && item.maxWidth < 60 ?item.maxWidth:60,maxWidth:item.maxWidth || 200,color:item.unlineProps.color}} onClick={()=>item.unlineProps.action({row,value,index})}>{item.custval?item.custval({row,value,index}):value}</div>
      else if (item.type==='operation') temp = (
        item.opProps.opList.map((opitem:any)=>{
          if (opitem.show && !opitem.show({row,index})) return null
          else if (opitem.disabled && opitem.disabled({row,index})) return <span style={{color:'#bbb',marginRight: 8, padding: '0 9px'}}>{opitem.name}</span>
          else if (item.opProps.type==='button') return <Button style={{marginRight:10, backgroundColor:opitem.bgColor?opitem.bgColor(row):''}} size='small' type="primary" onClick={()=>opitem.action({row,index})}>{opitem.name}</Button>
          else return <Typography.Link key={opitem.name} onClick={()=>opitem.action({row,index})} style={{ marginRight: 8, color: opitem.color, padding: '0 9px'}}>{opitem.name}</Typography.Link>
        })
      )
      else if (item.type==='tag') {
        let findindex = 0
        let tempvalus = (item.tagProps && item.tagProps.values && item.tagProps.values.length>0)?item.tagProps.values:[0,1]
        tempvalus.forEach((valuekey:any,keyindex:any)=>{
          if (valuekey === value) findindex = keyindex
        })
        temp = (item.custval?item.custval({row,value,index}):value)
          ?<Tag style={{minWidth:item.maxWidth && item.maxWidth < 40 ?item.maxWidth:40,maxWidth:item.maxWidth || 200}} color={tagColors[findindex]}>{item.custval?item.custval({row,value,index}):value}</Tag>
          :<span></span>
      }
      else if (item.type==='download') temp = value ? <a href={value} download={item.downloadProps.fileName}>{item.downloadProps.buttonName || '下载'}</a> : <></>
      else if (['select','number','input','switch'].includes(item.type)) temp = getElement(item,item.type,row)
      return temp
    }
    return tempitem
  })
  if (parent) parent.children = temparr
  return temparr
}
const handleUpload = (loadaction:any) => {
  const formData = new FormData();
  fileList.forEach((file) => {
    formData.append('file', file as any);
  });
  setUploading(true);
  loadaction(formData,setUploading)
  // You can use any AJAX library you like
  // fetch('https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188', {
  //   method: 'POST',
  //   body: formData,
  // })
  //   .then((res) => res.json())
  //   .then(() => {
  //     setFileList([]);
  //     message.success('upload successfully.');
  //   })
  //   .catch(() => {
  //     message.error('upload failed.');
  //   })
  //   .finally(() => {
  //     setUploading(false);
  //   });
};

  /* props */
  const {columns, rowList, rowSelection, scroll,inPagination,pagination, onRow, tableLoading, rowKey='id', dataRow, tableChange, bottomActions, strip=true, bordered=true, summary,expandable,tableClass} = props
  let effectCols = columns.filter((colitem:any)=>!colitem.hide)
  let effectBottomActions = bottomActions?.filter((actitem:any)=>!actitem.hide)
  let cols = modifCol(effectCols)

  /* 文件上传相关 */
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const fileprops: UploadProps = {
    // onChange({file,fileList}:any) {
    //   if (!fileList.length) setFileList(fileList)
    // },
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      setFileList([file])
      return false
    },
    fileList
  }

  return (
    <>
    <ConfigProvider locale={locale}>
    <Table
      className={tableClass}
      bordered={bordered}
      style={{flex:1,overflowY:'hidden'}}
      rowKey={rowKey}
      columns={cols}
      rowSelection={rowSelection?{
        type: rowSelection.type,
        onChange: (selectedRowKeys: any, selectedRows: any) => {
          rowSelection.setSelectedRowKeys(() => selectedRowKeys)
          rowSelection.setSelectedRows(() => selectedRows)
        },
        onSelect: (record: any, selected: any, selectedRows: any) => {},
        onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {},
        selectedRowKeys:rowSelection.selectedRowKeys,
        checkStrictly:true
      }:false as any}
      dataSource={rowList}
      pagination={false}
      loading={tableLoading}
      expandable={expandable}
      scroll={{scrollToFirstRowOnChange:true,x:'max-content'}}
      size={'small'}
      onRow={onRow || ((record:any, index:any) =>strip?({style:{backgroundColor:index%2===1?'rgb(240, 231, 214)':null}} as any):null)}
      sticky
      summary={summary}
      onChange={tableChange}
    />
    </ConfigProvider>
    <div style={{display:'flex',justifyContent:'space-between',marginTop:4}}>
      {effectBottomActions && effectBottomActions.length>0
      ? <div style={{display:'flex'}}>{effectBottomActions.map((item:any)=>
        item.render?item.render:!item.isfile
        ?<Button disabled={item.disabled===true?true:false} href={item.type==='link'?item.action:null} style={{marginRight:10}} type={item.type || 'primary'} danger={item.isDanger} loading={item.loading} size='small' key={item.label} onClick={item.action}>{item.label}</Button>
        :<div className={supertcss.filewrap}>
          <Button
            size='small'
            type="primary"
            onClick={()=>handleUpload(item.action)}
            disabled={fileList.length === 0}
            loading={uploading}
          >上传</Button>
          <Upload style={{display:'flex'}} {...fileprops} maxCount={1}><Button size='small' icon={<UploadOutlined />}>选择文件</Button></Upload>
        </div>
        )}</div>
      :null
      }
      {pagination 
      ? <div style={{textAlign:'right',flex:1}}>
        <ConfigProvider locale={locale}>
        <Pagination
          pageSize={pagination.pageSize}
          current={pagination.current}
          total={pagination.total}
          pageSizeOptions={[10, 20, 50, 100, 500]}
          showSizeChanger
          showQuickJumper
          showTotal={total => `共 ${total} 条`}
          onChange={(page, pageSize)=>{
            if (page !== pagination.current) pagination.current = page
            else pagination.pageSize = pageSize
            if (inPagination) inPagination(page, pageSize)
            else uniAxList(dataRow())
          }}
        />
      </ConfigProvider>
      </div>
      :null
    }
    </div>
    </>
  )
}

SuperTable.defaultProps = {rowKey: 'id'}

const getElement = (item:any,type:string,row:any) => {
  let tempel = <></>
  if (type==='input') tempel = item.usedefault 
    ? <Input value={row[item.key]} allowClear={item.clearable===false?false:true} disabled={item.disabled?item.disabled({row,value:row[item.key]}):false} size='small' readOnly={item.readonly} placeholder={item.placeholder || '请输入'} onChange={(e:any)=>{
      row[item.key] = e.target.value
      if (item.change) item.change(e.target.value,row)
    }}></Input>
    : <Input allowClear={item.clearable===false?false:true} disabled={item.disabled?item.disabled({row,value:row[item.key]}):false} size='small' readOnly={item.readonly} placeholder={item.placeholder || '请输入'} onChange={(e:any)=>{
        row[item.key] = e.target.value
        if (item.change) item.change(e.target.value,row)
      }}></Input>
  else if (type==='select') tempel = (<div style={{width:'100%',display:'flex'}}><Select
    value={row[item.key]}
    disabled={item.disabled?item.disabled({row,value:row[item.key]}):false}
    mode={item.selectProps?item.selectProps.mode:null}
    style={{ flex:1 }}
    size="small"
    maxTagCount='responsive'
    optionLabelProp={(item.selectProps && item.selectProps.mode==='multiple')?'label':''}
    allowClear={item.clearable===false?false:true}
    placeholder={item.placeholder || '请选择'}
    onChange={(value,operation)=>{
      row[item.key] = value
      if (item.selectProps?.requireName) row[item.selectProps?.requireName] = operation
      if (item.change) item.change(value,row)
    }}
    showSearch
    filterOption={(inputkey:any,option:any)=>(option?.children ?? '').includes(inputkey)}
  > 
    {item.dataList(row).map((listitem:any) => <Select.Option label={listitem[item.selectProps?.propName || 'name'].slice(0,1)} key={listitem[item.selectProps?.propId ||'id']} value={listitem[item.selectProps?.propId || 'id']}>
      {item.selectProps&&item.selectProps.childrenKeys
        ?item.selectProps.childrenKeys.map((keyitem:string)=>listitem[keyitem]).join(' ')
        :listitem[item.selectProps?.propName || 'name']
      }
    </Select.Option>)}
  </Select>{item.suffix?item.suffix({row,value:row[item.key]}):null}</div>)
  else if (type==='number') tempel = item.usedefault
    ?(<InputNumber value={row[item.key]} disabled={item.disabled?item.disabled({row,value:row[item.key]}):false} style={{width:'100%'}} size='small' precision={item.numProps?.precision || 0} min={item.numProps?.min} placeholder={item.placeholder || '请输入'} onChange={(value)=>{
      row[item.key] = value
      if (item.change) item.change(value,row)
      }}/>)
    :(<InputNumber disabled={item.disabled?item.disabled({row,value:row[item.key]}):false} style={{width:'100%'}} size='small' precision={item.numProps?.precision || 0} min={item.numProps?.min} placeholder={item.placeholder || '请输入'} onChange={(value)=>{
      row[item.key] = value
      if (item.change) item.change(value,row)
      }}/>)
  else if (type==='switch') tempel = (<Switch checkedChildren={(item.switchProps?.tags)?item.switchProps.tags[1] : "是"} unCheckedChildren={(item.switchProps?.tags)?item.switchProps.tags[0] : "否"} defaultChecked onChange={(checked)=>{
    let checkarr = item.switchProps?.values || [false,true]
    if (checked) row[item.key] = checkarr[1]
    else row[item.key] = checkarr[0]
  }} />)
  return tempel
}
