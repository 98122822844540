import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, ConfigProvider, DatePicker, Form, FormInstance, Input, InputNumber, message, Modal, Pagination, Popconfirm, Radio, Select, Table, TablePaginationConfig, Tabs, Tooltip, TreeSelect, Typography, Upload, UploadFile, UploadProps } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import FormModal from '../../../components/FormModal'
import { getStaffList, getStaffCharList, getRoleList, createUser, getCompanyList, updateStaff, deleteStaff, searchDepList, getDepTree, getPositionList, getStaffDetailList, fileUpload, axStaffmax, axUserimport, axFinancefdList, axJpInoutList, axInoutGenvou, axFdGenvou, axJpInoutStat } from '../../../utils/api'
import { NameValue, PsItem, StaffItem, TableParams } from '../../../utils/beans'
import { uniSelectRows, uniModifyRow, uniDeleteRow, uniAxList, uniModifyAxList, uniModifyDpList, uniAxSearchList, UniDeleteAll, uniBatchQuit, uniQuitModal, staffAddRowSubmit, staffChgRowSubmit, uniFilterItem, uniDatePikcerChange, uniGetKeybykeyFromlist, uniGetMaxFromListkey, uniAddRowSubmit, uniPhoneValidator, uniIdNumValidator, uniExportExcel, uniTableChange, uniCurrentTime, uniChgRowSubmit, uniDeepArray, uniHandleUpload, uniValueToName, uniInitFormdata, uniOpAlarm, uniFormProcess, uniToProof } from '../../../utils/uniFucs'
import TextArea from 'antd/lib/input/TextArea'
import { getLocalItem, setLocalItem } from '../../../utils/localstorage'
import { csStaffActives, initStaffItem, postStatus } from '../../../utils/constant'
import SuperTable from '../../../components/SuperTable'
import UniOperationColumn from '../../../components/UniOperationColumn'
import formModal from '../../../css/formModal.module.less'
import TopSearch from '../../../components/TopSearch';
import { AxDataform, ColumnAction, ColumnItem, FormItemObj } from '../../../utils/interfaces';
import FormDialog from '../../../components/FormDialog';

export default function HqTaxtotal() {
/* 不需要刷新页面的参数constructor */
const pathname = "/indexpage/staffmanagement"
const navigate = useNavigate()
const searchRef = createRef<FormInstance>()
const searchRef2 = createRef<FormInstance>()
const modalRef = createRef<FormInstance>()
const initSearchParams = {page:1,filter_NEL_type:1}
const initSearchParams2 = {page:1}
const initTypes = [{id:2,name:'收入'},{id:3,name:'支出'}]
const initTypes2 = [{id:1,name:'出库'},{id:2,name:'损耗'},{id:3,name:'盘盈'},{id:4,name:'盘亏'}]
/* hooks */
let location = useLocation()
const formModalRef = createRef<FormInstance>()
const [rowList, setRowList] = useState([])
const [rowList2, setRowList2] = useState([])
const [tableLoading, setTableLoading] = useState(false)
const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
const [pagination2, setPagination2] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
const [companyList, setCompanyList] = useState([])
const [currentRow, setCurrentRow] = useState({} as any)
const [selectedRowKeys, setSelectedRowKeys] = useState([])
const [selectedRows, setSelectedRows] = useState([])
let [searchParams, setSearchParams] = useState({...initSearchParams} as any)
let [searchParams2, setSearchParams2] = useState({...initSearchParams2} as any)
let [tableParams, setTableParams] = useState({...initSearchParams} as any)
const [tempSearchParams, setTempSearchParams] = useState({page:1} as any)
const [backList, setBackList] = useState([])
const [customAddKeys, setCustomAddKeys] = useState({jobNum:null} as any)
const [loadFlag, setLoadFlag] = useState(false)
const [modalFlag, setModalFlag] = useState('')
const [showModal, setShowModal] = useState(false)
const [modalLoading, setModalLoading] = useState(false)
const [statInfo,setStatInfo] = useState({totalPrice:'--',returnPrice:'--',taxPrice:'--'} as any)
const [exportLoading, setExportLoading] = useState(false)

/* elements */
//搜索行
const searchList:FormItemObj[] = [
{label: '公司', prop:'filter_EQL_group_item_id',type:'select',dataList:companyList},
{label: '类型', prop:'filter_EQL_type',type:'select',dataList:initTypes},
{label: '月份', prop:'filter_BETWEENS_ym',type:'dateRange',daterangeProps:{returnType:'string',type:'month',shortCuts:[]}},
{label: '用户', prop:'filter_LIKES_customer_name',type:'input'},
{label: '供应商', prop:'filter_LIKES_supplier_name',type:'input'}
// {label: '在职状态', prop:'status',type:'select',dataList:csStaffActives},
// {label: '角色', prop:'roleId',type:'select',dataList:roleList,selectProps:{propName:'roleName'}},
// {label: '职位', prop:'positionId',type:'select',dataList:positionList}
]
const searchList2:FormItemObj[] = [
{label: '公司', prop:'filter_EQL_group_item_id',type:'select',dataList:companyList},
{label: '类型', prop:'filter_EQL_type',type:'select',dataList:initTypes2},
{label: '月份', prop:'filter_BETWEENS_ym',type:'dateRange',daterangeProps:{returnType:'string',type:'month',shortCuts:[]}},
]
//列首行
const columns:ColumnItem[] = [
{title: '公司',key: 'groupItemName'},
{title: '收支类型',key: 'type',type:'tag',tagProps:{values:[3,2]},custval:({row,value}:any)=>uniValueToName({list:initTypes,value})},
{title: '月份',key: 'ym'},
{title: '供应商',key: 'supplierName'},
{title: '用户',key: 'customerName'},
{title: '金额',key: 'totalPrice',type:'price'},
{title: '退款',key: 'returnPrice',type:'price'},
{title: '折扣',key: 'mdiscount'},
{title: '税率(%)',key: 'taxRate'},
{title: '税额',key: 'taxPrice',type:'price'},
{title: '凭证号',key:'proofNo',type:'underline',unlineProps:{action:({row,value}:any)=>uniToProof(row,row.accountProof, navigate)}},
{title: '备注',key: 'remark'}
]
//列首行
const columns2:ColumnItem[] = [
{title: '公司',key: 'groupItemName'},
{title: '收支类型',key: 'type',type:'tag',tagProps:{values:[3,2]},custval:({row,value}:any)=>uniValueToName({list:initTypes2,value})},
{title: '月份',key: 'ym'},
{title: '0税金额',key: 'taxPrice',type:'price'},
{title: '9税金额',key: 'nineTaxPrice',type:'price'},
{title: '13税金额',key: 'thirteenTaxPrice',type:'price'},
{title: '凭证号',key:'proofNo',type:'underline',unlineProps:{action:({row,value}:any)=>uniToProof(row,row.accountProof, navigate)}},
{title: '备注',key: 'remark'}
]
//底部操作
const bottomActions:ColumnAction[] = [
{label: '导出', type:'primary',loading:exportLoading,action: () => {
  uniExportExcel({columns,setExportLoading, axApi:axJpInoutList, tableParams:{...tableParams,page:null},title:'集配税率统计_收入支出',fileName: '集配税率统计_收入支出'+uniCurrentTime({})})
}},
{label: '收入支出生成凭证', type:'primary',action: () => {
  setModalFlag('tab1')
  setShowModal(true)
}},
{label: 'bottomslot', render:<>
  <span style={{color: '#1890ff'}}>金额: </span>&nbsp;<span>{statInfo.totalPrice}</span>&nbsp;&nbsp;
  <span style={{color: '#1890ff'}}>退款: </span>&nbsp;<span>{statInfo.returnPrice}</span>&nbsp;&nbsp;
  <span style={{color: '#1890ff'}}>税额: </span>&nbsp;<span>{statInfo.taxPrice}</span>&nbsp;&nbsp;
</>}
]
const bottomActions2:ColumnAction[] = [
{label: '出库损耗盘点生成凭证', type:'primary',action: () => {
  setModalFlag('tab2')
  setShowModal(true)
}}
]
//生成凭证
const genItems:FormItemObj[] = [
{label: '类型',prop:'type',type:'select',dataList:()=>modalFlag==='tab1'?initTypes:initTypes2.slice(0,2)},
{label: '月份',prop:'ym',type:'datePicker',datepickerProps:{returnType:'string',type:'month'}},
{label: '公司',prop:'companyId',type:'select',dataList:companyList}
]

/* funcs */
//路由刷新请求
const routerFlash = () => {
  uniAxList(dataRow())
  uniAxList(dataCompanyList)
}
const searchConfirm = async (formValus:any) => {
  pagination.current = 1
  setPagination({...pagination,current:1});
  searchParams = await uniFormProcess({...initSearchParams,...formValus},searchList)
  setSearchParams(searchParams)
  setTableParams({...searchParams})
  uniAxList(dataRow())
}
const searchConfirm2 = async (formValus:any) => {
  pagination2.current = 1
  setPagination2({...pagination2,current:1});
  searchParams2 = await uniFormProcess({...initSearchParams2,...formValus},searchList2)
  setSearchParams2(searchParams2)
  uniAxList(dataRow2())
}
//tab页改变
const tabchange = (key:string) => {
  if (key==='2' && rowList2.length <= 0) uniAxList(dataRow2())
}
//modal取消
const modalCancel = () => {
  setShowModal(()=>false)
}
//modal点击确定
const modalConfirm = async (formValues:any) => {
  let tempparms = await uniFormProcess(formValues,genItems)
  if (modalFlag === 'tab1') uniAxList(dataGenvou(tempparms))
  else if (modalFlag==='tab2') uniAxList(dataFdGenvou(tempparms))
}

/* 接口参数 */
const dataRow = ():AxDataform => {
  let istotle
  return {
    params: {...searchParams, current: pagination.current, size:pagination.pageSize},
    axApi: axJpInoutList,
    setPagination,
    setList: setRowList,
    setTableLoading,
    awaitFunc: (predata:any)=>{
    if (predata.length>0 && searchParams['filter_EQL_group_item_id'] && searchParams['filter_EQL_type'] && searchParams['filter_BETWEENS_ym']) {
      uniAxList(dataRowStat())
    } else setStatInfo({totalPrice: '--',returnPrice:'--',taxPrice:'--'})
    }
  }
}
const dataRowStat = ():AxDataform => ({
  params: {
    companyId:searchParams['filter_EQL_group_item_id'],type:searchParams['filter_EQL_type'],ym:searchParams['filter_BETWEENS_ym'].split(',')[0],
    'filter_LIKES_customer_name':searchParams['filter_LIKES_customer_name'],'filter_LIKES_supplier_name':searchParams['filter_LIKES_supplier_name']
  },
  axApi: axJpInoutStat,
  awaitFunc: (predata:any)=>{
    let temparr = predata
    if (searchParams['filter_LIKES_customer_name'] && searchParams['filter_LIKES_supplier_name']) {
      temparr = predata.filter((item:any)=>item.customerName.includes(searchParams['filter_LIKES_customer_name']) && item.supplierName.includes(searchParams['filter_LIKES_supplier_name']))
    } else if (searchParams['filter_LIKES_customer_name']) {
      temparr = predata.filter((item:any)=>item.customerName.includes(searchParams['filter_LIKES_customer_name']))
    } else if (searchParams['filter_LIKES_supplier_name']) {
      temparr = predata.filter((item:any)=>item.supplierName.includes(searchParams['filter_LIKES_supplier_name']))
    }
    if (temparr.length > 0) {
      let totalPrice = 0
      let returnPrice = 0
      let taxPrice = 0
      temparr.forEach((item:any)=>{
        totalPrice+=item.totalPrice
        returnPrice+=item.returnPrice
        taxPrice+=item.taxPrice
      })
      setStatInfo({totalPrice: Number((totalPrice/100).toFixed(2)),returnPrice:Number((returnPrice/100).toFixed(2)),taxPrice:Number((taxPrice/100).toFixed(2))})
    } else setStatInfo({totalPrice: '--',returnPrice:'--',taxPrice:'--'})
  }
})
const dataRow2 = ():AxDataform => ({
  params: {...searchParams2, current: pagination2.current, size:pagination2.pageSize},
  axApi: axFinancefdList,
  setPagination:setPagination2,
  setList: setRowList2,
  setTableLoading
})
//收入支出生成凭证
const dataGenvou = (params:any):AxDataform => ({
  params,
  axApi:axInoutGenvou,
  setModalLoading,
  setShowModal,
  successTip: true,
  awaitFunc: () => uniAxList(dataRow())
})
//出库损耗盘点生成凭证
const dataFdGenvou = (params:any):AxDataform => ({
  params,
  axApi:axFdGenvou,
  setModalLoading,
  setShowModal,
  successTip: true,
  awaitFunc: () => uniAxList(dataRow2())
})
//公司列表
const dataCompanyList:AxDataform = {
  axApi: getCompanyList,
  setList: setCompanyList
}

//tabitems
const tabItems:any = [
{
  key: '1',
  label: '收入支出',
  children: <div style={{height:'100%', display:'flex',flexDirection:'column'}} >
    <TopSearch ref={searchRef} searchList={searchList} searchConfirm={searchConfirm} />
    <SuperTable
      columns={columns}
      rowList={rowList}
      tableLoading={tableLoading}
      pagination={pagination}
      bottomActions={bottomActions}
      dataRow={dataRow}
      rowSelection={{setSelectedRowKeys, setSelectedRows, selectedRowKeys}}
    />
  </div>
},
{
  key: '2',
  label: '出库损耗盘点',
  children: <div style={{height:'100%', display:'flex',flexDirection:'column'}} >
    <TopSearch ref={searchRef2} searchList={searchList2} searchConfirm={searchConfirm2} />
    <SuperTable
      columns={columns2}
      rowList={rowList2}
      tableLoading={tableLoading}
      pagination={pagination2}
      bottomActions={bottomActions2}
      dataRow={dataRow2}
    />
  </div>
},
]

/* useEffect */
//监听路由变化
React.useEffect(() => {
  if (loadFlag && location.pathname===pathname) routerFlash()
}, [location])

/* mounted */
React.useEffect(() => {
  uniAxList(dataRow())
  uniAxList(dataCompanyList)
  setLoadFlag(()=>true)
}, [])

return <>
<Tabs defaultActiveKey="1" items={tabItems} onChange={tabchange} />
<FormDialog
  ref={modalRef}
  title={`${modalFlag==='tab1'?'收入支出':'出库损耗盘点'}生成凭证`}
  show={showModal}
  formItems={genItems}
  modalLoading={modalLoading}
  formInitials={{}}
  cancel={modalCancel}
  confirm={modalConfirm}
/>
</>
}