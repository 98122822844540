import React, {lazy,Suspense} from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { getUserToken } from '../utils/localstorage'
import store, { setUser } from "../utils/store";

import AccountBook from '../pages/intelfinance/AccountBook';
import AccountBalance from '../pages/intelfinance/AccountBalance';
import AffixDetail from '../pages/intelfinance/AffixDetail';
import AffixManage from '../pages/intelfinance/AffixManage';
import BankDaily from '../pages/intelfinance/BankDaily';
import BillStatistics from '../pages/inoutbill/staffbill/BillStatistics';
import BillSummary from '../pages/inoutbill/staffbill/BillSummary';
import BranchSetting from '../pages/basicset/stafforg/BranchSetting';
import CashDaily from '../pages/intelfinance/CashDaily';
import ComPayment from '../pages/inoutbill/ComPayment';
import Dailyflow from '../pages/dayreport/Dailyflow';
import DetailBook from '../pages/intelfinance/DetailBook';
import ExpressAccountingSubject from '../pages/basicset/firmorg/ExpressAccountingSubject';
import FeeTypeSetting from '../pages/basicset/firmorg/FeeTypeSetting';
import FinanceAssets from '../pages/property/FinanceAssets';
import FinanceInout from '../pages/property/FinanceInout';
import FirmAccountCheck from '../pages/dayreport/FirmAccountCheck';
import FirmAccountSetting from '../pages/basicset/firmorg/FirmAccountSetting';
import GpBalanceSheet from '../pages/financeport/GpBalanceSheet';
import GpCashflow from '../pages/financeport/GpCashflow';
import GpMonthProfit from '../pages/financeport/GpMonthProfit';
import GpRatesetting from '../pages/basicset/GpRatesetting';
import Guide from '../pages/Guide';
import HQData from '../pages/inoutbill/bizdata/HQData';
import HqOrigin from '../pages/inoutbill/bizdata/HqOrigin';
import HQStatistics from '../pages/inoutbill/bizdata/HQStatistics';
import IndexPage from '../pages/IndexPage';
import InitialAccouts from '../pages/basicset/firmorg/InitialAccouts';
import InvalidDetail from '../pages/inoutbill/staffbill/InvalidDetail';
import Login from '../pages/Login';
import Main from '../pages/Main';
import MaterialClaim from '../pages/material/MaterialClaim';
import MaterialClaimlog from '../pages/material/MaterialClaimlog';
import MaterialCost from '../pages/material/MaterialCost';
import MaterialDailyCheck from '../pages/material/MaterialDailyCheck';
import MaterialStock from '../pages/material/MaterialStock';
import MaterialMonthStat from '../pages/material/MaterialMonthStat';
import MaterialPrice from '../pages/material/MaterialPrice';
import MaterialPurch from '../pages/material/MaterialPurch';
import MenuSetting from '../pages/system/MenuSetting';
import OtherFee from '../pages/inoutbill/staffbill/OtherFee';
import PledgeLoan from '../pages/inoutbill/staffbill/PledgeLoan';
import ProductMonthProfit from '../pages/financeport/ProductMonthProfit';
import ProofCollection from '../pages/intelfinance/ProofCollection';
import Recharge from '../pages/inoutbill/staffbill/Recharge';
import RecordVoucher from '../pages/intelfinance/RecordVoucher';
import Reimbursement from '../pages/inoutbill/staffbill/Reimbursement';
import SiteSetting from '../pages/system/SiteSetting';
import StaffManagement from '../pages/basicset/stafforg/StaffManagement';
import StockIn from '../pages/basicset/StockIn';
import Mcategory from '../pages/basicset/Mcategory';
import TitleSetting from '../pages/basicset/stafforg/TitleSetting';
import UserBalance from '../pages/inoutbill/staffbill/UserBalance';
import UserbalanceDetail from '../pages/inoutbill/staffbill/UserbalanceDetail';
import UserPrivilege from '../pages/system/UserPrivilege';
import HqTaxtotal from '../pages/inoutbill/bizdata/HqTaxtotal';

// const AccountBalance = lazy(()=>import('../pages/intelfinance/AccountBalance'))
// const AccountBook = lazy(()=>import('../pages/intelfinance/AccountBook'))
// const AffixDetail = lazy(()=>import('../pages/intelfinance/AffixDetail'))
// const BankDaily = lazy(()=>import('../pages/intelfinance/BankDaily'))
// const BillStatistics = lazy(()=>import('../pages/inoutbill/staffbill/BillStatistics'))
// const BillSummary = lazy(()=>import('../pages/inoutbill/staffbill/BillSummary'))
// const BranchSetting = lazy(()=>import('../pages/basicset/stafforg/BranchSetting'))
// const CashDaily = lazy(()=>import('../pages/intelfinance/CashDaily'))
// const ComPayment = lazy(()=>import('../pages/inoutbill/ComPayment'))
// const Dailyflow = lazy(()=>import('../pages/dayreport/Dailyflow'))
// const DetailBook = lazy(()=>import('../pages/intelfinance/DetailBook'))
// const ExpressAccountingSubject = lazy(()=>import('../pages/basicset/firmorg/ExpressAccountingSubject'))
// const FeeTypeSetting = lazy(()=>import('../pages/basicset/firmorg/FeeTypeSetting'))
// const FinanceAssets = lazy(()=>import('../pages/property/FinanceAssets'))
// const FinanceInout = lazy(()=>import('../pages/property/FinanceInout'))
// const FirmAccountCheck = lazy(()=>import('../pages/dayreport/FirmAccountCheck'))
// const FirmAccountSetting = lazy(()=>import('../pages/basicset/firmorg/FirmAccountSetting'))
// const GpBalanceSheet = lazy(()=>import('../pages/financeport/GpBalanceSheet'))
// const GpCashflow = lazy(()=>import('../pages/financeport/GpCashflow'))
// const GpMonthProfit = lazy(()=>import('../pages/financeport/GpMonthProfit'))
// const GpRatesetting = lazy(()=>import('../pages/basicset/GpRatesetting'))
// const Guide = lazy(()=>import('../pages/Guide'))
// const HQData = lazy(()=>import('../pages/inoutbill/bizdata/HQData'))
// const HqOrigin = lazy(()=>import('../pages/inoutbill/bizdata/HqOrigin'))
// const HQStatistics = lazy(()=>import('../pages/inoutbill/bizdata/HQStatistics'))
// const IndexPage = lazy(()=>import('../pages/IndexPage'))
// const InitialAccouts = lazy(()=>import('../pages/basicset/firmorg/InitialAccouts'))
// const InvalidDetail = lazy(()=>import('../pages/inoutbill/staffbill/InvalidDetail'))
// const Login = lazy(()=>import('../pages/Login'))
// const Main = lazy(()=>import('../pages/Main'))
// const MaterialClaim = lazy(()=>import('../pages/material/MaterialClaim'))
// const MaterialClaimlog = lazy(()=>import('../pages/material/MaterialClaimlog'))
// const MaterialCost = lazy(()=>import('../pages/material/MaterialCost'))
// const MaterialDailyCheck = lazy(()=>import('../pages/material/MaterialDailyCheck'))
// const MaterialMonthStat = lazy(()=>import('../pages/material/MaterialMonthStat'))
// const MaterialPrice = lazy(()=>import('../pages/material/MaterialPrice'))
// const MaterialPurch = lazy(()=>import('../pages/material/MaterialPurch'))
// const MenuSetting = lazy(()=>import('../pages/system/MenuSetting'))
// const OtherFee = lazy(()=>import('../pages/inoutbill/staffbill/OtherFee'))
// const PledgeLoan = lazy(()=>import('../pages/inoutbill/staffbill/PledgeLoan'))
// const ProductMonthProfit = lazy(()=>import('../pages/financeport/ProductMonthProfit'))
// const ProofCollection = lazy(()=>import('../pages/intelfinance/ProofCollection'))
// const Recharge = lazy(()=>import('../pages/inoutbill/staffbill/Recharge'))
// const RecordVoucher = lazy(()=>import('../pages/intelfinance/RecordVoucher'))
// const Reimbursement = lazy(()=>import('../pages/inoutbill/staffbill/Reimbursement'))
// const SiteSetting = lazy(()=>import('../pages/system/SiteSetting'))
// const StaffManagement = lazy(()=>import('../pages/basicset/stafforg/StaffManagement'))
// const StockIn = lazy(()=>import('../pages/basicset/StockIn'))
// const TitleSetting = lazy(()=>import('../pages/basicset/stafforg/TitleSetting'))
// const UserBalance = lazy(()=>import('../pages/inoutbill/staffbill/UserBalance'))
// const UserbalanceDetail = lazy(()=>import('../pages/inoutbill/staffbill/UserbalanceDetail'))
// const UserPrivilege = lazy(()=>import('../pages/system/UserPrivilege'))

export default function NavRoutes() {
  return (
    <Suspense fallback={<h1></h1>}>
      <Routes>
        <Route path='/' element={<Navigate to='/login'/>}></Route>
        <Route path='/login' element={<Login />}></Route>
        <Route path='/indexpage' element={<IndexPage />}>
          <Route path='' element={<Main />}></Route>
          <Route path='main' element={<Main />}></Route>
          <Route path='guide' element={<Guide />}></Route>
          <Route path='gpratesetting' element={<GpRatesetting />}></Route>
          <Route path='staffmanagement' element={<StaffManagement />}></Route>
          <Route path='branchsetting' element={<BranchSetting />}></Route>
          <Route path='titlesetting' element={<TitleSetting />}></Route>
          <Route path='firmaccountsetting' element={<FirmAccountSetting />}></Route>
          <Route path='expressaccountingsubject' element={<ExpressAccountingSubject />}></Route>
          <Route path='feetypesetting' element={<FeeTypeSetting />}></Route>
          <Route path='initialaccounts' element={<InitialAccouts />}></Route>
          <Route path='stockin' element={<StockIn />}></Route>
          <Route path='mcategory' element={<Mcategory />}></Route>
          <Route path='financeassets' element={<FinanceAssets />}></Route>
          <Route path='compayment' element={<ComPayment />}></Route>
          <Route path='billsummary' element={<BillSummary />}></Route>
          <Route path='billstatistics' element={<BillStatistics />}></Route>
          <Route path='reimbursement' element={<Reimbursement />}></Route>
          <Route path='recharge' element={<Recharge />}></Route>
          <Route path='userbalance' element={<UserBalance />}></Route>
          <Route path='userbalancedetail' element={<UserbalanceDetail />}></Route>
          <Route path='otherfee' element={<OtherFee />}></Route>
          <Route path='pledgeloan' element={<PledgeLoan />}></Route>
          <Route path='invaliddetail' element={<InvalidDetail />}></Route>
          <Route path='hqdata' element={<HQData />}></Route>
          <Route path='hqorigin' element={<HqOrigin />}></Route>
          <Route path='hqstatistics' element={<HQStatistics />}></Route>
          <Route path='hqtaxtotal' element={<HqTaxtotal />}></Route>
          <Route path='materialpurch' element={<MaterialPurch />}></Route>
          <Route path='materialclaim' element={<MaterialClaim />}></Route>
          <Route path='materialclaimlog' element={<MaterialClaimlog />}></Route>
          <Route path='materialprice' element={<MaterialPrice />}></Route>
          <Route path='materialdailycheck' element={<MaterialDailyCheck />}></Route>
          <Route path='materialstock' element={<MaterialStock />}></Route>
          <Route path='materialcost' element={<MaterialCost />}></Route>
          <Route path='materialmonthstat' element={<MaterialMonthStat />}></Route>
          <Route path='financeassets' element={<FinanceAssets />}></Route>
          <Route path='financeinout' element={<FinanceInout />}></Route>
          <Route path='recordvoucher' element={<RecordVoucher />}></Route>
          <Route path='accountbook' element={<AccountBook />}></Route>
          <Route path='accountbalance' element={<AccountBalance />}></Route>
          <Route path='proofcollection' element={<ProofCollection />}></Route>
          <Route path='bankdaily' element={<BankDaily />}></Route>
          <Route path='cashdaily' element={<CashDaily />}></Route>
          <Route path='affixdetail' element={<AffixDetail />}></Route>
          <Route path='affixmanage' element={<AffixManage />}></Route>
          <Route path='detailbook' element={<DetailBook />}></Route>
          <Route path='dailyflow' element={<Dailyflow />}></Route>
          <Route path='firmaccountcheck' element={<FirmAccountCheck />}></Route>
          <Route path='productmonthprofit' element={<ProductMonthProfit />}></Route>
          <Route path='gpmonthprofit' element={<GpMonthProfit />}></Route>
          <Route path='gpbalancesheet' element={<GpBalanceSheet />}></Route>
          <Route path='gpcashflow' element={<GpCashflow />}></Route>
          <Route path='userprivilege' element={<UserPrivilege />}></Route>
          <Route path='menusetting' element={<MenuSetting />}></Route>
          <Route path='sitesetting' element={<SiteSetting />}></Route>
        </Route>
      </Routes>
    </Suspense>
  )
}
