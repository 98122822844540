import React, { Fragment, forwardRef, useEffect, useState } from 'react'
import { Modal, Form, Button, Input, Row, Col, Drawer, TreeSelect, Select, InputNumber, DatePicker, ConfigProvider, Upload, UploadFile } from 'antd';
import locale from 'antd/es/locale/zh_CN';
import moment from 'moment';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons'
import { csDateRages } from '../utils/constant';
import { uniNumToCh } from '../utils/uniFucs';
import printcss from '../css/cptprint.module.less'

type printProp = {title:any, printList:any, columns:any,titleSlot?:boolean,sumSlot?:boolean,hasSum?:boolean,sumList?:any,sumTotalInfo?:any,hasSuffix?:boolean,sumAlltitle?:string, sumProps?:any, sumTitlespan?:number, hasIndex?:boolean,capKey?:string, amountCap?:boolean, isGroup?:boolean, hasBorder?:boolean, children?:any}
export default function CpTprint(props: printProp) {
  const {title='', printList=[], columns=[],titleSlot=false,sumSlot=false,hasSum=false,sumList,sumTotalInfo,hasSuffix=true,sumAlltitle='总计', sumProps=[], sumTitlespan=2, hasIndex=true, isGroup=false, hasBorder=true, amountCap=false,capKey='',children} = props
  console.log('printlist-------',printList)
  return (<>
  <table className={printcss.printtable}>
    <thead>
      {
        title?<>
        <tr style={{border:'none'}}>
          <th colSpan={columns.length + 1} style={{border:'none'}}>
            <div style={{width:'100%',textAlign: 'center',fontSize: '16px'}}>{ title }</div>
          </th>
        </tr>
        {
          children&&children[0]?<tr style={{border:'none'}}>
            <th colSpan={columns.length + 1} style={{border:'none'}}>{children[0]}</th>
          </tr>:null
        }
        </>:null
      }
      <tr>
        {hasIndex?<th> 序号 </th>:null}
        {columns.map((citem:any)=><th key={citem.key} style={{width:citem.pwidth}}>{ citem.title }</th>)}
      </tr>
    </thead>
    <tbody>
      {
        printList.map((pitem:any, pindex:number)=>
          <tr key={pitem.id || pitem.key}>
            {hasIndex?<td> { pindex + 1 } </td>:null}
            {
              columns.map((citem:any,cindex:number)=>
                <td key={citem.key} rowSpan={(pitem.rowspans && (cindex>=pitem.rowspans.length || pitem.rowspans[cindex] > 0)) ? pitem.rowspans[cindex] : 1} style={{width:citem.pwidth}}>{
                  citem.custval
                    ?citem.custval({row:pitem,value:pitem[citem.key],index:pindex})
                    :typeof(pitem[citem.key]) === 'number' ? Number(pitem[citem.key].toFixed(2)) : pitem[citem.key]
                }</td>
              )
            }
          </tr>
        )
      }
      {hasSum?<tr>
        <td colSpan={sumTitlespan} style={{textAlign: 'center',fontWeight: 'bold'}} > { sumAlltitle + (capKey? (' ' +  uniNumToCh(printList.reduce((total:number,item:any)=>total+(item[capKey]||0),0))):'') } </td>
        {
          columns.slice(hasIndex?(sumTitlespan - 1):sumTitlespan).map((citem:any,cindex:number)=><td key={citem.key}>
            {
              sumProps.includes(citem.key)
              ?sumList
                ?(sumList.reduce((total:number,item:any)=>total+(citem.custvalue?Number(citem.custvalue({row:item,value:item[citem.key]})):(item[citem.key]||0)),0).toFixed(2) + (sumTotalInfo&&sumTotalInfo[citem.key]?('/'+Number(sumTotalInfo[citem.key]).toFixed(2)):''))
                :(printList.reduce((total:number,item:any)=>total+(citem.custvalue?Number(citem.custvalue({row:item,value:item[citem.key]})):(item[citem.key]||0)),0).toFixed(2) + (sumTotalInfo&&sumTotalInfo[citem.key]?('/'+Number(sumTotalInfo[citem.key]).toFixed(2)):''))
              :''
            }
          </td>)
        }
      </tr>:null}
    </tbody>
    <tfoot>
      {
        (children&&children[1])?<tr style={{border:'none'}}>
          <th colSpan={columns.length + 1} style={{border:'none'}}>{children[1]}</th>
        </tr>:null
      }
    </tfoot>
  </table>
  </>
  )
}

