import React, { createRef, useState } from 'react'
import { Button, ConfigProvider, DatePicker, Form, FormInstance, Input, InputNumber, message, Modal, Pagination, Popconfirm, Radio, Select, Steps, Table, TablePaginationConfig, Tag, Tooltip, TreeSelect, Typography, Upload, UploadProps } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import { PlusCircleOutlined } from '@ant-design/icons'

import { getCompanyList, companyBillList, companyBillBusiness, createCompanyBill, getCompanyAccoutList, deleteCompanyBill, updateCompanyBill, cancelCompanyBill, getCompanyBillTotal, confirmCompanyBill, reviewCompanyBill, getReviewUserList, getStaffList, createCompanyBillTrans, confirmCompanyBillTrans, axSubjectList, createPlantBalance, getImportTemplate, getCompanylimitStatus, getReviewTempList, getCostTypeTreeV2, companyAccountList, axAffixExSubjectList, axAffixSubjectDetail, axSubjectBindList, axSubjectBindAffixdetails, dataCompbilltempList, dataNewCompbilltemp, dataDelCompbilltemp } from '../../utils/api'
import { uniAxList, uniFormProcess, uniInitFormdata, uniOpAlarm, uniToProof, uniValueToName } from '../../utils/uniFucs'
import { cancelStatus, companyBillType, proofStatusList, leadBorrow, subjectTypeList, businessTypes, borrowOrLend } from '../../utils/constant'
import FormModal from '../../components/FormModal'
import '../../css/table.less'
import formModal from '../../css/formModal.module.less'
import { ModifyParams, NameValue } from '../../utils/beans'
import TextArea from 'antd/lib/input/TextArea';
import UniOperationColumn from '../../components/UniOperationColumn';
import SummeryRow from '../../components/SummeryRow';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserLocal, getUserToken, localGetUserId } from '../../utils/localstorage';
import tablecss from '../../css/custable.module.less'
import { AxDataform, ColumnAction, ColumnItem, FormItemObj } from '../../utils/interfaces';
import TopSearch from '../../components/TopSearch';
import SuperTable from '../../components/SuperTable';
import FormDialog from '../../components/FormDialog';

export default function ComPayment() {
const pathname = "/indexpage/compayment"
const initVisible:any = {businessId:true,costTypeId:true,subjectId:true,servicePrice:false, accountId:true, collectionAccountId:false,payAccountId:false,serviceAccountId:false,serviceType:false,outCompanyId:false,inCompanyId:false,companyId:true,isShare:false}
const tokenString:string = getUserToken()
const searchRef = createRef<FormInstance>()
const modalRef = createRef<FormInstance>()
const submodalRef = createRef<FormInstance>()
const initSearchParams = {page:1,orderBy:"c.create_date", orderDir: "desc",'filter_EQB_c.is_cancel':false}
const initForminitial = {billType:1}
const initIsaccount = [{id:false,name:'非账号'},{id:true,name:'账号'}]
const initNewrowObj = {accountList:[],costList:[]}
let idcount = 2
let assistidCount = 1

/* hooks */
let location = useLocation()
const navigate = useNavigate()
const {state} = useLocation()
const [importModal, setImportModal] = useState(false)
const [companyList, setCompanyList] = useState([])
const [rowList, setRowList] = useState([] as any)
const [selectedRowKeys, setSelectedRowKeys] = useState([])
const [selectedRows, setSelectedRows] = useState([] as any)
const [currentRow, setCurrentRow] = useState({} as any)
let [searchParams, setSearchParams] = useState({...initSearchParams} as any)
let [tableParams, setTableParams] = useState({...initSearchParams} as any)
const [tableLoading, setTableLoading] = useState(false)
const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
const [staticTableData, setStaticTableData] = useState([] as any)
const [modalFeeTypeList, setModalFeeTypeList] = useState([])
const [selectFeeTypeList, setSelectFeeTypeList] = useState([])
const [stepData, setStepData] = useState({current:0, reviewers:[]})
const [loadFlag, setLoadFlag] = useState(false)
const [modalLoading, setModalLoading] = useState(false)
const [submodalLoading, setSubmodalLoading] = useState(false)
const [modalAccountList, setModalAccountList] = useState([])
const [selectAccountList, setSelectAccountList] = useState([])
const [errorMessage, setErrorMessage] = useState({value:''})
const [staticLoading,setStaticLoading] = useState(false)
const [modalFlag, setModalFlag] = useState('')
const [submodalFlag, setSubmodalFlag] = useState('')
const [showModal, setShowModal] = useState(false)
let [caryDate,setCaryDate] = useState('')
let [formInitials, setFormInitials] = useState({...initForminitial} as any)
let [modalBzList, setModalBzList] = useState([])
let [selectBzList, setSelectBzList] = useState([])
let [formData, setFormdata] = useState({...initForminitial})
let [accountCostList, setAccountCostList] = useState([])
let [subjectList,setSubjectList] = useState([])
let [newrowList, setNewrowList] = useState([{...initNewrowObj,rowid:1},{...initNewrowObj,rowid:2}] as any)
let [showSubmodal,setShowSubmodal] = useState(false)
let [assistRowlist,setAssistRowlist] = useState([{}])
let [assistList, setAssistList] = useState([])
let [assistdetailList,setAssistdetailList] = useState([])
let [currentNewrow,setCurrentNewrow] = useState({rowid:1} as any)
let [detailRowlist,setDetailRowlist] = useState([] as any)
let [statRowlist,setStatRowlist] = useState([] as any)
let [supplierList,setSupplierList] = useState([])
let [itemShow,setItemShow] = useState({supplierId:false})
let [modelEndym,setModelEndym] = useState(moment().format('YYYY-MM'))
let [modelcompanyItem, setModelCompanyItem] = useState({} as any)
const [searchYmRange,setSearchYmRange] = useState([] as any)
let [newbindItem,setNewbindItem] = useState([] as FormItemObj[])
let [affixDetailInfo,setAffixDetailInfo] = useState({} as any)
let [curTmplate,setCurTmplate] = useState({} as any)
let [subselectedRowKeys,setSubselectedRowKeys] = useState([] as any)
let [tmpLoading,setTmpLoading] = useState(false)
const [templateList, setTemplateList] = useState([] as any)
let [subtableLoading,setSubtableLoading] = useState(false)
let [subselectedRows,setSubselectedRows] = useState([] as any)
let [statInfo,setStatInfo] = useState({price:0})

/* funcs */
const searchConfirm = async (formValus:any) => {
  pagination.current = 1
  setPagination({...pagination,current:1});
  setSelectedRows([])
  setSelectedRowKeys([])
  searchParams = await uniFormProcess({...initSearchParams,...formValus},searchList)
  tableParams = {...searchParams}
  setSearchParams(searchParams)
  setTableParams(tableParams)
  uniAxList(dataRow())
}
//modal点击确定
const modalConfirm = async (formValues:any) => {
  let tempparms = await uniFormProcess(formValues,formItems)
  if (modalFlag === 'confirm') uniAxList(dataConfirmRow(currentRow.billType,[{...tempparms,transTime:tempparms.collectionPayTime,id:currentRow.id}]))
  else if (modalFlag === 'new') {
    // if (formValues.billType === 1) return message.error("出款账户和入款账户不能相同")
    // if (moment(formValues.actionTime) > moment(formValues.collectionPayTime)) return message.error("收付款时间不能早于账单时间")
    // uniAxList(dataNewrow(tempparms))
    let borrowRows = []
    let lendRows = []
    for (let i=0;i<newrowList.length;i++) {
      if (newrowList[i].isAccount===null || !newrowList[i].borrowLend || !newrowList[i].accountCostId || !newrowList[i].subjectId || !newrowList[i].price) {
        return message.error('类型、借贷方向、账户/费用、科目、金额，不能为空')
      } else if (!newrowList[i].noaffix && !newrowList[i].subjectAccountingDetailId) return message.error('有辅助核算的科目, 辅助详情不能为空')
      if (newrowList[i].borrowLend === 1) borrowRows.push(newrowList[i])
      else if (newrowList[i].borrowLend === 2) lendRows.push(newrowList[i])
    }
    if (borrowRows.length<=0) return message.error('没有借方')
    if (lendRows.length<=0) return message.error('没有贷方')
    if (formValues.billType===1) {
      // if (!borrowRows.find((item:any)=>item.isAccount)) return message.error('公司收到款,借方要存在账户类型')
      if (lendRows.find((item:any)=>item.isAccount)) return message.error('公司收到款,贷方不能有账户类型')
    } else if (formValues.billType===2) {
      if (borrowRows.find((item:any)=>item.isAccount)) return message.error('公司支出款,借方不能有账户类型')
      // if (!lendRows.find((item:any)=>item.isAccount)) return message.error('公司支出款,贷方要有账户类型')
    } else if (formValues.billType===3) {
      if (!borrowRows.find((item:any)=>item.isAccount)) return message.error('内部转账,借方要有账户类型')
      if (!lendRows.find((item:any)=>item.isAccount)) return message.error('内部转账,贷方要有账户类型')
    }
    let borrowmount = borrowRows.reduce((total:number, dataItem:any) => total + Number(dataItem.price.toFixed(2)), 0)
    let lendmount = lendRows.reduce((total:number, dataItem:any) => total + Number(dataItem.price.toFixed(2)), 0)
    if (borrowmount.toFixed(2) !== lendmount.toFixed(2)) return message.error('借贷金额不相等')
    // console.log('temparams----------------------',tempparms)
    let templist = newrowList.map((item:any)=>({...item,price:Number((item.price*100).toFixed(0)),accountList:null,costList:null,subjectItem:null,subjectbindList:null,accountId:item.isAccount?item.accountCostId:null,costTypeId:!item.isAccount?item.accountCostId:null}))
    // console.log('templist----------',templist)
    uniAxList(dataNewrow(tempparms,templist))
  } else if (modalFlag === 'update') uniAxList(dataUpdaterow(tempparms))
}
//submodal点击确定
const submodalConfirm = async (formValues:any) => {
  if (submodalFlag==='newtmp') {
    let formvalues = modalRef.current?.getFieldsValue()
    // console.log('formvalues--------',formvalues)
    // console.log('newrolist-------',newrowList)
    let outparams = {code:formValues.code,name:formValues.name,remark:'测试',companyId:formvalues.companyId}
    let companyBill = {
      companyId:formvalues.companyId,
      billType:formvalues.billType, //收付类型
      businessId:formvalues.businessId, //所属业务
      billTime: moment(formvalues.billTime).format('YYYY-MM-DD'), //账单日期
      supplierId: formvalues.supplierId  //供应商id
    }
    let detailList = newrowList.map((item:any)=>({
      isAccount: item.isAccount, // 类型, hasAssist
      borrowLend:item.borrowLend,
      accountId:item.isAccount?item.accountCostId:null, //账户/费用
      costTypeId:item.isAccount?null:item.accountCostId,
      //fullSubname 拼凑到remark
      accountingDetailNoName: item.fullSubname,    //科目
      subjectAccountingDetailId:item.subjectAccountingDetailId, //辅助详情
      price:item.price, //金额
      summary: item.fullSubname +'!!!'+item.summary, //凭证摘要
      //noaffix 读取模板时获取
      //rowid 读取模板时的顺序+1
      subjectId:item.subjectId,
    }))
    uniAxList(dataNewtemp({...outparams,companyBill:{...companyBill,detailList}}))
  } else if (submodalFlag==='choosetmp') {
    let finditem = templateList.find((item:any)=>item.id===subselectedRowKeys[0])
    modalRef.current?.setFieldsValue({billType:finditem.companyBill.billType,businessId:finditem.companyBill.businessId,billTime: moment(finditem.companyBill.billTime),supplierId:finditem.companyBill.supplierId})
    setSubmodalLoading(true)
    if (subselectedRowKeys.length <= 0) return message.error('请选择模板')
    if (finditem.companyBill.billType) uniAxList(dataBusinessList(finditem.companyBill.billType,'modal'))
    let costList = []
    if (finditem.companyBill.businessId) {
      if (finditem.companyBill.businessId===12) setItemShow({supplierId: true})
      else setItemShow({supplierId: false})
      costList = await uniAxList({params: {businessId:finditem.companyBill.businessId,size:999},axApi: getCostTypeTreeV2,setList:setModalFeeTypeList})
    }

    let tempnewlist = []
    let detailist = finditem.companyBill.detailList
    for (let i=0;i<detailist.length;i++) {
      let findsubject = subjectList.find((item:any)=>item.id===detailist[i].subjectId)
      let subjectbindList = await uniAxList({params:{companyId:finditem.companyId,subjectId:detailist[i].subjectId},axApi: axSubjectBindList})
      let summaryarr = detailist[i].summary.split('!!!')
      tempnewlist.push({
        ...detailist[i], price: (detailist[i].price / 100),rowid:i+1, accountList:modalAccountList, subjectItem:findsubject, costList,
        subjectbindList,fullSubname:summaryarr[0],summary:summaryarr[1],
        accountCostId:(detailist[i].isAccount?detailist[i].accountId:detailist[i].costTypeId),
        noaffix: ((subjectbindList && subjectbindList.length>0) ? false : true)
      })
    }
    setNewrowList(tempnewlist)
    setCurTmplate(finditem)
    setSubmodalLoading(false)
    setShowSubmodal(false)
  } else {
    let temparr:any = []
    currentNewrow.subjectItem.subjectAccounting.forEach((item:any)=>{
      let detailid = formValues[item.accountingId+'_detailId']
      let finditem
      if (detailid) finditem = affixDetailInfo[item.accountingId].find((detailitem:any)=>detailitem.id === detailid)
      temparr.push({accountingId:item.accountingId,accountingName:item.accountingName,detailId:detailid,detailName:finditem?.name,detailNo:finditem?.no})
    })
    uniAxList(dataBindAffixdetails({companyId:modalRef.current?.getFieldValue('companyId'),subjectId:currentNewrow.subjectId,accountingDetail:temparr}))
  }
}

//统计数据修饰
const modifyStatic = ({collectionPrice,notCollectionPrice,payPrice,notPayPrice}:any) => {
  return [
    {name: '公司收到款', hasCash:collectionPrice/100, unCash:notCollectionPrice/100, rowTotal: collectionPrice/100 + notCollectionPrice/100},
    {name: '公司支出款', hasCash:payPrice/100, unCash:notPayPrice/100, rowTotal: payPrice/100 + notPayPrice/100},
    {name: '合计', hasCash: collectionPrice/100 + payPrice/100, unCash: notCollectionPrice/100 + notPayPrice/100, rowTotal: collectionPrice/100 + payPrice/100 + notCollectionPrice/100 + notPayPrice/100}
  ]
}
//路由刷新请求
const routerFlash = (billId?:any) => {
  if (billId) uniAxList(dataRow({billId}))
  uniAxList(uniCompanyData)
  uniAxList(dataSupplierList)
}

/* elements */
//搜索行
const searchList:FormItemObj[] = [
{label: '所属公司', prop:'filter_EQS_c.company_id',type:'select',dataList:companyList,required:true,clearable:false,change:(value:any)=>{
  uniAxList(dataAccountList(value,'select'))
  searchRef.current?.setFieldsValue({'filter_BETWEENS_c.bill_time':null})
  let finditem:any = companyList.find((item:any)=>item.id===value)
  if (finditem.initialize===1) setSearchYmRange([finditem.startYm,finditem.endYm])
  else setSearchYmRange([])
}},
{label: '账单日期', prop:'filter_BETWEENS_c.bill_time',type:'dateRange',daterangeProps:{returnType:'string',disabledDate:(current)=>{
  if (searchYmRange.length<2) return true
  else return current<moment(searchYmRange[0]).startOf('month') || current>moment(searchYmRange[1]).endOf('month')
}}},
{label: '收支ID', prop:'filter_LIKES_c.id'},
// {label: '摘要', prop:'filter_LIKES_c.summary'},
{label: '收支类型', prop:'filter_EQL_c.bill_type',type:'select',dataList:companyBillType,change:(value:any)=>{
  searchRef.current?.setFieldsValue({filter_EQL_business_id:null,filter_EQL_cost_type_id:null})
  if (value && value!==3) uniAxList(dataBusinessList(value,'select'))
  else setSelectBzList([])
}},
{label: '所属业务', prop:'filter_EQL_c.business_id',type:'select',dataList:selectBzList,change:(value:any)=>{
  searchRef.current?.setFieldsValue({filter_EQL_cost_type_id:null})
  if (value) uniAxList(dataCosttypeList(value,'select'))
  else setSelectFeeTypeList([])
}},
{label: '费用类型', prop:'filter_EQL_d.cost_type_id',type:'select',dataList:selectFeeTypeList},
{label: '作废状态', prop:'filter_EQB_c.is_cancel',type:'select',dataList:cancelStatus},
{label: '账户', prop:'filter_EQS_d.account_id',type:'select',dataList:selectAccountList,selectProps:{childrenKeys:['name','cardNo']}},
{label: '供应商', prop:'filter_LIKES_c.supplier_name'},
// {label: '付款账户', prop:'filter_EQS_c.pay_account_id',type:'select',dataList:selectAccountList,selectProps:{childrenKeys:['name','cardNo']}},
// {label: '收/付状态', prop:'cashstatus',type:'select',dataList:cashStatusCancel},
// {label: '收/付日期', prop:'filter_BETWEENS_c.collection_time_OR_pay_time',type:'dateRange',daterangeProps:{returnType:'string'}},
// {label: '是否账号', prop:'filter_EQB_d.is_account',type:'select',dataList:initIsaccount},
// {label: '借贷方向', prop:'filter_EQB_d.borrow_lend',type:'select',dataList:leadBorrow},
]
//导入的功能的props
const uploadProps: UploadProps = {
name: 'file',
action: (process.env.NODE_ENV === 'production') ? '/api/xbfs/fin/companyBill/import' : 'http://localhost:3000/xbfs/fin/companyBill/import',
accept: ".xlsx,.xls,.docx,.csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
headers: {
  authorization: tokenString.slice(0,tokenString.length-3),
},
onChange(info) {
  if (info.file.status !== 'uploading') {
    console.log(info.file, info.fileList);
  }
  if (info.file.status === 'done') {
    message.success('文件上传成功')
    setImportModal(()=>false)
    setTimeout(() => {
      uniAxList(dataRow())
    }, 1000)
    // message.success(`${info.file.name} file uploaded successfully`);
  } else if (info.file.status === 'error') {
    message.error('文件上传失败')
    setErrorMessage(()=>({value:info.file.response.data}))
    // message.error(`${info.file.name} file upload failed.`);
  }
},
};
//顶部操作
const modaltopActions:ColumnAction[] = [
{label: '选择模板', type:'primary',action: () => {
  let companyId = modalRef.current?.getFieldValue('companyId')
  if (!companyId) return message.error('请先选择公司')
  if (curTmplate.id) setSubselectedRowKeys([curTmplate.id])
  setSubmodalFlag('choosetmp')
  setShowSubmodal(true)
  uniAxList(dataTempList(companyId))
}},
{label: '保存为新模板', type:'primary',loading:tmpLoading,action: () => {
  let companyId = modalRef.current?.getFieldValue('companyId')
  setSubmodalFlag('newtmp')
  if (!companyId) return message.error('请先选择公司')
  setShowSubmodal(true)
}}
]
// //列项
const columns:ColumnItem[] = [
{title: '收支类型',key: 'billType',type:'tag',tagProps:{values:[2,1,3]},custval:({row,value}:any)=>uniValueToName({list:companyBillType,value})},
// {title: '收支摘要',key: 'summary'},
{title: '所属公司',key: 'companyName'},
{title: '账单日期',key: 'billTime',custval:({row,value}:any)=>value?value.split(' ')[0]:''},
{title: '供应商',key: 'supplierName'},
{title: '业务名称',key: 'businessName'},
{title: '金额',key: 'price',type:'price'},
{title: '凭证状态',key: 'proofStatus',custval:({row,value}:any)=>uniValueToName({list:proofStatusList,value})},
{title: '凭证号',key: 'accountProof',render: (value:any,row:any) => {
  return value.map((item:any) => <span key={item.accountProofId}><Typography.Link onClick={()=>uniToProof(row,item.accountProofId,navigate)}>{item.accountProofNo}</Typography.Link> </span>)
}},
{title: '操作',key:'operation',type: 'operation',width:90,opProps:{opList:[
  {name:'详情',action:({row}:any) =>{
    uniAxList(dataBusinessList(row.businessId,'modal'))
    setModalFlag('detail')
    setFormInitials(uniInitFormdata(row,formItems))
    setDetailRowlist([...row.detailList])
    setShowModal(true)
  }},
  // {name:'作废',action:({row}:any) =>uniOpAlarm(()=>uniAxList(dataCancelRows(row.id)))},
  {name:'删除',action:({row}:any) =>uniOpAlarm(()=>uniAxList(dataDelrow(row.id)))}
]}}]
// //列项
const newColumns:ColumnItem[] = [
{title: '类型',key: 'isAccount',width:150,type:'select',clearable:false,dataList: () => initIsaccount,change:(value:any,row:any)=>{
  row.accountCostId = null
  row.subjectName = null
  setNewrowList([...newrowList])
}},
{title: '借贷方向',key: 'borrowLend',width:100,clearable:false,type:'select',dataList:() => leadBorrow,change:(value:any,row:any)=>{
  setNewrowList([...newrowList])
}},
{title: '账户/费用',key: 'accountCostId',width:200,type:'select',selectProps:{childrenKeys:['name','cardNo']},clearable:false,dataList:(row:any)=>row.isAccount?row.accountList:row.isAccount===false?row.costList:[],change: async (value:any,row:any)=>{
  // row.accountingTypeDetail = null
  let findtempitem:any
  if (row.isAccount) findtempitem = modalAccountList.find((item:any)=>item.id===value)
  else findtempitem = modalFeeTypeList.find((item:any)=>item.id===value)
  // let {findItem, parents}:any = uniFilterWithPa(subjectList,findtempitem.accountSubjectId)
  let findsubjectItem:any = subjectList.find((item:any)=>item.id===(row.isAccount?findtempitem.accountSubjectId:findtempitem.subjectId))
  let parentItem:any
  if (findsubjectItem.parent) parentItem = subjectList.find((item:any)=>item.id===findsubjectItem.parent)
  //判断是否有辅助核算
  // uniAxList(dataAssistList(findtempitem.accountSubjectId || findtempitem.subjectId,row))
  row.subjectItem = findsubjectItem
  row.noaffix = findsubjectItem.subjectAccounting.length <= 0
  if (findsubjectItem.subjectAccounting.length > 0) uniAxList(dataSubjectBindList(modalRef.current?.getFieldValue('companyId'),findsubjectItem.id,row))
  if (findtempitem) {
    row.subjectId = row.isAccount ? findtempitem.accountSubjectId : findtempitem.subjectId
    row.subjectName = findsubjectItem.name
    if (parentItem) row.fullSubname = parentItem.name+'-'+findsubjectItem.name
    else row.fullSubname = findsubjectItem.name
    setNewrowList([...newrowList])
  } else {
    row.subjectId = null
    row.subjectName = null
    row.fullSubname = null
    setNewrowList([...newrowList])
  }
}},
{title: '科目',key: 'fullSubname',width:200},
{title: '辅助详情',key: 'subjectAccountingDetailId',width:200,type:'select',disabled:({row,value}:any)=>row.noaffix,dataList:(row:any)=>row.subjectbindList || [],selectProps:{propName:'noName'},suffix:({row,value}:any)=>{
  if (row.subjectId && !row.noaffix) return <PlusCircleOutlined style={{color:'#1890ff',fontSize:20,cursor:'pointer'}} onClick={()=>{
    let temparr:FormItemObj[] = []
    let companyId = modalRef.current?.getFieldValue('companyId')
    row.subjectItem.subjectAccounting.forEach((item:any)=>{
      temparr.push({label:item.accountingName,prop:(item.accountingId+'_detailId'),required:item.needAccounting?true:false,type:'select',dataList:()=>affixDetailInfo[item.accountingId] || []})
      uniAxList(dataAffixDetailList(item.accountingId,companyId))
    })
    setCurrentNewrow(row)
    setNewbindItem(temparr)
    setShowSubmodal(true)
  }} />
  else return <></>
}},
{title: '金额',key: 'price',usedefault:true,type:'number',numProps:{precision:2},change:()=>setNewrowList([...newrowList])},
{title: '凭证摘要',key: 'summary',type:'input',width:240,usedefault:true,change:()=>setNewrowList([...newrowList])},
{title: '操作',key:'operation',type: 'operation',width:80,opProps:{type:'button',opList:[
  {name:'+',action:({row,index}:any) =>setNewrowList((prelist:any)=>{
    idcount += 1
    let temparr1 = prelist.slice(0,index+1)
    let temparr2 = prelist.slice(index+1)
    return [...temparr1,{...initNewrowObj,accountList:modalAccountList,costList:modalFeeTypeList,rowid:idcount},...temparr2]
  })},
  {name:'-',show:()=>newrowList.length>1,action:({row,index}:any) =>setNewrowList((prelist:any)=>{
    let temparr1 = prelist.slice(0,index)
    let temparr2 = prelist.slice(index+1)
    return [...temparr1,...temparr2]
  })},
]}}]
//列项
const detailColumns:ColumnItem[] = [
{title: '类型',key: 'isAccount',custval:({row,value}:any)=>uniValueToName({list:initIsaccount,value})},
{title: '借贷方向',key: 'borrowLend',custval:({row,value}:any)=>uniValueToName({list:leadBorrow,value})},
{title: '账户/费用',key: 'accountCostId',custval:({row,value}:any)=>row.isAccount?row.accountName:row.costTypeName},
// {title: '科目',key: 'subjectName'},
{title: '金额',key: 'price',type:'price'},
{title: '备注',key: 'summary'},
]
//统计列项
const statColumns:ColumnItem[] = [
{title: '公司收到款',key: 'income',type:'price'},
{title: '公司支出款',key: 'outcome',type:'price'},
{title: '内部转账',key: 'trans',type:'price'}
]

//凭证table列项
const tmpcolumns:ColumnItem[] = [
{title: '名称', key:'name'},
{title: '编码', key:'code'},
{title: '备注', key:'remark'},
{title: '操作',key:'operation',type: 'operation',width:120,opProps:{opList:[
  {name:'删除',action:({row}:any) =>uniOpAlarm(()=>uniAxList(dataDeltmprow(row.id)))}
]}}
// {title: '操作',key:'operation',type: 'operation',width:120,opProps:{opList:[
//   {name:'删除',action:({row}:any) =>uniOpAlarm(()=>uniAxList(dataDeltmprow(row.id)))}
// ]}}
]
//底部操作
const bottomActions:ColumnAction[] = [
{label: '新增', type:'primary', action: () => {
  // setFormInitials({...initForminitial,billTime:caryDate?moment(caryDate):moment()})
  // setFormInitials({...initForminitial,billTime:rowList[0]?moment(rowList[0].billTime):null})
  setFormInitials({...initForminitial})
  setNewrowList([{...initNewrowObj,rowid:1},{...initNewrowObj,rowid:2}])
  uniAxList(dataBusinessList(1,'modal'))
  setModalFlag('new')
  setShowModal(() => true)
  // modalRef.current?.setFieldsValue({billTime:rowList[0]?moment(rowList[0].billTime):null})
}},
// {label: '批量作废', type:'primary',isDanger:true,disabled:!selectedRowKeys.length,action: () => uniOpAlarm(()=>uniAxList(dataCancelRows()),`已选${selectedRowKeys.length}条记录, 是否继续`)},
{label: '数据统计', type:'primary', action: () => {
  uniAxList(dataStatrow())
  setModalFlag('static')
  setShowModal(true)
  // uniStaticLast({
  //   axApi:companyBillList,
  //   params:{...searchParams, page:null},
  //   staticColumns,
  //   staticRows,
  //   setStaticModal:setShowModal,
  //   setStaticTableData,
  //   setStaticLoading
  // })
}},
{label: 'bottomslot', render:<>
  <span style={{color: '#1890ff'}}>本页金额: </span>&nbsp;<span>{Number((statInfo.price/100).toFixed(2))}</span>&nbsp;&nbsp;
</>},
]
//form项
const formItems:FormItemObj[] = [
{label: '所属公司',prop: 'companyId',clearable:false,type:'select',dataList:companyList,selectProps:{requireName:'companyName'},change:(value:any)=>{
  uniAxList(dataSubjectList(value))
  modalRef.current?.setFieldsValue({billTime:null})
  let finditem:any = companyList.find((item:any)=>item.id===value)
  if (finditem.initialize===0) {
    modalRef.current?.setFieldsValue({companyId:null})
    return message.error('该公司未初始化, 不能新增收支')
  }
  setModelEndym(finditem.endYm)
  setModelCompanyItem(finditem)
  uniAxList(dataAccountList(value,'modal'))
}},
{label: '收付类型',prop: 'billType',clearable:false,type:'select',dataList:companyBillType,change:(value:any)=>{
  newrowList.forEach((item:any)=>{
    if (!item.isAccount) {
      item.accountCostId = null
      item.subjectName = null
      item.fullSubname = null
      item.costList = []
    }
  })
  //modal的控制变量
  setFormdata((predata:any)=>({...predata,billType:value}))
  //属性置空
  modalRef.current?.setFieldsValue({businessId:null,costTypeId:null})
  setModalFeeTypeList([])
  // setModalAccountList([])
  uniAxList(dataBusinessList(value,'modal'))
}},
{label: '所属业务',prop: 'businessId',type:'select',dataList:modalBzList,selectProps:{requireName:'businessName'},clearable:false,change:(value:any)=>{
  if (value===12) setItemShow({supplierId: true})
  else setItemShow({supplierId: false})
  uniAxList(dataCosttypeList(value,'modal'))
}},
{label: '账单日期',prop: 'billTime',type:'datePicker',datepickerProps:{returnType:'string',disabledDate:(current)=>current<moment(modelcompanyItem.beginYm).startOf('month') || current>moment(modelcompanyItem.endYm).endOf('month')}},
{label: '供应商',prop: 'supplierId',type:'select',dataList:supplierList,selectProps:{requireName:'supplierName'},clearable:false,show:itemShow.supplierId}
]
//确认付款项
const confirmItems:FormItemObj[] = [
{label: '账户',prop: 'accountId', type:'select',dataList:modalAccountList,selectProps:{requireName:'accountName'}},
{label:'确认时间', prop: 'collectionPayTime', type:'datePicker', datepickerProps:{returnType:'string'}},
]
//保存为新模板model项
const tmplItems:FormItemObj[] = [
{label: '名称',prop: 'name'},
{label: '编码',prop: 'code'},
{label: '备注',prop: 'remark',required:false}
]

/* 接口参数 */
const dataRow = (params?:any):AxDataform => ({
  params: {...searchParams, 'filter_LIKES_c.id':params?.billId || searchParams['filter_LIKES_c.id'],current: params?.current || pagination.current, size:pagination.pageSize},
  axApi: companyBillList,
  setList: setRowList,
  setTableLoading,
  setPagination,
  awaitFunc: (predata:any)=>{
    if (predata.length>0) {
      setStatInfo({price: predata.reduce((totle:number,item:any)=>totle+(item.price || 0),0)})
    }
  }
})
//统计行
const dataStatrow = ():AxDataform => ({
  params: tableParams,
  axApi: companyBillList,
  setTableLoading:setStaticLoading,
  awaitFunc:(predata:any) => {
    let income = 0
    let outcome = 0
    let trans = 0
    predata.forEach((item:any)=>{
      if (item.billType===1) income += item.price
      else if (item.billType===2) outcome += item.price
      else if (item.billType===3) trans += item.price
    })
    setStatRowlist([{income,outcome,trans}])
  }
})
//新增收支createCompanyBill
const dataNewrow = (params:any,list:any):AxDataform => ({
  params:{...params,detailList:list},
  axApi: createCompanyBill,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//修改收支
const dataUpdaterow = (params:any):AxDataform => ({
  params: {...params, id:currentRow.id},
  axApi: updateCompanyBill,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//删除行
const dataDelrow = (rowid:any):AxDataform => ({
  params: {id:rowid},
  axApi: deleteCompanyBill,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//批量作废
const dataCancelRows = (rowid:any) => ({
  // params: selectedRowKeys.map((item:any)=>({id:item.id})),
  params: [rowid],
  axApi: cancelCompanyBill,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//确认收付
const dataConfirmRow = (type:any,params:any):AxDataform =>({
  params,
  axApi: type===3?confirmCompanyBillTrans:confirmCompanyBill,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//公司列表
const uniCompanyData:any = {
  axApi: getCompanyList,
  setList: setCompanyList
}
//所属业务列表
const dataBusinessList = (type:any,flag:any):AxDataform => ({
  params:{type,orderBy:"sort", orderDir: "desc"},
  axApi: companyBillBusiness,
  setList: flag==='select'? setSelectBzList :setModalBzList,
})
//公司账户列表
const dataAccountList = (companyid:any,flag:any):AxDataform => ({
  params: {companyId:companyid},
  axApi: getCompanyAccoutList,
  setList: flag==='select'?setSelectAccountList:setModalAccountList,
  awaitFunc: (predata:any)=>{
    if (flag==='modal') newrowList.forEach((item:any)=>{
      item.accountList = predata
      if (item.isAccount) {
        item.accountCostId = null
        item.subjectName = null
        item.fullSubname = null
        // item.accountingTypeDetail = null
        // item.hasAssist = false
      }
    })
  }
})
//费用类型
const dataCosttypeList = (businessId:any,flag:any):AxDataform => ({
  params: {businessId,size:999},
  axApi: getCostTypeTreeV2,
  setList: flag==='select'?setSelectFeeTypeList:setModalFeeTypeList,
  awaitFunc: (predata:any)=>{
    if (flag==='modal') newrowList.forEach((item:any)=>{
      item.costList = predata
      if (!item.isAccount) {
        item.accountCostId = null
        item.subjectName = null
        item.fullSubname = null
        // item.accountingTypeDetail = null
        // item.hasAssist = false
      }
    })
  }
})
const uniStaticData:any = {
  axApi: getCompanyBillTotal,
  modifyFunc: modifyStatic,
  setList: setStaticTableData,
}
//会计科目详情
const dataSubjectDetail = (id:any):AxDataform => ({
  params: {filter_EQS_id: id},
  axApi: axSubjectList
})
//会计科目的辅助核算列表
// const dataAssistList = (subjectid:any,row?:any):AxDataform => ({
//   params: {filter_EQS_subject_id: subjectid},
//   axApi: axAffixExSubjectList,
//   setList: row?null:setAssistList,
//   awaitFunc: (predata:any) => {
//     if (predata && predata.length>0) row.hasAssist = true
//     else row.hasAssist = false
//     setNewrowList([...newrowList])
//   }
// })
//辅助核算详情列表
const dataAssistDetailList = (assistId:any,companyId:any):AxDataform => ({
  params: {accountingId: assistId,companyId},
  axApi: axAffixSubjectDetail,
  setList: setAssistdetailList
})
// const dataCaryDate:AxDataform = {
//   axApi: getReviewTempList,
//   awaitFunc: (predata:any)=>{
//     if(predata && predata.length>0 && predata[0].caryDate) setCaryDate(predata[0].caryDate)
//   }
// }
//公司会计科目列表
const dataSubjectList = (companyId:any):AxDataform => ({
  params: {page:1, size:999,companyId},
  axApi: axSubjectList,
  itemModify: {modifyFunc:({item}:any)=>item.title=item.subjectNo+' '+item.name},
  setList: setSubjectList
})
//获取科目详情组合
const dataSubjectBindList = (companyId:any,subjectId:any,row:any):AxDataform => {
  return {
    params:{companyId,subjectId},
    axApi: axSubjectBindList,
    awaitFunc: (predata:any)=>{
      newrowList.forEach((item:any)=>{
        if(item.subjectId === subjectId) item.subjectbindList = predata
      })
      // row.subjectbindList = predata
      setNewrowList([...newrowList])
    }
  }
}
//供应商列表--通过辅助核算详情获取 accountingType
const dataSupplierList:AxDataform = {
  params: {accountingId: 2},
  axApi: axAffixSubjectDetail,
  setList: setSupplierList
}
//辅助核算详情列表
const dataAffixDetailList = (assistId:any,companyId:any):AxDataform => ({
  params: {accountingId: assistId,companyId},
  axApi: axAffixSubjectDetail,
  awaitFunc: (predata:any)=>{
    affixDetailInfo[assistId] = predata
    setAffixDetailInfo({...affixDetailInfo})
  }
  // setList: setAffixdetailList
})
//科目绑定辅助核算详情
const dataBindAffixdetails = (params:any):AxDataform => ({
  params,
  axApi: axSubjectBindAffixdetails,
  setModalLoading:setSubmodalLoading,
  setShowModal:setShowSubmodal,
  awaitFunc: (predata:any)=>{
    currentNewrow.subjectAccountingDetailId = predata.id
    uniAxList(dataSubjectBindList(params.companyId,params.subjectId,currentNewrow))
  }
})
//新建模板
const dataNewtemp = (params:any):AxDataform => ({
  params,
  axApi: dataNewCompbilltemp,
  setModalLoading:setSubmodalLoading,
  setShowModal:setShowSubmodal,
  successTip:true
})
//模板列表
const dataTempList = (companyId:any):AxDataform => ({
  params: {filter_EQL_company_id: companyId},
  axApi: dataCompbilltempList,
  setList: setTemplateList,
  setTableLoading:setSubtableLoading
})
//删除模板
const dataDeltmprow = (rowid:any):AxDataform => ({
  params: {id:rowid},
  axApi: dataDelCompbilltemp,
  successTip: true,
  awaitFunc: ()=>{
    uniAxList(dataTempList(modalRef.current?.getFieldValue('companyId')))
    setSubselectedRowKeys([])
  }
})

/* useEffect */
//监听路由变化
React.useEffect(() => {
  if (location.pathname===pathname) {
    let mystate:any = state
    if (state) {
      searchRef.current?.setFieldsValue({'filter_LIKES_c.id':mystate.billid})
      if (mystate.companyId) searchRef.current?.setFieldsValue({'filter_EQS_c.company_id':mystate.companyId})
      routerFlash(mystate.billid)
    }
    else routerFlash()
  }
}, [location]);

/* mounted */
React.useEffect(() => {
// uniAxList(dataRow())
// uniAxList(dataCaryDate)
// uniAxList(dataSupplierList)
// uniAxList(uniCompanyData)
// uniAxList(dataSubjectList)
setLoadFlag(()=>true)
}, [])

return (<>
<TopSearch
  ref={searchRef}
  searchList={searchList}
  searchConfirm={searchConfirm}
  initialValues={initSearchParams}
/>
<SuperTable
  columns={columns}
  rowList={rowList}
  bottomActions={bottomActions}
  tableLoading={tableLoading}
  pagination={pagination}
  dataRow={dataRow}
  rowSelection={{setSelectedRowKeys, setSelectedRows, selectedRowKeys}}
/>
<FormDialog
  ref={modalRef}
  type={['new','detail'].includes(modalFlag)?'drawer':'modal'}
  drawerSize="70%"
  title={modalFlag==='new'?'新增':modalFlag==='update'?'修改':modalFlag==='static'?'数据统计':modalFlag==='detail'?'详情':'确认收付款'}
  show={showModal}
  formItems={modalFlag==='static'?[]:modalFlag==='confirm'?confirmItems:formItems}
  modalLoading={modalLoading}
  formInitials={formInitials}
  overBody={modalFlag==='new'?false:true}
  cancel={()=>{
    idcount = 2
    setShowModal(()=>false)
    setCurrentRow({})
    setModelCompanyItem({})
    setNewrowList([{...initNewrowObj,rowid:1},{...initNewrowObj,rowid:2}])
    setDetailRowlist([])
    setFormInitials({...initForminitial,billTime:caryDate})
  }}
  confirm={modalConfirm}
  showCustom={['static','new','detail'].includes(modalFlag)}
  hasConfirm={!['static','detail'].includes(modalFlag)}
>
  {
    modalFlag==='static'
    ?<SuperTable
      columns={statColumns}
      rowList={statRowlist}
      tableLoading={staticLoading}
    />:null
  }
  {
    ['new','detail'].includes(modalFlag)
    ?<>
    {modalFlag==='new'?<TopSearch
      ref={searchRef}
      topActions={modaltopActions}
      searchList={[]}
      hasButton={false}
      searchConfirm={()=>{}}
    />:null}
    <SuperTable
      rowKey={modalFlag==='new'?'rowid':'id'}
      columns={modalFlag==='new'?newColumns:detailColumns}
      rowList={modalFlag==='new'?newrowList:detailRowlist}
    />
    </>
    :null
  }
</FormDialog>
<FormDialog
  ref={submodalRef}
  title={submodalFlag==='newtmp'?'新建模板':submodalFlag==='choosetmp'?'选择模板':'新增详情组合'}
  show={showSubmodal}
  formItems={submodalFlag==='choosetmp'?[]:submodalFlag==='newtmp'?tmplItems:newbindItem}
  modalLoading={submodalLoading}
  showCustom={submodalFlag==='choosetmp'}
  cancel={()=>{
    setShowSubmodal(false)
    setAffixDetailInfo({})
    setNewbindItem([])
    setSubmodalFlag('')
    setSubselectedRowKeys([])
    setSubselectedRows([])
    setShowSubmodal(false)
  }}
  confirm={submodalConfirm}
>
  <span></span>
  <SuperTable
    bordered={true}
    columns={tmpcolumns}
    rowList={templateList}
    tableLoading={subtableLoading}
    rowSelection={{type: 'radio',selectedRowKeys:subselectedRowKeys,setSelectedRowKeys:setSubselectedRowKeys,setSelectedRows:setSubselectedRows}}
  />
</FormDialog>
</>)
}
