/**
 * 存储公共函数：多个页面都需要调用的函数
 */

import { message, Popconfirm, TreeSelect, Typography,Modal, Button, ConfigProvider } from "antd"
import locale from 'antd/es/locale/zh_CN';
import {AES, SHA256, algo, enc, lib, mode, pad, ɵa, ɵb, ɵc, ɵe, ɵf, ɵg, ɵi, ɵj, ɵk, ɵl, ɵm, ɵn, ɵo, ɵp, ɵq, ɵr, ɵs, ɵt, ɵu} from 'crypto-ts'
// import {utils, writeFile,write} from "xlsx"
import {utils, writeFile, read} from "xlsx"
// import * as XLSX from "xlsx"
// import {write} from "xlsx-style-fixedver"
import {write} from "xlsx-style-vite"
import FileSaver from "file-saver";
import { axProofdetail, createUser, fileUpload, getCompanyList, getDepTree, getPositionList, getProofList, getProofList2, getRoleList, getStaffCharList, modifyDep, updateStaff } from "./api"
import { DpItem, PsItem } from "./beans"
import { csAlphabets, chNum } from "./constant"
import { buffer } from "stream/consumers";
import moment from "moment";
import { getLocalItem } from "./localstorage";

//获取当前时间形如 年-月-日 小时-分钟-秒
export const uniCurrentTime:any = ({year=true,month=true,day=true,hour=false,min=false,second=false}:any) => {
  const monthList = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
  const d = new Date()
  let resultStr = ''
  if (year) resultStr += d.getFullYear().toString()
  if (month) resultStr += ((resultStr?'-':'') + monthList[d.getMonth()])
  if (day) resultStr += ((resultStr?'-':'') + ((d.getDate() < 10) ? ('0' + d.getDate()) : d.getDate()))
  if (hour) resultStr += ((resultStr?' ':'') + d.getHours())
  if (min) resultStr += ((resultStr?':':'') + d.getMinutes())
  if (second) resultStr += ((resultStr?':':'') + d.getSeconds())
  return resultStr
}
//获取数字各个位数
export const uniSplitDigit = (value:number) => {
  let positve = true
  if (value < 0) positve = false
  let price = Math.abs(value)
  let numStrList = []
  let priceArry = price.toString().split('.')
  let intStrList = priceArry[0].split('')
  let floatStrList = priceArry[1] ? priceArry[1].split('') : []
  for (let i = 0; i < 9; i++) {
    if (intStrList.length > 0 && i >= 9-intStrList.length && intStrList[0]!=='0') numStrList.push(intStrList[intStrList.length-(9-i)])
    else numStrList.push('')
  }
  if (floatStrList.length === 0) {
    if(intStrList.length>0 && intStrList[0] !=='0') numStrList.push(...['0','0'])
    else numStrList.push(...['',''])
  }
  else if (floatStrList.length === 1) numStrList.push(...[floatStrList[0],'0'])
  else if (floatStrList.length === 2) {
    if (intStrList[0] ==='0' && floatStrList[0]==='0') numStrList.push(...['',floatStrList[1]])
    else numStrList.push(...floatStrList)
  }
  if (!positve) {
    let findindex = numStrList.findIndex((key:any)=>key !== '')
    if (findindex > 0) numStrList[findindex-1] = '-'
  }
  return numStrList
}

//阿拉伯数字边中文大写
export const uniNumToCh = (price:number) => {
  let chString = ''
  const priceArry:string[] = price.toString().split('.')
  const intNumStrList:string[] = priceArry[0].split('')
  const floatNumStrList:string[] = priceArry[1] ? priceArry[1].split('') : []
  const chIntNum1 = (num:string, hasPre=true) => {
    if(num==='0') {
      if (hasPre) {
        if (floatNumStrList.length>0) return '元'
        else return '元整'
      } else return ''
    } else {
      if (floatNumStrList.length>0) return chNum[num]+'元'
      else return chNum[num]+'元整'
    }
  }
  const chIntNum2 = (nums:string[],hasPre=true) => {
    if (nums[0]==='0') {
      if (hasPre) return '零'+chIntNum1(nums[1],false)
      else return chIntNum1(nums[1],false)
    } else return chNum[nums[0]]+'拾'+chIntNum1(nums[1])
  }
  const chIntNum3 = (nums:string[],hasPre=true) => {
    if (nums[0]==='0') {
      if (hasPre) return '零'+chIntNum2(nums.slice(1),false)
      else return chIntNum2(nums.slice(1),false)
    } else return chNum[nums[0]]+'佰'+chIntNum2(nums.slice(1))
  }
  const chIntNum4 = (nums:string[],hasPre=true) => {
    if (nums[0]==='0') {
      if (hasPre) return '零'+chIntNum3(nums.slice(1),false)
      else return chIntNum3(nums.slice(1),false)
    } else return chNum[nums[0]]+'仟'+chIntNum3(nums.slice(1))
  }
  const chIntNum5 = (nums:string[],hasPre=true) => {
    if (nums[0]==='0') return '万'+chIntNum4(nums.slice(1),false)
    else return chNum[nums[0]]+'万'+chIntNum4(nums.slice(1))
  }
  const chIntNum6 = (nums:string[],hasPre=true) => {
    if (nums[0]==='0' && nums[1]==='0') {
      if (hasPre) return '万零'+chIntNum5(nums.slice(1),false)
      else return chIntNum5(nums.slice(1),false)
    } else if (nums[0]==='0') {
      if (hasPre) return '零'+chIntNum5(nums.slice(1),false)
      else return chIntNum5(nums.slice(1),false)
    } else return chNum[nums[0]]+'拾'+chIntNum5(nums.slice(1))
  }
  const chIntNum7 = (nums:string[],hasPre=true) => {
    if (nums[0]==='0' && nums[1]==='0' && nums[2]==='0') {
      if (hasPre) return '万零'+chIntNum6(nums.slice(1),false)
      else return chIntNum6(nums.slice(1),false)
    } else if (nums[0]==='0') {
      if (hasPre) return '零'+chIntNum6(nums.slice(1),false)
      else return chIntNum6(nums.slice(1),false)
    } else return chNum[nums[0]]+'佰'+chIntNum6(nums.slice(1))
  }
  const chIntNum8 = (nums:string[],hasPre=true) => {
    if (nums[0]==='0') {
      if (hasPre) return '零'+chIntNum7(nums.slice(1),false)
      else return chIntNum7(nums.slice(1),false)
    } else return chNum[nums[0]]+'仟'+chIntNum7(nums.slice(1))
  }
  const chIntNum9 = (nums:string[],hasPre=true) => {
    if (nums[0]==='0') {
      if (hasPre) return '零'+chIntNum8(nums.slice(1),false)
      else return chIntNum8(nums.slice(1),false)
    } else return chNum[nums[0]]+'亿'+chIntNum8(nums.slice(1))
  }
  const chFloatNum1 = (num:string,hasNext=true) => {
    if (num==='0') {
      if (hasNext && !(intNumStrList.length===1 && intNumStrList[0]==='0')) return '零'
      else return ''
    } else {
      if (hasNext) return chNum[num] + '角'
      else return chNum[num] + '角整'
    }
  }
  const chFloatNum2 = (nums:string[],hasNext=true) => {
    if (nums[1]==='0') {
      if (hasNext) return '零'
      else return ''
    } else return chFloatNum1(nums[0]) + chNum[nums[1]] + '分'
  }
  if(intNumStrList.length === 1)  chString = chIntNum1(intNumStrList[0],false)
  else if (intNumStrList.length === 2) chString = chIntNum2(intNumStrList,false)
  else if (intNumStrList.length === 3) chString = chIntNum3(intNumStrList,false)
  else if (intNumStrList.length === 4) chString = chIntNum4(intNumStrList,false)
  else if (intNumStrList.length === 5) chString = chIntNum5(intNumStrList,false)
  else if (intNumStrList.length === 6) chString = chIntNum6(intNumStrList,false)
  else if (intNumStrList.length === 7) chString = chIntNum7(intNumStrList,false)
  else if (intNumStrList.length === 8) chString = chIntNum8(intNumStrList,false)
  else if (intNumStrList.length === 9) chString = chIntNum9(intNumStrList,false)
  if (floatNumStrList.length === 1) chString += chFloatNum1(floatNumStrList[0], false)
  else if (floatNumStrList.length === 2) chString += chFloatNum2(floatNumStrList, false)
  return chString.replace(/零+/,'零')
}

/* 接口增加方法 */
//通用新增row表单提交
export const uniAddRowSubmit = ({setModalLoading,axGetData,customFunc, axAddApi, setAddModal, customAddKeys, awaitFunc, tempChange, isArray=false, needArray=false}:any) => {
  return async (formValus:any) => {
    setModalLoading(()=>true)
    let axAddData:any = {...formValus, ...customAddKeys}
    if (tempChange && tempChange === 'companyment') {
      if (formValus.bothPrice) {
        axAddData.collectionPrice = formValus.bothPrice
        axAddData.payPrice = formValus.bothPrice
      }

      if (formValus.serviceAccountId && formValus.serviceAccountId === 1) axAddData.serviceAccountId = formValus.payAccountId
      else if (formValus.serviceAccountId && formValus.serviceAccountId === 2) axAddData.serviceAccountId = formValus.collectionAccountId
      
      if (formValus.bothTime) {
        axAddData.collectionTime = formValus.bothTime
        axAddData.payTime = formValus.bothTime
      }

    }
    let res = null
    if (needArray) axAddApi([axAddData]).then((res:any)=>{
      message.success('操作成功')
      if(awaitFunc) awaitFunc(res,formValus)
      setModalLoading(()=>false)
      if(setAddModal) setAddModal(() => false)
      uniAxList({...axGetData})
    }).catch((e:any)=>setModalLoading(()=>false))
    else if (isArray) axAddApi(formValus).then((res:any)=>{
      message.success('操作成功')
      if(awaitFunc)  awaitFunc(res,formValus)
      setModalLoading(()=>false)
      if(setAddModal) setAddModal(() => false)
      uniAxList({...axGetData})
    }).catch((e:any)=>setModalLoading(()=>false))
    else axAddApi(axAddData).then((res:any)=>{
      message.success('操作成功')
      if(awaitFunc) awaitFunc(res,formValus)
      setModalLoading(()=>false)
      if(setAddModal) setAddModal(() => false)
      uniAxList({...axGetData})
    }).catch((e:any)=>setModalLoading(()=>false))
  }
}
//新增row表单提交
export const staffAddRowSubmit = (setAddModal:Function, axParams:object) => {
  return async (formValus:any) => {
    setAddModal(() => false)
    const params = { ...formValus, roleIds:[formValus.roleIds] }
    console.log('params', params)
    const res = await createUser(params)
    if (res && res.message && res.message === 'success') {
      uniAxList(axParams)
    }
  }
}
/* 接口删除方法 */
//删除行
export const uniDeleteRow = async ({record,deleteApi,uniAxListData}:any) => {
  const res = await deleteApi({id: record.id})
  if (res && res.message && res.message === 'success') {
    message.success('删除成功')
    uniAxList(uniAxListData)
  }
}
//根据id数组批量删除
export const UniDeleteAll = (data:any) => {
  let promiseArray:Function[] = []
  data.deleteKeyList.forEach((item:string) => {
    promiseArray.push(data.deleteApi({id: item}))
  })
  Promise.all(promiseArray).then(res => {
    data.setSelectedRowKeys(() => [])
    uniAxList(data.axParams)
    message.success('操作成功')
  })
}

/* 接口修改数据方法 */
//通用修改单个row表单提交
export const uniChgRowSubmit = ({setModalLoading,currentRows, axData, updateApi, setModal,awaitFunc, customKeys, deleteKeys, tempChange,customFunc,addRowKeys=[],idName='id'}:any) => {
  return async (formValus:any) => {
    if(setModalLoading) setModalLoading(()=>true)
    let axList:Function[] = []
    if (deleteKeys) deleteKeys.forEach((key:string) => delete formValus[key])

    let axAddData:any = {...formValus, ...customKeys}
    if (tempChange && tempChange === 'companyment') {
      if (formValus.bothPrice) {
        axAddData.collectionPrice = formValus.bothPrice
        axAddData.payPrice = formValus.bothPrice
      }

      if (formValus.serviceAccountId && formValus.serviceAccountId === 1) axAddData.serviceAccountId = formValus.payAccountId
      else if (formValus.serviceAccountId && formValus.serviceAccountId === 2) axAddData.serviceAccountId = formValus.collectionAccountId
      
      if (formValus.bothTime) {
        axAddData.collectionTime = formValus.bothTime
        axAddData.payTime = formValus.bothTime
      }
    }
    currentRows.forEach((rowItem:any) => axList.push(updateApi({[idName]: rowItem.id, ...formValus, ...customKeys, ...Object.fromEntries(addRowKeys.map((rowKey:any) => [rowKey.newKey, rowItem[rowKey.sourceKey]]))})))
    Promise.all(axList).then((res:any) => {
      message.success('操作成功!')
      if (setModalLoading) setModalLoading(()=>false)
      if (setModal) setModal(() => false)
      uniAxList(axData)
      if(awaitFunc) awaitFunc(res,formValus)
    }).catch((e)=>{
      if(setModalLoading) setModalLoading(()=>false)
    })
    if (customFunc) customFunc()
  }
}

//人员管理修改row表单提交
export const staffChgRowSubmit = (roleList:any, companyList:any, setChgModal:Function, axParams:any, currentRow:any) => {
  return async (formValus:any) => {
    const tempCompanyList =  formValus.companyIds.map((idItem:string) => (companyList.find((companyItem:any) => companyItem.id === idItem)))
    const tempRole = roleList.find((roleItem:any) => roleItem.id === formValus.roleIds)
    const res = await updateStaff({...formValus, company: tempCompanyList, role:tempRole ? [tempRole] : [], id: currentRow.id})
    if (res && res.message && res.message === 'success') {
      setChgModal(() => false)
      uniAxList(axParams)
    }
  }
  
}
//批量离职
export const uniBatchQuit = (selectedRows:any, axParams:object) => {
  let promiseArray:Function[] = []
  selectedRows.forEach((item:any) => {
    if (item.status !== '0') promiseArray.push(updateStaff({id: item.id, status: '0'}))
  })
  Promise.all(promiseArray).then((res) => {
    uniAxList(axParams)
  })
}

/* 获取接口数据方法 */
//通用获取api接口函数
export const uniAxList = async ({setModalLoading,successTip,setSelectedRowKeys,setSelectedRows,itemModify,setTableLoading,setShowModal,finishLoading=true,axApi,params,setPagination,modifyFunc,modifyData,predata,setList,initBackData,setBackList,setModal,awaitFunc}:any) => {
  console.log('axapi---------',axApi)  
  let modifyRes:any
    let resData:any 
    if (setModalLoading) setModalLoading(()=>true)
    if(setTableLoading) setTableLoading(() => true)
    let getData = async ()=>{
      await axApi(params).then(async (res:any)=>{
        if(setPagination) setPagination((preinfo:any)=>({...preinfo,total:res.data.total}))
        resData = res.data.records || res.data
        if (modifyFunc) {
          if (predata) modifyRes = await modifyFunc(resData,modifyData,predata)
          else modifyRes = await modifyFunc(resData,modifyData)
        }
        if (itemModify) {
          let childrenKey = 'children'
          if (itemModify.childrenKey) childrenKey = itemModify.childrenKey
          const modFunc = (list:any,parentItem:any) => {
            list.forEach((item:any,index:number) => {
              itemModify.modifyFunc({item,index,parentItem,predata})
              if (item[childrenKey] && item[childrenKey].length>0) modFunc(item[childrenKey],item)
              else delete item[childrenKey]
            })
          }
          modFunc(resData,null)
        }
        console.log('modifyres--------', modifyRes)
        if (modifyRes && setList) setList(() => modifyRes)
        else if (setList) setList(() => (resData))
        if (initBackData) {
          const initRes = await axApi(initBackData.initData)
          if (modifyFunc) modifyFunc(initRes.data.records || initRes.data, modifyData)
          initBackData.setBackList(() => (initRes.data.records || initRes.data))
        }
        if(setBackList) setBackList(() => (resData))
        if(setTableLoading && finishLoading) setTableLoading(() => false)
        console.log('initList', res.data.records || res.data)
        if (setModal) setModal(()=>false)
        if (setModalLoading && finishLoading) setModalLoading(()=>false)
        if (setShowModal) setShowModal(()=>false)
        if (setSelectedRowKeys) setSelectedRowKeys([])
        if (setSelectedRows) setSelectedRows([])
        if (successTip) message.success('操作成功')
        if (awaitFunc) {
          if (modifyRes) awaitFunc(modifyRes)
          else awaitFunc(resData)
        }
      }).catch((e:any)=>{
        console.log('error---------------',e.message)
        if(setModalLoading) setModalLoading(()=>false)
        if(setTableLoading) setTableLoading(()=>false)
      })
    }
    await getData()
    console.log('resdata--------',resData)
    return resData
}
//接口按条件查询
export const uniAxSearchList = ({setSearchParams,uniRowData,specAxlist, params}:any) => {
  setSearchParams(() => params)
  uniAxList({...uniRowData,params})
}





/* 普通修改数据 */
//通用树状列表生成title 及 删除空children
export const uniModifyAxList = (itemList:any, axModify:any) => {
  let flatList:any = []
  let filterRows:any = []
  let countKey = 1
  let childrenKey = axModify ? axModify.childrenKey ? axModify.childrenKey : 'children' : null

  const modifyFunc = (list:any) => {
    list.forEach((item:any) => {
      if (axModify && axModify.custFunc) axModify.custFunc(item)
      if (axModify && axModify.funcKey) axModify.funcKey(item)
      if (axModify && axModify.autoIncreaseKey) {
        item.key = countKey
        countKey++
      }
      if (!item.label && item.name) item.label = item.name
      if (!item.key) (axModify && axModify.customKey) ? item.key = item[axModify.customKey] : item.key = item.id
      if (axModify && axModify.custom) {
        const {customkeys, conKey, sourceObj} = axModify.custom
        customkeys.forEach((customKeyItem:string) => item[customKeyItem] = sourceObj[item[conKey]] ? sourceObj[item[conKey]][customKeyItem] : null)
      }
      if (axModify && axModify.titleList) {
        let tempStr = ''
        for(let i=1; i<axModify.titleList.length; i++) {
          if (i < axModify.titleList.length -1) tempStr  += (item[axModify.titleList[i]] + ' ')
          else tempStr  += item[axModify.titleList[i]]
        }
        item[axModify.titleList[0]] = tempStr
      }
      if (axModify && axModify.extractList) uniExtractKeyToList(item, axModify.extractList)
      if (axModify && axModify.addPrefix) uniAddPrefix(item, axModify.addPrefix)
      if (axModify && axModify.flatChildren) {
        let flatObj = axModify.flatChildren
        if(flatObj.nameKey && flatObj.levelKey) item[flatObj.nameKey] = '    '.repeat(item[flatObj.levelKey]-1) + item[flatObj.nameKey]
        flatList.push(item)
      }
      if (axModify && axModify.newkeyByAddMinus) uniNewkeyByAddMinus(item,axModify.newkeyByAddMinus.newKey,axModify.newkeyByAddMinus.addKeys,axModify.newkeyByAddMinus.minuKeys)
      if (axModify && axModify.getRate) uniGetRate(item,axModify.getRate.newKey,axModify.getRate.upKey,axModify.getRate.downKey)
      if (axModify && axModify.copyKey) uniCopyKey(item, axModify.copyKey)
      if (axModify && axModify.banKeys) uniBanKeys(item, axModify.banKeys)
      if (axModify && axModify.divideKey) uniDivideKey(item, axModify.divideKey)
      if (axModify && axModify.splitTime) uniSplitTime(item,axModify.splitTime)
      if (axModify && axModify.checkStatus) uniCheckStatus(item, axModify.checkStatus)
      if (axModify && axModify.notNullColumns) uniNotNull(item,axModify.notNullColumns)
      if (axModify && axModify.filterRows && item[axModify.filterRows.key]===axModify.filterRows.value) {
        console.log('axModify.filterRows.value------', axModify.filterRows.value)
        filterRows.push(item)
      }
      if (item[childrenKey]?.length) modifyFunc(item[childrenKey])
      else delete item[childrenKey]
    })
  }
  modifyFunc(itemList)
  if (axModify && axModify.filterRows && axModify.filterRows.value !=null) return filterRows
  else if (axModify && axModify.flatChildren) {
    // console.log('modifyedData-------:', flatList)
    return flatList
  } else {
    // console.log('modifyedData--------', itemList)
    return itemList
  }
}
//通用children累加到父级
export const uniChildCalculation = (rowlist:any, keys:any) => {
  console.log('keys------------------',keys)
  const loopFunc = (list:any,level:number,parents:any) => {
    let parentList:any
    if (level === 1) parentList = []
    else parentList = parents
    list.forEach((listItem:any) => {
      if (parentList.length > 0) {
        parentList.forEach((parent:any) => {
          keys.forEach((keyitem:any)=>parent[keyitem]+=listItem[keyitem])
        })
      }
      if (listItem.children.length>0) loopFunc(listItem.children,level+1,[...parentList,listItem])
      else delete listItem.children
    })
  }
  loopFunc(rowlist,1,[])
  return rowlist
}
export const uniCustbillModifyAx = (rows:any, {isSearchCompany, reverse, rowfilter}:any) => {
  if (!isSearchCompany) {
    const mainRows:any = []
    if(reverse) rows.reverse() //有的月结账单列表总和在最后，这就需要倒序
    rows.forEach((axItem:any) => {
      axItem.billAmount = axItem.billAmount / 100
      axItem.billPrice = axItem.billPrice / 100
      axItem.hadMoneyAmount = axItem.hadMoneyAmount / 100
      axItem.shouldMoneyAmount = axItem.shouldMoneyAmount / 100
      axItem.prePayMoney = axItem.prePayMoney / 100
      axItem.restMount = axItem.shouldMoneyAmount - axItem.hadMoneyAmount
      axItem.getPayRate = Number.isNaN(axItem.hadMoneyAmount / axItem.shouldMoneyAmount) ? '0%' : ((axItem.hadMoneyAmount * 100 / axItem.shouldMoneyAmount).toFixed(2) + '%')
      if (!axItem.companyId) {
        axItem.companyName = '总账单'
        if (!rowfilter) axItem.children = []
        mainRows.push(axItem)
      } else if (!rowfilter) {
        axItem.customerNum = ''
        axItem.customerName = ''
        let mainRowItem = mainRows.find((mainItem:any) => mainItem.billMonth === axItem.billMonth && mainItem.customerId === axItem.customerId)
        mainRowItem?.children.push(axItem)
      }
    })
    rows.splice(0, rows.length)
    rows.push.apply(rows, mainRows)
  } else {
    rows.forEach((axItem:any) => {
      axItem.billAmount = axItem.billAmount / 100
      axItem.billPrice = axItem.billPrice / 100
      axItem.hadMoneyAmount = axItem.hadMoneyAmount / 100
      axItem.shouldMoneyAmount = axItem.shouldMoneyAmount / 100
      axItem.prePayMoney = axItem.prePayMoney / 100
      axItem.restMount = axItem.shouldMoneyAmount - axItem.hadMoneyAmount
      axItem.getPayRate = Number.isNaN(axItem.hadMoneyAmount / axItem.shouldMoneyAmount) ? '0%' : ((axItem.hadMoneyAmount * 100 / axItem.shouldMoneyAmount).toFixed(2) + '%')
    })
  }
}
//编辑部门列表
export const uniModifyDpList = (dpList:DpItem[], parentId='-1') => {
  let sortCount = 0
  dpList.map(item => {
    item.sort = sortCount
    item.key = item.id
    item.parentId = parentId
    item.label = item.label ? item.label : item.name
    sortCount++
    if (item.children?.length) uniModifyDpList(item.children, item.id)
    else delete item.children
  })
}

//将对象列表中每个item的某个字段提取成新列表,比如 list = [{extractkey1:[{}, {}], extractkey2:[{}, {}]}, {extractkey1:[{}, {}], extractkey2:[{}, {}]}]
export const uniExtractKeyToList = (listItem:any, extractList:any) => {
  extractList.forEach((extractItem:any) => {
      listItem[extractItem.key+'List'] = ''
      listItem[extractItem.key].forEach((subItem:any, index:any) => {
        if (index < listItem[extractItem.key].length - 1) listItem[extractItem.key+'List'] += (subItem[extractItem.value] + ',')
        else listItem[extractItem.key+'List'] += subItem[extractItem.value]
      })
    })
}
//给某个字段加前缀，返回新字段
export const uniAddPrefix = (listItem:any, prefixList:any) => {
  prefixList.forEach((prefixItem:any) => {
    listItem[prefixItem['newKey']] = prefixItem['prefix'] + listItem[prefixItem['key']]
  })
}
//根据某个字段的状态，添加中文名，返回新字段
export const uniCheckStatus = (listItem:any, checkStatusList:any) => {
  checkStatusList.forEach(({newKey, checkKey, sourceList, sourceCheckKey, sourceValueKey}:any) => {
    let sourceItem:any = uniFilterItem(sourceList, sourceCheckKey, listItem[checkKey])
    if (sourceItem) {
      if (Object.prototype.toString.call(sourceValueKey) === '[object Array]') {
        let tempStr = ''
        sourceValueKey.forEach((key:string) => tempStr = tempStr + sourceItem[key]+' ')
        listItem[newKey] = tempStr
      } else listItem[newKey] = sourceItem[sourceValueKey]
    }
  })
}
//某个字段除以某个数
export const uniDivideKey = (listItem:any, divideList:any) => {
  divideList.forEach(({newKey, key, value}:any) => {
    if (!isNaN(listItem[newKey])) listItem[newKey] = listItem[key] / value
  })
}
//将某个字段按条件赋值为另一字段
export const uniCopyKey = (listItem:any, copyList:any) => {
  copyList.forEach(({newKey, checkKey, checkInfo}:any)=> {
    if (Object.prototype.toString.call(checkInfo) === '[object Array]') {
      let checkInfoItem:any = uniFilterItem(checkInfo, 'value', listItem[checkKey])
      listItem[newKey] = checkInfoItem ? listItem[checkInfoItem.copyKey] : null
    } else listItem[newKey] = checkInfo(listItem)
  })
}
//树状列表中，禁用某些叶子节点
export const uniBanKeys = (listItem:any, banKeyList:any) => {
  if (banKeyList.indexOf(listItem.id) !== -1) listItem.disabled = true
}
//为null的字段赋值为''
export const uniNotNull = (listItem:any, columns:any) => {
  columns.forEach((column:any) => {
    if(listItem[column.dataIndex] !== null && listItem[column.dataIndex] !== undefined && !Number.isNaN(listItem[column.dataIndex])) {}
    else listItem[column.dataIndex] = ''
  })
}
//分割时间
export const uniSplitTime = (listItem:any, times:any) => {
  times.forEach((timekey:string) => listItem[timekey] = listItem[timekey] ? listItem[timekey].split(' ')[0] : '')
}
//通用加减运算
export const uniNewkeyByAddMinus = (item:any, newKey:string, addKeys=[], minuKeys=[]) => {
  let addResult = 0
  let minuResult = 0
  addKeys.forEach((addKey:string) => addResult += item[addKey])
  minuKeys.forEach((minuKey:string) => minuResult += item[minuKey])
  item[newKey] = addResult - minuResult
}
//通用获取百分比
export const uniGetRate = (item:any, newKey:string, upKey:string, downKey:string) => {
  item[newKey] = Number.isNaN(item[upKey] / item[downKey]) ? '0%' : ((item[upKey] * 100 / item[downKey]).toFixed(2) + '%')
}

//点击修改row
export const uniModifyRow = ({setCurrentRow, setChgModal, setCustomAddKeys, record, initCustom}:any) => {
  if (setCustomAddKeys) setCustomAddKeys(()=>({...initCustom}))
  setCurrentRow(() => record)
  setChgModal(() => true)
}
//公司账户管理界面点击修改行
export const companyAccountModifyRow = (data:any) => {
  if (data.record.accountType === 1) data.setShowCashAccount(() => false)
  if (data.record.bankType == '支付宝' || data.record.accountType == '微信') data.setModalItemVisible(() => ({...data.modalItemVisible, showRealName:false}))
  else if (data.record.bankType == '现金账户') data.setModalItemVisible(() => ({...data.modalItemVisible, showRealName:false, showCardNo:false}))
  else if (data.record.bankType == '其他') data.setModalItemVisible(() => ({showRealName:false, showCardNo:false, showBeginBalance:false, showBeginTime:false, showSubject:true}))
  uniModifyRow(data)
}

/* 普通查找方法 */
//根据id找到对应的item
export const uniFilterItem = (list:any, listKey:string, id:any, filters:any=[],needstr=false) => {
  let tempObj = null
  const filterItem = (list:any[], listKey:string, id:any,filterlist:any) => {
    list.find((item:any) => {
      if (filters.length > 0) {
        let result = filterlist.every((filteritem:any)=>{
          let comparevalue = needstr ? item[filteritem.key].toString() : item[filteritem.key]
          return comparevalue==filteritem.value
        })
        if (result) return tempObj = item
        else if (item.children) filterItem(item.children, listKey, id, filterlist)
        else return false
      } else {
        if (item[listKey] == id) return tempObj = item
        else if (item.children) filterItem(item.children, listKey, id, filterlist)
        else return false
      }
    })
  }
  filterItem(list, listKey, id, filters)
  return tempObj
}
//根据id找到对应的item
export const uniFilterWithPa = (initlist:any, id:any, listKey='id') => {
  let parentinfos:any = []
  let tempObj = null
  let findflag = false
  const filterItem = (list:any[],parentinfo:any,listKey:string, id:any) => {
    if (findflag) return
    parentinfos.push(parentinfo)
    for (let i=0; i<list.length; i++) {
      if (list[i][listKey] == id) {
        findflag = true
        return tempObj = list[i]
      }
      else if (list[i].children && list[i].children.length > 0 && !findflag) filterItem(list[i].children,{parent:list[i],isend:i===list.length-1},listKey, id)
      else if (!findflag && i === list.length-1 && (!list[i].children || list[i].children.length <= 0)) {
        let popitem = parentinfos.pop()
        let initcount = parentinfos.length
        for (let j = initcount - 1; j >= 0; j--) {
          if (popitem.isend) popitem = parentinfos.pop()
          else return
        }
      }
    }
  }
  filterItem(initlist,{parent:{},isend:true},listKey, id)
  return {findItem: tempObj, parents:parentinfos.map((item:any)=>item.parent).slice(1)}
}


/* 其他方法 */
//选中列的相应方法
export const uniSelectRows = (setSelectedRowKeys:Function, setSelectedRows:any=null) => ({
  onChange: (selectedRowKeys: any, selectedRows: any) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    setSelectedRowKeys(() => selectedRowKeys)
    if (setSelectedRows) setSelectedRows(() => selectedRows)
  },
  onSelect: (record: any, selected: any, selectedRows: any) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
    console.log(selected, selectedRows, changeRows);
  }
})

// modal退出
export const uniQuitModal = (setModal:Function) => {
  return (status:boolean) => setModal(() => status)
}

//单选时间选择器
export const uniDatePikcerChange = (data:any) => {
  console.log('datastring', data.dataString)
  data.formRef.current?.setFieldsValue({[data.keyWord]: data.dateString})
}
//修改form中的字段
export const uniSetFormField = ({formRef, keyWord, value, isArray=false, arrayKey, arrayIndex}:any) => {
  if (isArray) {
    let arrayRows = formRef.current?.getFieldValue(arrayKey)
    if (arrayRows[arrayIndex]) arrayRows[arrayIndex][keyWord] = value
    formRef.current?.setFieldsValue({[arrayKey]:arrayRows})
  } else formRef.current?.setFieldsValue({[keyWord]: value})
}

//点击资产按钮
export const uniAssetButton = ({setActiveButton, subjectType, tempSearchParams, searchParams, setSearchParams, uniAxData}:any) => {
  setActiveButton(() => subjectType)
  tempSearchParams.filter_EQL_subject_type = subjectType
  setSearchParams(() => {
    uniAxList({...uniAxData,  params: {...searchParams, filter_EQL_subject_type: subjectType || null}})
    return {...searchParams, filter_EQL_subject_type: subjectType || null}
  })
}

//点击右下角页码
export const uniTableChange = ({uniAxData, searchParams, setTableParams,newPagination:newPagination,filters,sorter}:any) => {
  setTableParams(() => ({pagination:newPagination,filters,...sorter}))
  uniAxList({...uniAxData, params:{...searchParams,current: newPagination.current, size:newPagination.pageSize}})
}

//通用确认收款/付款
/* export const uniConfirmMoney = ({selectedRows, rowList, itemKeys, checkKey, customKeys, confirmApi, uniAxData}:any) => {
  console.log('selectedrows', selectedRows)
  let promiseArray:Function[] = []
  rowList.forEach((rowItem:any) => {
    let paramObj:any = {}
    itemKeys.forEach((keyTtem:string) => paramObj[keyTtem]=rowItem[keyTtem])
    if (!rowItem[checkKey]) promiseArray.push(confirmApi({...paramObj, ...customKeys}))
  })
  Promise.all(promiseArray).then(res => uniAxList(uniAxData))
} */

//根据时间范围获取rows
export const uniRangeRows = ({beginString, endString, checkKey, rowList, setSelectedRows, setForm}:any) => {
  const beginTime = (new Date(beginString)).getTime()
  const endTime = (new Date(endString)).getTime()
  const tempItems:any = []
  rowList.forEach((item:any) => {
    let itemTime = (new Date(item[checkKey])).getTime()
    if (itemTime >= beginTime && itemTime <= endTime) tempItems.push(item)
  })
  setSelectedRows(() => {
    if (setForm) setForm(tempItems)
    return tempItems
  })
}
//点击确认收款按钮
export const uniConfirm = ({selectedRows, setModal, setUnConfirmRows, key, value=[0]}:any) => {
  if (!selectedRows.length) message.warning('请选择至少一条记录')
  else {
    let tempRows:any = []
    selectedRows.forEach((item:any) => {
      if ((key && value.indexOf(item[key]) !== -1) || !key) tempRows.push(item)
    })
    if (tempRows.length > 0) {
      setUnConfirmRows(() => {
        if (setModal) setModal(() => true)
        return tempRows
      })
    } else message.warning('没有合适的操作记录')
  }
}

//数据统计-从api获取统计值
export const uniApiStatic = async ({axApi,params,modifyFunc,modifyData,setStaticLoading,setStaticModal, setStaticTableData}:any) => {
  setStaticModal(() => true)
  if (setStaticLoading) setStaticLoading(()=>true)
  let res = await axApi(params)
  let staticRows:any
  if (modifyFunc) staticRows = modifyFunc(res.data.records || res.data, modifyData)
  else  staticRows = res.data.records || res.data
  setStaticTableData(() => staticRows)
  if (setStaticLoading) setStaticLoading(()=>false)
}
//数据统计-自己计算
export const uniStaticLast = async ({rowList, axApi, params, modifyFunc,setStaticLoading,rowfilters, modifyData, staticModify,staticModifyData, staticColumns, staticRows, setStaticModal, setStaticTableData}:any) => {
  setStaticModal(() => true)
  if (setStaticLoading) setStaticLoading(()=>true)
  //获取统计数据
  let sourceData
  if (rowList) {
    if (staticModify) sourceData = staticModify(rowList, staticModifyData)
    else sourceData = rowList
  } else if (axApi) {
    let res = await axApi(params)
    if (modifyFunc) sourceData = modifyFunc(res.data.records || res.data, modifyData)
    else  sourceData = res.data.records || res.data
  }
  //找到总的统计行
  const totalRow = staticRows.find((staticRowItem:any) => staticRowItem.checkKey === 'staticTotal')
  //遍历元数据行
  sourceData.forEach((rowListItem:any) => {
    let findItem = true
    if (rowfilters && rowfilters.find((filterItem:any)=>rowListItem[filterItem.key] !== filterItem.value)) findItem = false
    if (findItem) {
      //找到要添加的统计行
      let staticRow:any
      if (staticRows.length === 1) staticRow = staticRows[0]
      else staticRow = staticRows.find((staticRowItem:any) => rowListItem[staticRowItem.checkKey] === staticRowItem.checkValue)
      //遍历统计列
      staticColumns.forEach((staticColumnItem:any) => {
        if (staticColumnItem.addValue) {
          let addvalue = staticColumnItem.addValue(rowListItem,staticRow.children)
          let totalAdd = staticColumnItem.totalAdd ? staticColumnItem.totalAdd(rowListItem) : addvalue
          if(staticRows.length > 1) staticRow[staticColumnItem.dataIndex] += addvalue
          if(totalRow) totalRow[staticColumnItem.dataIndex] += totalAdd
        } else if (staticColumnItem.addKey) { //简单相加的统计列
          //将元数据加到对应统计行
          if (staticRow && staticRow[staticColumnItem.dataIndex] !==null) {
            if ((staticColumnItem.checkInfo && staticColumnItem.checkInfo.value === rowListItem[staticColumnItem.checkInfo.checkKey]) || !staticColumnItem.checkInfo) {
              staticRow[staticColumnItem.dataIndex] += rowListItem[staticColumnItem.addKey]
              if (staticRows.length>1 && totalRow && totalRow[staticColumnItem.dataIndex] !==null) totalRow[staticColumnItem.dataIndex] += rowListItem[staticColumnItem.addKey]
            }
          }
        } else if (staticColumnItem.multiKey) { //处理多行统计
          let multiResult = 1
          let addResult = 0
          if (staticColumnItem.operate === 'multi') { //多行数据相乘
            staticColumnItem.multiKey.forEach((keyItem:string) => multiResult *= rowListItem[keyItem])
            if (staticRow && staticRow[staticColumnItem.dataIndex] !==null) staticRow[staticColumnItem.dataIndex] += multiResult
            if (totalRow && totalRow[staticColumnItem.dataIndex] !==null) totalRow[staticColumnItem.dataIndex] += multiResult
          } else if (staticColumnItem.operate === 'add') { //多行相加
            staticColumnItem.multiKey.forEach((keyItem:string) => addResult += rowListItem[keyItem])
            if (staticRow && staticRow[staticColumnItem.dataIndex] !==null) staticRow[staticColumnItem.dataIndex] += addResult
            if (totalRow && totalRow[staticColumnItem.dataIndex] !==null) totalRow[staticColumnItem.dataIndex] += addResult
          }
        } else if (staticColumnItem.addMinus) { //处理混合加减
          let result = 0
          staticColumnItem.addMinus.addKeys.forEach((keyItem:string) => result += rowListItem[keyItem])
          staticColumnItem.addMinus.minusKeys.forEach((keyItem:string) => result -= rowListItem[keyItem])
          if (staticRow && staticRow[staticColumnItem.dataIndex] !==null) staticRow[staticColumnItem.dataIndex] += result
          if (totalRow && totalRow[staticColumnItem.dataIndex] !==null) totalRow[staticColumnItem.dataIndex] += result
        }
      })
    }
  })
  setStaticTableData(() => staticRows)
  if (setStaticLoading) setStaticLoading(()=>false)
}

//点击作废按钮
export const uniCancel = ({selectedRows,setCancelModal}:any) => {
  if (selectedRows.length > 0) setCancelModal(() => true)
  else message.warning('请选择至少一条记录!')
}

//作废modal确认
export const uniCancelConfirm = ({selectedRows,cancelApi,cancelRemark,axData,setCancelModal,setModalLoading,isArray}:any) => {
  setModalLoading(()=>true)
  if (isArray) {
    cancelApi(selectedRows.map((item:any)=>({id: item.id, cancelRemark}))).then((res:any) => {
      message.success('已成功作废' + selectedRows.length + '条记录')
      setModalLoading(()=>false)
      setCancelModal(() => false)
      uniAxList({...axData})
    })
  } else {
    let axCancelFuncs:Function[] = []
    selectedRows.forEach((item:any) => axCancelFuncs.push(cancelApi({id: item.id, cancelRemark})))
    Promise.all(axCancelFuncs).then(res => {
      message.success('已成功作废' + selectedRows.length + '条记录')
      setModalLoading(()=>false)
      setCancelModal(() => false)
      uniAxList({...axData})
    })
  }
}
//点击批量操作
export const uniOnBatch = ({confirmKey,confirmValue,selectedRows,setBatchRows,setModal}:any) => {
  let operateRows:any = []
  selectedRows.forEach((item:any)=>{
    if (item[confirmKey] !== confirmValue) operateRows.push(item)
  })
  if (operateRows.length === 0) return message.info('全部已操作完成!')
  else setModal(() => true)
  setBatchRows(()=>operateRows)
}
//确认批量操作
export const uniBatchConfirm = ({batchRows,batchData,axiApi,isFinishLoading=true,formValues,uniRowData,setModal,setModalLoading,awaitFunc,isArray,isPureArray}:any) => {
  if(setModalLoading) setModalLoading(()=>true)
  if (isArray) {
    let axarray
    if (isPureArray) axarray = batchRows.map((item:any)=>item.id)
    else axarray = batchRows.map((item:any)=>({id: item.id, ...formValues}))
    axiApi(axarray).then((res:any) => {
      message.success('成功操作' + batchRows.length + '条记录')
      if(setModalLoading && isFinishLoading) setModalLoading(()=>false)
      if(setModal) setModal(() => false)
      uniAxList({...uniRowData})
      if (awaitFunc) awaitFunc()
    }).catch((e:any)=>setModalLoading(()=>false))
  } else {
    let axCancelFuncs:Function[] = []
    let axlength=0
    if (batchRows) {
      axlength = batchRows.length
      batchRows.forEach((item:any) => {
        if(item.id) axCancelFuncs.push(axiApi({id: item.id, ...formValues}))
      })
    }
    else if (batchData) {
      axlength = batchData.length
      batchData.forEach((item:any) => axCancelFuncs.push(axiApi({...item})))
    }
    Promise.all(axCancelFuncs).then(res => {
      // message.success('成功操作' + axlength + '条记录')
      message.success('操作成功')
      if(setModalLoading && isFinishLoading) setModalLoading(()=>false)
      if(setModal) setModal(() => false)
      uniAxList({...uniRowData})
      if (awaitFunc) awaitFunc()
    }).catch((e:any)=>setModalLoading(()=>false))
  }
}
//查看收款记录
export const uniOnLog = ({selectedRows,setLogModal,uniLogData}:any) => {
  if (selectedRows.length !== 1) message.warning('需要且仅能选择一条数据!')
  else {
    if (selectedRows[0].parentId !== 0) message.warning('请选择总账单')
    else {
      setLogModal(() => true)
      uniAxList(uniLogData)
    }
  }
}
//查看记账凭证
export const uniToProof = async (record:any,proofId:any,navigate:any,setLogModal=null as any,type='default') => {
  let finditem
  let treeList = getLocalItem('userMenu',[])
  let findintel
  if (treeList.length > 0) findintel = treeList.find((item:any)=>item.code === "PC_INTELLIGENCE_FINANCE")
  let findvoucher
  if (findintel && findintel.children.length>0) findvoucher = findintel.children.find((item:any)=>item.code === "PC_ACCOUNTING_VOUCHER")
  if (!findvoucher) return
  if(setLogModal) setLogModal(()=>false)
  let detail = await uniAxList({params: {id:proofId}, axApi: axProofdetail})
  if (!detail) return message.error('凭证未找到')
  navigate('/indexpage/recordvoucher', {replace: true, state: {record,proofId,type, detail}})
}

//打印表格
export const uniPrintTable = (tableRef:any) => {
      const newWin:any = window.open("");//新打开一个空窗口
      newWin.document.write(tableRef.outerHTML);//将表格添加进新的窗口
      newWin.document.close();//在IE浏览器中使用必须添加这一句
      newWin.focus();//在IE浏览器中使用必须添加这一句

      newWin.print();//打印
      newWin.close();//关闭窗口
}
// export const uniDoPrint = () => {
//   const bdhtml = window.document.body.innerHTML;
//   const sprnstr="/* endprint */"; //开始打印标识字符串有17个字符
//   const eprnstr="/* endprint */"; //结束打印标识字符串
//   let prnhtml = bdhtml.substr(bdhtml.indexOf(sprnstr)+14); //从开始打印标识之后的内容
//   prnhtml=prnhtml.substring(0,prnhtml.indexOf(eprnstr)); //截取开始标识和结束标识之间的内容
//   window.document.body.innerHTML=prnhtml; //把需要打印的指定内容赋给body.innerHTML
//   window.print(); //调用浏览器的打印功能打印指定区域
//   window.document.body.innerHTML=bdhtml; // 最后还原页面
// }
export const uniDoPrint = (tableRef:any) => {
  console.log('tableref', tableRef)
  const newWin:any = window.open('')
  newWin.document.write(tableRef.current.outerHTML);
  newWin.focus()
  newWin.print()
  newWin.close()
  window.focus()
}

//打印表格数据
// 自动计算col列宽
const auto_width = (ws:any, data:any) => {
  /*set worksheet max width per col*/
  const colWidth = data.map((row:any) => row.map((val:any) => {
    /*if null/undefined*/
    if (val == null) return { 'wch': 10 }
    /*if chinese*/
    else if (val.toString().charCodeAt(0) > 255) return { 'wch': val.toString().length * 2 }
    else return { 'wch': val.toString().length }
    
  }))
  /*start in the first row*/
  let result = colWidth[0]
  for (let i = 1; i < colWidth.length; i++) {
    for (let j = 0; j < colWidth[i].length; j++) {
      if (result[j]['wch'] < colWidth[i][j]['wch']) {
        result[j]['wch'] = colWidth[i][j]['wch']
      }
    }
  }
  ws['!cols'] = result
}

// 将json数据转换成数组
const json_to_array = (key:any, jsonData:any) => {
  return jsonData.map((v:any) => key.map((j:any) => v[j]))
}

/**
 * @param header Object，表头
 * @param data Array，表体数据
 * @param key Array，字段名
 * @param title String，标题（会居中显示），即excel表格第一行
 * @param filename String，文件名
 * @param autoWidth Boolean，是否自动根据key自定义列宽度
 */
//自定义下载文件方式
export const uniDownExcel = (obj:any,fileName:string) => {
  const a_node = document.createElement('a');
  a_node.download = fileName;
  a_node.href = URL.createObjectURL(obj);
  a_node.click();
  URL.revokeObjectURL(obj);
}
//文件六转换
export const uniS2ab:any = (s:any) => {
  if (typeof ArrayBuffer !== 'undefined') {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i=0;i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  } else {
    const buf = new Array(s.length);
    for (let i = 0; i !== s.length; ++i) buf[i] = s.charCodeAt(i) & 0xff
    return buf
  }
}
//通过某个字段从列表中获取其他字段数组
export const uniGetKeybykeyFromlist = (list:any, targetKey:any, sourceKey:any, sourceValue:any) => {
  const resultList:any = []
  const getKey = (list:any) => {
    list.forEach((item:any) => {
      if (item[sourceKey] === sourceValue) resultList.push(item[targetKey])
      if (item.children) getKey(item.children)
    })
  }
  getKey(list)
  return resultList;
}

//删除数组的某个元素
export const uniRemoveItem = (list:any, value:any) => {
  let index = list.indexOf(value)
  list.splice(index,1)
}
//删除list中的关键字和值
export const uniDeleteKeyvalue = ({rowList, keys}:any) => {
  console.log('deletekeys-----------',keys)
  rowList.forEach((rowItem:any) => {
    keys.forEach((keyItem:string) => Reflect.deleteProperty(rowItem, keyItem))
    if(rowItem.children) uniDeleteKeyvalue({rowList:rowItem.children, keys})
  })
  console.log('delete rowlist------', rowList)
}
//通用导出函数
export const uniExportExcel = async ({setExportLoading,custtitles,titleMergeList,titlecounts=1,sumFunc,columns,mergColindexs,twoHeader=false,modifyFunc,rowkeyIndex=0, rowList,custDeleteKeys=[], title, axApi, fileName, modifyData,delProps, columnws, tableParams={},deleteChildren=true,showSummary,sumProps}:any) => {
  if (setExportLoading) setExportLoading(true)
  let effectcols = columns.filter((item:any)=>!(item.type && item.type==='operation'))
  const headerTop:any = []
  const header:any = {}
  const headmerge:any = []
  const flatcols:any = []  //把column的children拿出来，合计时用到
  let colcount = 0
  //{s:{r:0,c:0},e:{r:0,c:key.length-1}}
  const key:string[] = []
  //获取header和key
  let headerindex = 0
  effectcols.forEach((colitem:any,index:number) => {
    if (twoHeader && colitem.children) {
      headerTop.push({title:colitem.title,colAlpha:csAlphabets[headerindex],colindex:headerindex,span:colitem.children.length})
      colitem.children.forEach((subitem:any)=>{
        flatcols.push(subitem)
        header[subitem.key] = subitem.title
        key.push(subitem.key)
        headerindex++
      })
    } else {
      if (twoHeader) flatcols.push(colitem)
      header[colitem.key] = colitem.title
      key.push(colitem.key)
      headerindex++
    }
  })
  const filename:string = fileName
  const autoWidth:boolean = false

  //获取分页的全部数据，并删除多余关键字
  let tempList:any = []
  let rowStartindex = twoHeader?(2+titlecounts):(1+titlecounts)
  let rowMergeList:any = []
  if (rowList) tempList = rowList
  else if (axApi) tempList = await uniAxList({axApi,params:{...tableParams,page:null}})
  let custColumObj:any = {}
  let dataSource:any = []
  let editcolus = twoHeader ? flatcols : effectcols
  editcolus.forEach((item:any)=>{if (item.custval || item.type==='price') custColumObj[item.key] = item})
  const loopPush = (list:any,ischildren?:boolean) => {
    list.forEach((item:any,index:number)=>{
      if (mergColindexs && item.mergeFlag) {
        mergColindexs.forEach((mindex:any)=>rowMergeList.push({s:{r:index+rowStartindex,c:mindex},e:{r:index+rowStartindex+item.span-1,c:mindex}}))
      }
      let tempobj:any = {}
      key.forEach((keyitem:any,index:number)=>{
        if (custColumObj[keyitem] && custColumObj[keyitem].custval) tempobj[keyitem] = custColumObj[keyitem].custval({row:item,value:item[keyitem]})
        else if (custColumObj[keyitem] && custColumObj[keyitem].type==='price') tempobj[keyitem] = Number((item[keyitem] / 100).toFixed(2))
        else tempobj[keyitem] = item[keyitem]
        if (ischildren && index===0) tempobj[keyitem] = ' '+tempobj[keyitem]
      })
      dataSource.push(tempobj)
      if (item.children) loopPush(item.children,true)
    })
  }
  loopPush(tempList)
  if (showSummary) {
    let tempobj:any = {}
    tempobj[effectcols[0].key] = '合计'
    let sumcolus = twoHeader ? flatcols : effectcols
    sumcolus.forEach((citem:any)=>{
      if (sumProps.includes(citem.key)) {
        let temptotal = 0
        if (sumFunc) temptotal = sumFunc(tempList,citem)
        else temptotal = tempList.reduce((total:number,ritem:any)=>total+(ritem[citem.key] || 0),0)
        tempobj[citem.key] = citem.type==='price' ? Number((temptotal/100).toFixed(2)) :Number(temptotal.toFixed(2))
      }
    })
    dataSource.push(tempobj)
  }
  uniExportToExcel({header,headerTop,twoHeader,headmerge,titleMergeList,custtitles,titlecounts,rowMergeList, key, title, filename, autoWidth, columnws, data: dataSource})
  if (setExportLoading) setExportLoading(false)
}
export const uniExportToExcel = ({header,headerTop,twoHeader,custtitles,titleMergeList,titlecounts,rowMergeList,data,key,title,filename,columnws,autoWidth}:any) => {
  // let {utils, writeFile} = XLSX
  const wb = utils.book_new()
  if (header) data.unshift(header)
  if (twoHeader) data.unshift({})
  for (let i=0;i<titlecounts;i++) data.unshift({})
  const ws = utils.json_to_sheet(data, {
    header: key,
    skipHeader: true,
  })
  ws['A1'] = {t:'s',v:title,s:{
    font:{sz:20,bold:true},
    alignment:{horizontal:'center',vertical:'center'}
  }}
  if (custtitles) {
    let tempkeys = Object.keys(custtitles)
    tempkeys.forEach((key:any)=>ws[key]=custtitles[key])
  }
  for (let i = 0; i<key.length; i++) {
    if (twoHeader) ws[csAlphabets[i]+(2+titlecounts)] = {t:'s',v:header[key[i]],s:{font:{bold:true},alignment:{horizontal:'center',vertical:'center'}}}
    else ws[csAlphabets[i]+(1+titlecounts)] = {t:'s',v:header[key[i]],s:{font:{bold:true},alignment:{horizontal:'center',vertical:'center'}}}
  }
  let temp = [{s:{r:0,c:0},e:{r:0,c:key.length-1}}]
  if (twoHeader) headerTop.forEach((hitem:any)=>{
    ws[hitem.colAlpha+(1+titlecounts)] = {t:'s',v:hitem.title,s:{font:{bold:true},alignment:{horizontal:'center',vertical:'center'}}}
    temp.push({s:{r:titlecounts,c:hitem.colindex},e:{r:titlecounts,c: hitem.colindex + hitem.span - 1}})
  })
  ws['!merges'] = temp
  ws['!merges'].push(...rowMergeList)
  if (titleMergeList) ws['!merges'].push(...titleMergeList)
  // if (twoHeader) ws['!merges'].push(...headmerge)
  if (columnws) ws["!cols"] = columnws
  else {
    let temparr:any = []
    for (let i=0;i<key.length;i++) {
      temparr.push({wch: 10})
    }
    ws["!cols"] = temparr
  }
  if (autoWidth) {
    const arr = json_to_array(key, data)
    auto_width(ws, arr)
  }

  utils.book_append_sheet(wb, ws, filename)
  // return wb_out
  //使用xlsx-style写入文件方式，是的自定义样式生效
  const tmpDown = new Blob([
    uniS2ab(write(wb,{
      bookType:'xlsx',
      bookSST:true,
      type:'binary',
      cellStyles:true
    }))
  ])
  uniDownExcel(tmpDown, filename+uniCurrentTime({})+'.xlsx')
}
//通用导出table
export const uniExportTable = (tableId:any,fileName:string) => {
  let tableHtml = document.querySelector(tableId)
  console.log('tablehtml-----------',tableHtml)
  // let {utils, write} = XLSX
  // 导出的内容只做解析，不进行格式转换
  let xlsxParam = {raw: true};
  //#taskTable为我们表格的ID
  let wb = utils.table_to_book(document.querySelector(tableId), xlsxParam);
  //下面代码阐明了保存的类型为什么
  const wbout = write(wb, {
    bookType: "xlsx",
    bookSST: true,
    type: "array"
  })
  try {
    FileSaver.saveAs(new Blob([wbout], {type: "application/octet-stream"}), fileName+'.xlsx');
  } catch (e) {
      if (typeof console !== "undefined") console.log(e, wbout);
  }
  return wbout
}
//aes加密
export function uniAesEncrypt(data:any){
	const key  = enc.Utf8.parse("f80bc425f5801c0c") //Latin1 w8m31+Yy/Nw6thPsMpO5fg==
  const iv = enc.Utf8.parse("xbgp#6465asdqw14")
	const srcs = enc.Utf8.parse(JSON.stringify(data));
	const encrypted = AES.encrypt(srcs, key, {iv, mode:mode.CBC, padding:pad.PKCS7});
	return encrypted.toString()
}
//手动编码url，已传递空格
/* export const uniEncodeURIParams = (config:any) => {
  let url = config.url
  url += '?'
  const keys = Object.keys(config.params)
  for (const key of keys) {
    url += `${key}=${encodeURIComponent(config.params[key])}&`
  }
  url = url.substring(0, url.length - 1)
  config.params = {}
  return url
} */
//从某个列表中获取某个字段的最大值
export const uniGetMaxFromListkey = ({rowList, key, omitHead}: any) => {
  let max = 0
  rowList.forEach((item:any) => {
    let getKeyNum = 0
    if (omitHead) getKeyNum = Number.parseInt(item[key].slice(omitHead.length))
    else getKeyNum = Number.parseInt(item[key])
    if (getKeyNum > max) max = getKeyNum
  })
  return max
}
//手机号校验
export const uniPhoneValidator = (value:any) => {
  if (value) {
    if((/^1[3456789]\d{9}$/.test(value))) return Promise.resolve()
    else return Promise.reject('手机号格式不正确')
  } else return Promise.resolve()
}
//身份证校验
export const uniIdNumValidator = (value:any) => {
  if (value) {
    if((/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value))) return Promise.resolve()
    else return Promise.reject('身份证格式不正确')
  } else return Promise.resolve()
}
//数组深拷贝 - 嵌套对象
export const uniDeepArray = (arrlist:any) => {
  let newList:any = []
  const deepClone = (obj:any, newObjarr:any) => {
    let newObj = newObjarr || {};
    for (let key in obj) {
        if (obj[key] && typeof obj[key] == 'object') {
            // console.log('obj[key]--------', obj[key])
            newObj[key] = (obj[key].constructor === Array) ? [] : {}
            deepClone(obj[key], newObj[key]);
        } else {
            newObj[key] = obj[key]
        }
    }
    return newObj;
  }
  arrlist.forEach((arrItem:any)=>{
    let newObj = {};
    newList.push(deepClone(arrItem,newObj))
  })
  return newList
}
//生成报表
export const uniCreateReport = ({setReportModal, axApi, formValus}:any) => {
  setReportModal(() => false)
  axApi(formValus).then((res:any) => {
    if (res && res.message && res.message.toLowerCase() === 'success') message.success('生成报表成功')
  })
}
//文件上传
export const uniHandleUpload = ({fileList,axUploadApi}:any) => new Promise((resolve, reject)=>{
  let axFuncs:Function[] = []
  fileList.forEach((item:any,index:number)=>{
    let formData = new FormData();
    formData.append('file', item)
    axFuncs.push(axUploadApi(formData))
  })
  Promise.all(axFuncs).then(res => {
    resolve(res)
  })
})
//下载文件
export const uniDownloadFile = (data:any) => {
  fetch(data.url, {
      method: "get",
      mode: "cors",
    })
    .then((response) => response.blob())
    .then((res) => {
      const downloadUrl = window.URL.createObjectURL(
        //new Blob() 对后端返回文件流类型处理
        new Blob([res], {
          type: data.type == "pdf" ? "application/pdf" : data.type == "word" ?
            "application/msword" : data.type == "xlsx" ? "application/vnd.ms-excel" : ""
        })
      );
      //word文档为msword,pdf文档为pdf
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", data.name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }).catch((error) => {
      window.open(data.url);
    });
};
//----------------------------------------优化--------------------------------
//列表返回key值的名称
export const uniValueToName = ({list,value, propId='id',propName='name'}:{list:any[],value:any,propId?:string,propName?:string}) => {
  let findItem = list.find((item:any)=>item[propId] === value)
  return findItem ? findItem[propName] : value
}
//通用操作警告
export const uniOpAlarm = async (action:any,content='是否确认此操作') => {
  let modal:any = Modal
  modal.confirm({
    title: '提示',
    content,
    onOk: action,
    footer: (_:any, { OkBtn, CancelBtn }:any) => <><ConfigProvider locale={locale}><CancelBtn /><OkBtn/></ConfigProvider></>,
  });
}
//通用from数据处理
export const uniFormProcess = async (formValues:any,formItems:any,fileList?:any[]) => {
  let tempobj:any = {}
  for (let key in formValues) {
    let finditem = formItems.find((item:any)=>item.prop===key)
    if (formValues[key] && finditem && finditem.type==='price') tempobj[key] = Number((formValues[key] * 100).toFixed(0))
    else if (formValues[key] && finditem && finditem.type==='select' && finditem.selectProps && finditem.selectProps.requireName) {
      tempobj[key] = formValues[key]
      let findsubitem = finditem.dataList.find((listitem:any)=>listitem[finditem.selectProps?.propId || 'id']===formValues[key])
      if (findsubitem) tempobj[finditem.selectProps.requireName] = findsubitem[finditem.selectProps?.propName || 'name']
    }
    else if (formValues[key] && finditem && finditem.type==='tree' && finditem.treeProps && finditem.treeProps.requireName) {
      tempobj[key] = formValues[key]
      let tempKey = 'id'
      if (finditem.treeProps && finditem.treeProps.propId) tempKey = finditem.treeProps.propId
      let findsubitem = uniFilterItem(finditem.dataList,tempKey,formValues[key])
      if (findsubitem) tempobj[finditem.treeProps.requireName] = findsubitem[finditem.treeProps?.propName || 'name']
    }
    else if (formValues[key] && finditem && finditem.type==='datePicker') {
      if (finditem.datepickerProps?.type==='month') tempobj[key] = moment(formValues[key]).format('YYYY-MM')
      else {
        if (finditem.datepickerProps?.returnType==='string') tempobj[key] = moment(formValues[key]).format('YYYY-MM-DD')
        else tempobj[key] = moment(formValues[key]).format('x')
      }
    }
    else if (formValues[key] && formValues[key].length>0 && finditem && finditem.type==='dateRange') {
      let ismonth = finditem.daterangeProps && finditem.daterangeProps.type==='month' ? true :false
      let nosuffix = ismonth || (finditem.daterangeProps && finditem.daterangeProps.nosuffix)
      let formstr = ismonth ? 'YYYY-MM' : 'YYYY-MM-DD'
      if (finditem.daterangeProps && finditem.daterangeProps.returnType==='string') {
        if (!finditem.daterangeProps.rangeKeys) tempobj[key] = moment(formValues[key][0]).format(formstr)+(nosuffix?'':' 00:00:00')+','+moment(formValues[key][1]).format(formstr)+ (nosuffix?'':' 23:59:59')
        else {
          tempobj[finditem.daterangeProps.rangeKeys[0]] = moment(formValues[key][0]).format(formstr) + (nosuffix?'':' 00:00:00')
          tempobj[finditem.daterangeProps.rangeKeys[1]] = moment(formValues[key][1]).format(formstr)+ (nosuffix?'':' 23:59:59')
        }
      }
      else {
        if (finditem.daterangeProps && finditem.daterangeProps.rangeKeys) {
          tempobj[finditem.daterangeProps.rangeKeys[0]] = moment(formValues[key][0]).format('x')
          tempobj[finditem.daterangeProps.rangeKeys[1]] = moment(formValues[key][1]).endOf('day').format('x')
        }
        else tempobj[key] = moment(formValues[key][0]).format('x')+','+moment(formValues[key][1]).endOf('day').format('x')
      }
    }
    else if (finditem && finditem.type==='upload') {
      if (fileList && fileList.length > 0) {
        let existUrls:any = []
        let uploadfiles:any = []
        fileList.forEach((fileitem:any)=>{
          if (fileitem.exist) existUrls.push(fileitem.url)
          else uploadfiles.push(fileitem)
        })
        let upres:any = await uniHandleUpload({fileList:uploadfiles,axUploadApi:fileUpload})
        console.log('upres-------------',upres)
        let urlstr = [...existUrls,...upres.map((mapitem:any)=>mapitem.data)].join(',')
        tempobj[key] = urlstr
      } else tempobj[key] = ''
    }
    else tempobj[key] = formValues[key]
  }
  return tempobj
}
//通用初始化from数据
export const uniInitFormdata = (row:any,formItems:any) => {
  let tempobj:any = {}
  for (let key in row) {
    let finditem = formItems.find((item:any)=>item.prop===key)
    if (finditem && (!finditem.type || finditem.type==='input') && finditem.custval) tempobj[key] = finditem.custval({row,value:row[key]})
    else if (finditem && finditem.type==='price') tempobj[key] = row[key] / 100
    else if (finditem && finditem.type==='datePicker') tempobj[key] = row[key] ? moment(row[key]) : null
    else if (finditem && finditem.type==='upload') tempobj[key] = row[key]?row[key].split(',').map((url:any,index:any)=>({exist:true,uid:index,name:'exist'+index+'.png',status:'done',url})):[]
    // else if (finditem && finditem.type==='upload') tempobj[key] = row[key]?.split(',')
    else if (finditem) tempobj[key] = row[key]
  }
  return tempobj
}
